import React, { useState, useEffect, useRef } from "react";
import {
  Avatar, Box,
  Button, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControl,
  FormControlLabel, FormGroup,
  FormLabel,
  Grid, IconButton, LinearProgress, List, ListItem, ListItemAvatar, ListItemText,
  Radio,
  RadioGroup,
  TextField, Typography
} from "@mui/material";
import { FieldArray, Formik } from "formik";
import useProject from "../../../../../hooks/useProject";
import axios from "axios";
import * as Yup from "yup";
import { Add } from "@mui/icons-material";
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import { useRecoilState } from "recoil";
import { headcountsState } from "../../../SharedState/HeadcountsState";
import { getProjectIdNotAsync } from "../../../SharedComponents/ProjectServices";
import UpdatePowdrModelGlobal from "../../../SharedComponents/UpdatePowdr/UpdatePowdrModelGlobal";

function AddHeadCount() {

  const updatePowdrModelGlobalRef = useRef();

  const [open, setOpen] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);
  const { project } = useProject();
  const [newValue, setNewValue] = useState("");

  const [selectedProject, setSelectedProject] = useState({ id : 0 });
  const [projects, setProjects] = useState([]);
  const [headcountsForProject, setHeadcountsForProject] = useState([]);
  const [selectedHeadcounts, setSelectedHeadcounts] = useState([]);
  const [headcounts, setHeadcounts] = useState([]);

  const [headCountAtom, setHeadCountAtom] = useRecoilState(headcountsState);

  const [assumptionFormatValue, setAssumptionFormatValue] =
    useState("PRICE_X_QUANTITY");
  const [grossProfitLineFlagValue, setGrossProfitLineFlagValue] =
    useState("BELOW");

  const validationSchema = Yup.object().shape({
    peopleCostName: Yup.string()
      .min(3, "Must be at least 3 characters")
      .max(150, "Must be less than 150 characters")
      .required("Required")
      .notOneOf(headCountAtom.map(function(i) {
        return i?.headCountDto?.name;
      }), "Headcount must have a unique name"),
  });

  const initialValues = {
    peopleCostName: "",
    assumptionFormat: "",
    grossProfitLineFlag: "ABOVE",
    departments: [],
  };

  const handleSubmit = async (
    values,
    { setErrors, setSubmitting, resetForm }
  ) => {

    if (assumptionFormatValue !== "HEADCOUNT_DIVISIONAL_CONSOLIDATION") {
        if (values.departments.length < 1) {
          window.alert('You must add at least 1 department.');
          return;
        }
    }

    try {

      setSubmittingForm(true)
      axios
        .post("headcounts", {
          name: values.peopleCostName,
          project: project.id,
          assumption: assumptionFormatValue,
          percentageComplete: 0,
          overrideComplete: false,
          profitLine: grossProfitLineFlagValue,
          departments: values.departments,
          linkedProjectId: selectedProject.id,
          headcounts: selectedHeadcounts
        })
        .then(async function(response) {

          //update the atoms - Headcount & p&l
          await axios.get("headcounts/" + project.id).then(function(response) {
            setHeadCountAtom(response.data);
          });

          await axios.get("pnl/" + project.id).then(function(response) {
            updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(null, response.data);
          });

          setSubmittingForm(false);
          resetForm();
          setOpen(false);

          setSelectedHeadcounts([]);
          setSelectedProject({ id : 0 });

        })
        .catch(function (error) {
          console.log(error);
          setSubmittingForm(false);
        });


    } catch (error) {
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const handleChangeAssumption = (event) => {
    setAssumptionFormatValue(event.target.value);
  };

  const handleChangeProfitLine = (event) => {
    setGrossProfitLineFlagValue(event.target.value);
  };


  useEffect(() => {

    if (open === true) {
      loadProjects();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ open]);

  async function loadProjects() {

    await axios.get('projectsByOwner/' + getProjectIdNotAsync().owner).then(function(response) {
      setProjects(response.data);
    })

    axios.get("headcounts/" + getProjectIdNotAsync().id).then(function(response) {
      setHeadcounts(response.data);
    });


  }

  useEffect( () => {
    if (selectedProject !== undefined && selectedProject !==  undefined && assumptionFormatValue === "HEADCOUNT_DIVISIONAL_CONSOLIDATION") {

      axios.get("headcounts/" + selectedProject.id).then(function(response) {
        setHeadcountsForProject(response.data);
        //setSelectedHeadcounts([]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject]);


  const handleSelectModel = (model) => {

    const currentIndex = selectedHeadcounts.indexOf(model.headCountDto.id);
    const newChecked = [...selectedHeadcounts];

    if (currentIndex === -1) {
      newChecked.push(model.headCountDto.id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedHeadcounts(newChecked);
  };

  function findParentOverhead(row) {
    const found = headcounts.find(headcount =>   headcount.headCountDto.headcounts.includes(row.headCountDto.id));
    return found ? found.headCountDto.name : '';
  }

  return (
    <React.Fragment>


      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpen(false)
          }
        }}
        aria-labelledby="form-dialog-title"
        sx={{minHeight: 400}}
      >

      </Dialog>




      <Grid container>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            formik,
          }) => (
            <Grid item xs={12}>
              <Button
                mr={2}
                variant="contained"
                color="primary"
                sx={{ marginBottom: 2, minWidth : 160 }}
                onClick={() => setOpen(true)}
              >
                Headcount
                <Add />
              </Button>

              <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="form-dialog-title"
                sx={{minHeight: 400}}
                fullWidth={["HEADCOUNT_DIVISIONAL_CONSOLIDATION"].includes(assumptionFormatValue)}
                maxWidth={"md"}
              >
                <form onSubmit={handleSubmit}>


                  <DialogTitle id="form-dialog-title">Add People Cost</DialogTitle>



                  {submittingForm &&  (

                    <DialogContent>

                      <DialogContentText>
                        Adding Product...
                        <LinearProgress my={2} />
                      </DialogContentText>

                    </DialogContent>
                  )}


                  <DialogContent sx={{ paddingBottom: 0 }}>

                    <Grid container spacing={3}>
                      <Grid item xs>

                      <DialogContentText>
                        Enter the name of the People cost you wish to add.
                      </DialogContentText>

                      <TextField
                        error={Boolean(touched.peopleCostName && errors.peopleCostName)}
                        name="peopleCostName"
                        autoFocus
                        placeholder={"People Cost Name"}
                        margin="dense"
                        id="peopleCostName"
                        label="People Cost Name"
                        value={values.peopleCostName}
                        onChange={handleChange}
                        type="text"
                        helperText={touched.peopleCostName && errors.peopleCostName}
                        fullWidth
                      />

                        <FormLabel id="demo-row-radio-buttons-group-label">
                          Assumption Format
                        </FormLabel>


                        <RadioGroup
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="assumptionFormat"
                          value={assumptionFormatValue}
                          onChange={handleChangeAssumption}
                        >
                          <FormControlLabel
                            value="PRICE_X_QUANTITY"
                            control={<Radio />}
                            label="Salary cost x number of FTEs"
                          />
                          <FormControlLabel
                            value="ANNUAL_GROWTH_RATE"
                            control={<Radio />}
                            label="Annual Growth Rate"
                          />
                          <FormControlLabel
                            value="MONTHLY_GROWTH_RATE"
                            control={<Radio />}
                            label="Monthly Growth Rate"
                          />
                          <FormControlLabel
                            value="DIRECT_INPUT"
                            control={<Radio />}
                            label="Direct Input"
                          />

                          <FormControlLabel
                            value="HEADCOUNT_DIVISIONAL_CONSOLIDATION"
                            disabled={getProjectIdNotAsync().projectType !== "PNL_DIVISIONAL"}
                            control={<Radio />}
                            label="Headcount Consolidation"
                          />
                        </RadioGroup>


                        <FormLabel id="demo-row-radio-buttons-group-label">
                          P & L location:
                        </FormLabel>


                        <RadioGroup
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="grossProfitLineFlag"
                          value={grossProfitLineFlagValue}
                          onChange={handleChangeProfitLine}
                        >
                          <FormControlLabel
                            value={"ABOVE"}
                            control={<Radio />}
                            label="Variable costs (Above Gross Profit)"
                          />
                          <FormControlLabel
                            value={"BELOW"}
                            control={<Radio />}
                            label="Overhead (Below Gross Profit, but Above EBITDA)"
                          />
                        </RadioGroup>

                      </Grid>

                      {assumptionFormatValue !== "HEADCOUNT_DIVISIONAL_CONSOLIDATION" &&
                        <Grid item xs>

                          <DialogContentText>
                            Enter the name of the Department you wish to add.
                          </DialogContentText>


                          <Grid container spacing={2}>
                            <Grid item xs={10}>
                              <TextField
                                error={Boolean(touched.departments && errors.departments)}
                                name="departmentName"
                                autoFocus
                                placeholder={"Department Name"}
                                margin="dense"
                                id="departmentName"
                                label="Department Name"
                                value={newValue}
                                onChange={(event) => {
                                  setNewValue(event.target.value);
                                }}
                                type="text"
                                helperText={touched.departmentName && errors.departmentName}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={2}>

                              <Button
                                mr={2}
                                variant="contained"
                                color="secondary"
                                sx={{ marginBottom: 2, marginTop: 4 }}
                                onClick={() => {
                                  if (newValue !== "") {
                                    setSubmittingForm(false)
                                    const newTodos = [
                                      ...values.departments,
                                      {
                                        name: newValue
                                      }
                                    ];
                                    values.departments = newTodos;
                                    setNewValue("");
                                  }
                                }}
                              >
                                <Add />
                              </Button>
                            </Grid>
                          </Grid>


                          <FieldArray
                            name="departments"
                            render={(arrayHelpers) => (

                              <List dense={true}>

                                {values.departments.map(({ name }, index) => (
                                  <ListItem key={Math.random() * 10000000}
                                            secondaryAction={
                                              <IconButton edge="end" aria-label="delete" onClick={() => {
                                                arrayHelpers.remove(index);
                                              }}>
                                                <DeleteIcon />
                                              </IconButton>
                                            }
                                  >
                                    <ListItemAvatar>
                                      <Avatar>
                                        <FolderIcon />
                                      </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                      primary={name}
                                    />
                                  </ListItem>
                                ))}

                              </List>

                            )}
                          />


                        </Grid>
                      }

                      {assumptionFormatValue === "HEADCOUNT_DIVISIONAL_CONSOLIDATION" &&
                        <Grid item xs>
                          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormLabel component="legend">Select a linked project to create your headcount consolidation group</FormLabel>
                            <FormGroup>

                              <Box sx={{minWidth: '50%'}}>
                                <br/>
                                <Typography variant="h6">Projects</Typography>
                                <Box sx={{ minHeight: '90%', maxHeight: '90%', overflow: 'auto', border: '1px solid #ccc', padding: '8px' }}>
                                  <List>

                                    {projects.filter(projectToPick => getProjectIdNotAsync().id !== projectToPick.id && getProjectIdNotAsync()?.projectMultiProjectList.filter(multiProject => multiProject.project === projectToPick.id).length > 0).map((row) => (
                                      <ListItem
                                        key={row.id}
                                        button
                                        selected={selectedProject.id === row.id}
                                        onClick={() => setSelectedProject(row)}
                                      >
                                        <ListItemText primary={row.name} />
                                      </ListItem>
                                    ))}
                                  </List>
                                </Box>
                              </Box>
                            </FormGroup>

                          </FormControl>
                        </Grid>
                      }

                      {assumptionFormatValue === "HEADCOUNT_DIVISIONAL_CONSOLIDATION" &&
                        <Grid item xs>
                          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormLabel component="legend">Select headcounts you want to include in this new consolidation, note that some may already be in an existing group.</FormLabel>
                            <FormGroup>

                              <Box sx={{minWidth: '50%'}}>
                                <Typography variant="h6">Headcounts</Typography>
                                <Box sx={{ minHeight: '90%', maxHeight: '90%', overflow: 'auto', border: '1px solid #ccc', padding: '8px' }}>
                                  <List>

                                    {headcountsForProject.map((row) => (
                                      <ListItem
                                        key={row.headCountDto.id}
                                        button
                                        selected={selectedProject.id === row.id}
                                        secondaryAction={
                                          <Checkbox
                                            edge="end"
                                            onChange={() => handleSelectModel(row)}
                                            checked={selectedHeadcounts.indexOf(row.headCountDto.id) !== -1}
                                          />
                                        }
                                      >

                                        <ListItemText primary={row.headCountDto.name} secondary={`Current Group: ${(findParentOverhead(row))}`} />
                                      </ListItem>
                                    ))}
                                  </List>
                                </Box>
                              </Box>

                            </FormGroup>

                          </FormControl>
                        </Grid>
                      }


                    </Grid>








                  </DialogContent>


                  <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary" disabled={submittingForm}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      autoFocus
                      disabled={submittingForm}
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
            </Grid>
          )}
        </Formik>
        <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>
      </Grid>
    </React.Fragment>
  );
}

export default AddHeadCount;
