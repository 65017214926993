import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid, LinearProgress,
  TextField
} from "@mui/material";
import { Formik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { Add } from "@mui/icons-material";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import useProject from "../../../../../hooks/useProject";
import { useRecoilState } from "recoil";
import { releaseProfileState } from "../../../SharedState/ReleaseProfileState";

function AddReleaseProfile() {
  const [open, setOpen] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [releaseProfileAtom, setReleaseProfileAtom] = useRecoilState(releaseProfileState);
  const { project } = useProject();

  const validationSchema = Yup.object().shape({
    releaseProfileName: Yup.string()
      .min(3, "Must be at least 3 characters")
      .max(150, "Must be less than 150 characters")
      .required("Required"),
  });

  const initialValues = {
    releaseProfileName: ""
  };


  const handleSubmit = async (
    values,
    { setErrors, setSubmitting, resetForm }
  ) => {

    try {
      setSubmittingForm(true)
      axios
        .put("releaseprofile/" + project.id , {
          name: values.releaseProfileName,
          releaseProfile: releaseProfileAtom[0].releaseProfileDto.id,
          project: getCurrentProject().id,
          overrideName: values.releaseProfileName,
          releaseProfileMonthDtoList: [],

        })
        .then(async function(response) {

          setReleaseProfileAtom(response.data);

          setSubmittingForm(false);
          resetForm();
          setOpen(false);
        })
        .catch(function (error) {
          console.log(error);
          setSubmittingForm(false);
        });


    } catch (error) {
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };



  return (
    <React.Fragment>
      <Grid container>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Grid item xs={12}>
              <Button
                mr={2}
                variant="contained"
                color="primary"
                sx={{ marginBottom: 2, minWidth : 160 }}
                onClick={() => setOpen(true)}
              >
                Release Profile
                <Add />
              </Button>

              <Dialog
                open={open}
                onClose={(event, reason) => {
                  if (reason !== 'backdropClick') {
                    setOpen(false)
                  }
                }}
                aria-labelledby="form-dialog-title"
              >
                <form onSubmit={handleSubmit}>
                  <DialogTitle id="form-dialog-title">Add Release Profile</DialogTitle>

                  {submittingForm &&  (

                    <DialogContent>

                      <DialogContentText>
                        Adding Release Profile...
                        <LinearProgress my={2} />
                      </DialogContentText>

                    </DialogContent>
                  )}

                  {!submittingForm &&  (
                  <DialogContent sx={{ paddingBottom: 0 }}>

                    <Grid container spacing={3}>
                      <Grid item xs>

                    <DialogContentText>
                      Enter the release profile you wish to add.
                    </DialogContentText>
                    <TextField
                      error={Boolean(touched.releaseProfileName && errors.releaseProfileName)}
                      name="releaseProfileName"
                      autoFocus
                      placeholder={"Release Profile Name"}
                      margin="dense"
                      id="releaseProfileName"
                      label="Release Profile Name"
                      value={values.debtName}
                      onChange={handleChange}
                      type="text"
                      helperText={touched.releaseProfileName && errors.releaseProfileName}
                      fullWidth
                    />

                      </Grid>
                    </Grid>

                  </DialogContent>
                  )}
                  <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary" disabled={submittingForm}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      autoFocus
                      disabled={submittingForm}
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
            </Grid>
          )}
        </Formik>
      </Grid>
    </React.Fragment>
  );
}

export default AddReleaseProfile;
