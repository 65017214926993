import _ from "lodash";
import { getCurrentProject } from "../../SharedComponents/ProjectServices";

export function actualiseVats(vats) {

  let firstForecast = getCurrentProject().firstForecast;

  //actualise each vat
  _.forEach(vats, function(vat, i) {

    _.forEach(vat.vatCategoryDtos, function(category, x) {

      if (_.isEmpty(category.vatMonthDtos)) {
        return;
      }

      category.vatMonthDtos[firstForecast].cellType =
        category.vatMonthDtos[firstForecast - 1].cellType;

      category.vatMonthDtos[firstForecast].financialTypeEnum =
        category.vatMonthDtos[firstForecast - 1].financialTypeEnum;

    });

  });

  return vats;
}
