import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import PowdrCell from "../../../SharedComponents/PowdrCell/PowdrCell";
import React, { useEffect, useState, useRef } from "react";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Modal } from "@mui/material";
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PowdrTableHeader from "../../../SharedComponents/PowdrTableHeader";
import { generateXaxisHeaders } from "../../../SharedComponents/MonthHeader";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import TableBody from "@mui/material/TableBody";
import PowdrNavBar from "../../../SharedComponents/PowdrNavBar";

const xAxisStyling = {
  minWidth: 200,
  maxWidth: 200,
  fontWeight: "bolder",
  color: "#2052c2",
  backgroundColor: "#F7F9FC",
};

// Style for the modal content box
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxHeight: '90vh',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function CashflowRow({ row, cashflow }) {

  const [open, setOpen] = React.useState(false);
  const tableRef = useRef(null);
  const [XaxisHeaders, setXaxisHeaders] = useState([]);

  useEffect(() => {
    setXaxisHeaders(generateXaxisHeaders(getCurrentProject().startDate, getCurrentProject().firstForecast));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>

      <TableRow key={row.id}
                id={row.id}
                sx={{ borderStyle: row.totalField === true && ["EBITDA", "Cashflow From Operating Activities", "Cashflow From Investing Activities", "Cashflow From Financing Activities", "Net Cashflow", "Opening Cash Balance", "Closing Cash Balance"].includes(row.name) ? "double" : "none", }}>
        <TableCell
          component="th"
          scope="row"
          variant={"body"}
          sx={{ ...xAxisStyling, backgroundColor: "#F7F9FC" }}
          style={{
            position: "sticky",
            left: 0,
            backgroundColor: "#F7F9FC",
            zIndex: 10,
          }}
          onClick={() => setOpen(!open)}
        >
          {["Non-operational expenses", "Movement In Other Current Liabilities", "Movement In Other Current Assets", "Net Debt Received/Paid"].includes(row.name) && <ArrowRightIcon />}
          {row.name}
        </TableCell>

        {row.cashflowMonthDtos.map((month) => (
          <PowdrCell
            key={month.id + "-CELL"}
            cellData={month}
            ownerId={row.id}
            items={cashflow}
          ></PowdrCell>
        ))}
      </TableRow>

      {["Non-operational expenses","Movement In Other Current Liabilities", "Movement In Other Current Assets", "Net Debt Received/Paid"].includes(row.name) &&

        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {row.name}
            </Typography>

            <PowdrNavBar
              item={{ name: "Movements-details" }}
              categories={row.movementDetails.categories}
              showExportToExcel={true}
              showProgressBar={false}
              showEdit={false}
              showDelete={false}
              showNavigation={true}
              exportToExcel={{
                fileName: "Powdr-Cashflow-" + row.name + "-" + new Date().toDateString(),
                sheet: "Debt",
                currentTableRef: tableRef.current
              }}
            ></PowdrNavBar>

            <Box
              sx={{
                mt: 2,
                overflowX: 'auto', // Enables horizontal scrolling
              }}
            >

              <Typography id="modal-modal-description" sx={{ mt: 2 }} component="div">
                <Box sx={{ margin: 1 }}>

                  <Table size="small" aria-label="purchases" ref={tableRef}>

                    <PowdrTableHeader name={"Detailed View"}
                                      XaxisHeaders={XaxisHeaders}
                                      typeColour={"info"} />

                    <TableBody>

                      {row.movementDetails !== undefined && row.movementDetails.categories.map((movement) => (

                        <TableRow key={movement.id}
                                  id={movement.id}
                                  sx={{ borderStyle: movement.totalField === true && ["Movement In Other Current Liabilities", "Movement In Other Current Assets", "Net Debt Received/Paid"].includes(movement.name) ? "double" : "none", }}>
                          <TableCell
                            component="th"
                            scope="row"
                            variant={"body"}
                            sx={{ ...xAxisStyling, backgroundColor: "#F7F9FC" }}
                            style={{
                              position: "sticky",
                              left: 0,
                              backgroundColor: "#F7F9FC",
                              zIndex: 10,
                            }}
                            onClick={() => setOpen(!open)}
                          >
                            {movement.name}
                          </TableCell>

                          {movement.cashflowMonthDtos.map((month) => (
                            <PowdrCell
                              key={month.id + "-CELL"}
                              cellData={month}
                              ownerId={movement.id}
                            ></PowdrCell>
                          ))}
                        </TableRow>

                      ))}

                    </TableBody>

                  </Table>
                </Box>
              </Typography>

            </Box>

          </Box>
        </Modal>
      }

    </React.Fragment>
  );

}
