import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Alert,
  Button,
  Card,
  CardContent,
  DialogActions, DialogContentText, FormControl,
  Grid, MenuItem, Select,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import { Field, Formik } from "formik";
import * as Yup from "yup";


const CustomizedSelectForFormik = ({ children, form, field }) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  return (
    <Select
      name={name}
      value={value}
      onChange={(e) => {
        setFieldValue(name, e.target.value);
      }}
    >
      {children}
    </Select>
  );
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "Must be at least 3 characters")
    .max(100, "Must be less than 100 characters")
    .required("Required"),
  lastName: Yup.string()
    .min(3, "Must be at least 3 characters")
    .max(100, "Must be less than 100 characters")
    .required("Required"),
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  mobileTelephone: Yup.string()
    .max(20, "Must be less than 20 characters")
    .required("Telephone is required"),
});

function EditUserForm({ user, companies }) {

  const [triggerRefresh, setTriggerRefresh] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(1);
  const [initialValues, setInitialValues] = useState({
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    email: user?.email || "",
    mobileTelephone: user?.mobileTelephone || "",
    company: user?.company || "",
    disabled: user?.disabled || false,
  });

  useEffect(() => {
    if (user) {
      setInitialValues({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        mobileTelephone: user.mobileTelephone,
        company: user.company,
        disabled: user.disabled,
      });
      setSelectedCompany(user.company);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
    try {
      var data = {
        id: user.id,
        disabled: values.disabled,
        roles: user.roles,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        mobileTelephone: values.mobileTelephone,
        company: selectedCompany,
      };

      axios
        .put("security/users", data)
        .then(function (response) {
          setStatus({ sent: true });
        })
        .catch(function (error) {
          setStatus({ sent: false });
        });

      setSubmitting(false);
    } catch (error) {
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <Card mb={6} sx={{ marginTop: 10, marginBottom: 10 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Edit User
        </Typography>
        <Typography variant="body2" gutterBottom>
          Update your user details here:
        </Typography>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
              setFieldValue
            }) => (
            <form onSubmit={handleSubmit}>
              {status && status.sent && (
                <Alert severity="success" my={3}>
                  Your project has been successfully updated! (If you have updated the <strong>first forecast values</strong>, then this may take several minutes to complete in the background)
                </Alert>
              )}

              <Grid container spacing={6} paddingTop={2}>
                <Grid item md={6}>
                  <TextField
                    error={Boolean(touched.firstName && errors.firstName)}
                    margin="dense"
                    name="firstName"
                    label="First Name"
                    type="text"
                    value={values.firstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid container spacing={6} paddingTop={2}>
                <Grid item md={6}>
                  <TextField
                    error={Boolean(touched.lastName && errors.lastName)}
                    margin="dense"
                    name="lastName"
                    label="Last Name"
                    type="text"
                    value={values.lastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid container spacing={6} paddingTop={2}>
                <Grid item md={6}>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    margin="dense"
                    name="email"
                    label="Email"
                    type="text"
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid container spacing={6} paddingTop={2}>
                <Grid item md={6}>
                  <TextField
                    error={Boolean(touched.mobileTelephone && errors.mobileTelephone)}
                    margin="dense"
                    name="mobileTelephone"
                    label="Mobile Telephone"
                    type="text"
                    value={values.mobileTelephone}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid item sx={{ minWidth: 265 }}>
                <FormControl sx={{ minWidth: 265 }}>
                  <DialogContentText>Company</DialogContentText>
                  <Field
                    name="company"
                    component={CustomizedSelectForFormik}
                    value={values.company}
                    fullWidth
                  >
                    {!!companies &&
                      companies.map((company) => (
                        <MenuItem onClick={() => {
                          setSelectedCompany(company.id);
                          setTriggerRefresh({ ...triggerRefresh });
                        }}
                                  key={company.id} value={company.id}>{company.companyName}</MenuItem>
                      ))}
                  </Field>
                </FormControl>
              </Grid>

              <Grid container spacing={6} paddingTop={2}>
                <Grid item md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.disabled}
                        onChange={(e) => setFieldValue("disabled", e.target.checked)}
                        name="disabled"
                        color="primary"
                      />
                    }
                    label="Disable user by clicking this checkbox"
                  />
                </Grid>
              </Grid>

              <DialogActions>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  autoFocus
                  disabled={isSubmitting}
                >
                  Update
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
}

export default EditUserForm;
