import _ from "lodash";

/**
 * Compares two objects and returns true only if a specific attribute has changed.
 * Ignores other attributes as specified in the optional ignoreAttributes array.
 *
 * @param {Object} obj1 - The first object to compare.
 * @param {Object} obj2 - The second object to compare.
 * @param {string} attribute - The specific attribute to check for changes.
 * @param {Array} [ignoreAttributes=[]] - Optional list of attributes to ignore during the comparison.
 * @returns {boolean} - Returns true if only the specified attribute has changed; false otherwise.
 *
 * Example usage:
 * const obj1 = { name: 'Alice', age: 30, location: 'New York' };
 * const obj2 = { name: 'Alice', age: 31, location: 'New York' };
 * const attribute = 'age';
 * const ignoreAttributes = ['location'];
 *
 * console.log(hasOnlySpecificAttributeChanged(obj1, obj2, attribute, ignoreAttributes)); // true
 */
export function hasOnlySpecificAttributeChanged(obj1, obj2, attribute, ignoreAttributes = []) {
  // Check if the specific attribute has changed using lodash's isEqual for deep comparison
  const specificAttributeChanged = !_.isEqual(obj1[attribute], obj2[attribute]);

  // If the specific attribute hasn't changed, return false
  if (!specificAttributeChanged) {
    return false;
  }

  // Create a list of attributes to ignore (including the specified attribute)
  const attributesToIgnore = [...ignoreAttributes, attribute];

  // Remove the attributes to ignore from both objects for comparison
  const obj1WithoutAttributes = _.omit(obj1, attributesToIgnore);
  const obj2WithoutAttributes = _.omit(obj2, attributesToIgnore);

  // Check if the remaining objects are equal
  return _.isEqual(obj1WithoutAttributes, obj2WithoutAttributes);
}
