import React, { useRef } from "react";
import {
  Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField
} from "@mui/material";
import { Formik} from "formik";
import useProject from "../../../../../hooks/useProject";
import axios from "axios";
import * as Yup from "yup";
import { useRecoilState, useRecoilValue } from "recoil";
import { fixedAssetsState } from "../../../SharedState/FixedAssetsState";
import { pnlState } from "../../../SharedState/PnLState";
import UpdatePowdrModelGlobal from "../../../SharedComponents/UpdatePowdr/UpdatePowdrModelGlobal";

async function updateFixedAsset(values, project, fixedasset, setOpen, setSubmitting, setErrors, setFixedAssets, setShowSpinner, pnlAtom, updatePowdrModelGlobalRef) {

  setShowSpinner(true)

  try {
    axios
      .put("fixedassets", {
        name: values.fixedAssetName,
        project: project.id,
        assumption: values.assumptionFormat,
        percentageComplete: 0,
        overrideComplete: false,
        canDelete: values.canDelete,
        id: fixedasset.id,
        comment: fixedasset.comment
      })
      .then(async function(response) {

        //update the atoms - Fixed Asset & Balance
        await axios.get("fixedassets/" + project.id).then(function(response) {
          setFixedAssets(response.data);
        });
        await axios.get("balance/" + project.id).then(function(response) {
          updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(response.data, pnlAtom);
        });

        setShowSpinner(false);
        setOpen(false);
      })
      .catch(function(error) {
        setShowSpinner(false);
        console.log(error);
      });

    setSubmitting(false);
    //resetForm();
  } catch (error) {
    setErrors({ submit: error.message });
    setSubmitting(false);
  }
}

function EditFixedAsset(props) {

  const updatePowdrModelGlobalRef = useRef();


  const { project } = useProject();
  const { fixedasset, open, setOpen } = props;

  const [showSpinner, setShowSpinner] = React.useState(false);
  const [fixedAssetsAtom, setFixedAssets] = useRecoilState(fixedAssetsState);

  const pnlAtom = useRecoilValue(pnlState);

  const validationSchema = Yup.object().shape({
    fixedAssetName: Yup.string()
      .min(3, "Must be at least 3 characters")
      .max(150, "Must be less than 150 characters")
      .required("Required")
      .notOneOf(fixedAssetsAtom.filter(debt_ => debt_?.fixedAssetDto?.name !== fixedasset?.name).map(function(i) {
        return i.fixedAssetDto.name;
      }), "Must have a unique name"),
  });

  const initialValues = {
    fixedAssetName: fixedasset?.name,
    assumptionFormat: fixedasset?.assumption,
    balancePositionFlag: fixedasset?.position,
    canDelete: fixedasset?.canDelete,
  };

  const handleSubmit = async (
    values,
    { setErrors, setSubmitting, resetForm }
  ) => {

    if (initialValues.assumptionFormat !== values.assumptionFormat) {
      if (window.confirm("WARNING: Ensure you export your data before modifying this assumption. \nPressing \"OK\" will RESET all entered numbers in this assumption table") === true) {
        updateFixedAsset(values, project, fixedasset, setOpen, setSubmitting, setErrors, setFixedAssets, setShowSpinner, pnlAtom, updatePowdrModelGlobalRef);
      } else {
        resetForm();
        setOpen(false);
      }
    } else {
      updateFixedAsset(values, project, fixedasset, setOpen, setSubmitting, setErrors, setFixedAssets,   setShowSpinner, pnlAtom, updatePowdrModelGlobalRef);
    }

  };


  return (
    <React.Fragment>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (

              <Dialog
                open={open}
                onClose={(event, reason) => {
                  if (reason !== 'backdropClick') {
                    setOpen(false)
                  }
                }}
                aria-labelledby="form-dialog-title"
              >
                <form onSubmit={handleSubmit}>
                  <DialogTitle id="form-dialog-title">Edit Fixed Asset</DialogTitle>
                  <DialogContent sx={{ paddingBottom: 0 }}>
                    <DialogContentText>
                      Update the name of the fixed asset you wish to edit.
                    </DialogContentText>
                    <TextField
                      error={Boolean(touched.fixedAssetName && errors.fixedAssetName)}
                      name="fixedAssetName"
                      autoFocus
                      placeholder={"Fixed Asset Name"}
                      margin="dense"
                      id="fixedAssetName"
                      label="Fixed Asset Name"
                      value={values.fixedAssetName}
                      onChange={handleChange}
                      disabled={!values.canDelete}
                      type="text"
                      helperText={touched.fixedAssetName && errors.fixedAssetName}
                      fullWidth
                    />

                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Assumption Format
                    </FormLabel>
                    <RadioGroup
                      list="true"
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="assumptionFormat"
                      value={values.assumptionFormat}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="SIMPLE_CORKSCREW"
                        control={<Radio />}
                        label="Simple Corkscrew"
                      />
                      <FormControlLabel
                        value="SIMPLE_CORKSCREW_WITH_INPUT_DA"
                        control={<Radio />}
                        label="Simple Corkscrew with input D/A"
                      />
                      <FormControlLabel
                        value="SIMPLE_CORKSCREW_WITH_DA_PERCENTAGE"
                        control={<Radio />}
                        label="Simple Corkscrew with D/A as a percentage of opening balance"
                      />
                    </RadioGroup>

                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      autoFocus
                      disabled={isSubmitting}
                    >
                      {showSpinner ? <CircularProgress size={24} /> : 'Update'}
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
          )}
        </Formik>
      <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>
    </React.Fragment>
  );;
}

export default EditFixedAsset;
