  import React from "react";
import { TableCell } from "@mui/material";


const xAxisStyling = {
  minWidth: 200,
  maxWidth: 200,
  fontWeight: "bolder",
  color: "#2052c2",
  backgroundColor: "#F7F9FC",
};

export default function RowTitle(props) {
    const { name, alt, defaultBackground="#F7F9FC" } = props;


    return (
      <TableCell
        title={alt}
        component="th"
        scope="row"
        variant={"body"}
        sx={{ ...xAxisStyling, backgroundColor: "#F7F9FC" }}
        style={{
          position: "sticky",
          left: 0,
          backgroundColor: defaultBackground,
          zIndex: 1,
        }}
      >
        {name}
      </TableCell>
    );
  }

