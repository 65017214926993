import React, { useEffect, useState }  from "react";
import {  Table, TableBody, TableRow,  } from "@mui/material";
import PowdrTableHeader from "../../../SharedComponents/PowdrTableHeader";
import RowTitle from "../../../SharedComponents/RowTitle";
import PowdrCell from "../../../SharedComponents/PowdrCell/PowdrCell";
import { generateXAnnualAxisHeadersAnnual } from "../../../SharedComponents/MonthHeader";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";


export default function OverheadsDashboardInput({overheadItem, name}) {

  const [XaxisHeaders, setXaxisHeaders] = useState([]);

  useEffect(() => {
    setXaxisHeaders(generateXAnnualAxisHeadersAnnual(getCurrentProject().startDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Table>

        <PowdrTableHeader name={name}
                          analysisAssumption={overheadItem?.analysisAssumptionDesc}
                          XaxisHeaders={XaxisHeaders}
                          typeColour={"info"}/>

        {/*loop through the categories*/}
        <TableBody>
          {overheadItem.overheadCategoryDtoList.map((row) => (
            <TableRow key={row.id} id={row.id}
                      sx={{borderStyle: row.totalField === true ? "double" : "none", }}>

              <RowTitle name={row.name}></RowTitle>

              {row.pnLMonthDtoList.map((month) => (
                <PowdrCell
                  key={month.id + "-CELL"}
                  cellData={month}
                  ownerId={row.id}
                ></PowdrCell>
              ))}

            </TableRow>
          ))}
        </TableBody>

      </Table>

    </React.Fragment>
  );
}