import React from 'react';
import {
  Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, Typography, Box
} from '@mui/material';

// The component accepts a 'data' prop containing the TrialBalanceDto
const TrialBalanceTable = ({ data }) => {

  // Helper function to convert date arrays to standardized date strings
  const parseDateArray = (dateArray) => {
    const [year, month, day] = dateArray;
    // Create a UTC date to avoid time zone issues
    const dateObj = new Date(Date.UTC(year, month - 1, day));
    // Format the date as 'YYYY-MM-DD' for consistent comparison
    return dateObj.toISOString().split('T')[0];
  };

  // Get unique sorted dates from the data to use as columns
  const getUniqueSortedDates = (sections) => {
    const datesSet = new Set();
    sections.forEach((section) => {
      section.forEach((codeData) => {
        codeData.dataList.forEach((financialData) => {
          const dateString = parseDateArray(financialData.date);
          datesSet.add(dateString);
        });
      });
    });
    const datesArray = Array.from(datesSet);
    datesArray.sort(); // Sort dates in ascending order
    return datesArray;
  };

  const dates = getUniqueSortedDates([
    data.revenue,
    data.expenses,
    data.assets,
    data.liabilities,
    data.equity,
  ]);

  // Function to render a section table
  const renderSectionTable = (sectionName, codeDataList) => {
    if (!codeDataList || codeDataList.length === 0) {
      return null; // Skip rendering if there's no data for the section
    }

    return (
      <Box sx={{ marginBottom: 4 }}>
        <Typography variant="h6" gutterBottom>
          {sectionName}
        </Typography>
        <TableContainer component={Paper}>
          <Table aria-label={`${sectionName} Table`}>
            <TableHead>
              <TableRow>
                <TableCell><strong>Code</strong></TableCell>
                <TableCell><strong>Description</strong></TableCell>
                {dates.map((date) => (
                  <TableCell key={Math.random() * 10000000} align="right">
                    <strong>{new Date(date).toLocaleDateString(undefined, { month: 'short', year: 'numeric' })}</strong>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {codeDataList.map((codeData) => (
                <TableRow key={Math.random() * 10000000}>
                  <TableCell>{codeData.code}</TableCell>
                  <TableCell>{codeData.description}</TableCell>
                  {dates.map((date) => {
                    const financialData = codeData.dataList.find((fd) => fd.date === date);
                    const value = financialData ? (financialData.debit - financialData.credit) : 0;
                    return (
                      <TableCell key={Math.random() * 10000000} align="right">
                        {value.toFixed(2)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        {data.reportTitle} - {data.name}
      </Typography>

      {renderSectionTable('Revenue', data.revenue)}
      {renderSectionTable('Expenses', data.expenses)}
      {renderSectionTable('Assets', data.assets)}
      {renderSectionTable('Liabilities', data.liabilities)}
      {renderSectionTable('Equity', data.equity)}
    </div>
  );
};

export default TrialBalanceTable;
