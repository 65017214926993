import React, { useState } from "react";
import axios from "axios";
import {
  Alert,
  Button,
  Card,
  CardContent,
  DialogActions,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";


const validationSchema = Yup.object().shape({
  firstName : Yup.string()
    .min(3, "Must be at least 3 characters")
    .max(100, "Must be less than 100 characters")
    .required("Required"),
  lastName : Yup.string()
    .min(3, "Must be at least 3 characters")
    .max(100, "Must be less than 100 characters")
    .required("Required"),
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  mobileTelephone: Yup.string()
    .email("Must be a valid telephone number")
    .max(255)
    .required("Telephone is required"),
});

function UserDetails({ user }) {

  const [initialValues] = useState({
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    mobileTelephone: user?.mobileTelephone,
  });

  const handleSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
    try {

      var data = {
        disabled: user.disabled,
        roles: user.roles,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        mobileTelephone: values.mobileTelephone,
      };

      axios
        .put("security/users", data)
        .then(function (response) {

          setStatus({ sent: true });
        })
        .catch(function (error) {
          setStatus({ sent: false });
        });

      setSubmitting(false);
    } catch (error) {
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <Card mb={6} sx={{marginTop: 10, marginBottom : 10}}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Edit User
        </Typography>
        <Typography variant="body2" gutterBottom>
          Update your user details here:
        </Typography>


          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                status,
                setFieldValue
              }) => (
              <form onSubmit={handleSubmit}>

                {status && status.sent && (
                  <Alert severity="success" my={3}>
                    Your project has been successfully updated! (If you have updated the <strong>first forecast values</strong>, then this may take several minutes to complete in the background)
                  </Alert>
                )}

                <Grid container spacing={6} paddingTop={2}>
                  <Grid item md={6}>
                    <TextField
                      error={Boolean(touched.firstName && errors.firstName)}
                      margin="dense"
                      name="firstName"
                      label="First Name"
                      type="text"
                      value={values.firstName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={6} paddingTop={2}>
                  <Grid item md={6}>
                    <TextField
                      error={Boolean(touched.lastName && errors.lastName)}
                      margin="dense"
                      name="lastName"
                      label="Last Name"
                      type="text"
                      value={values.lastName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={6} paddingTop={2}>
                  <Grid item md={6}>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      margin="dense"
                      name="email"
                      label="Email"
                      type="text"
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={6} paddingTop={2}>
                  <Grid item md={6}>
                    <TextField
                      error={Boolean(touched.mobileTelephone && errors.mobileTelephone)}
                      margin="dense"
                      name="mobileTelephone"
                      label="Mobile Telephone"
                      type="text"
                      value={values.mobileTelephone}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>


                <DialogActions>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    autoFocus
                    disabled={isSubmitting}
                  >
                    Update
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>

      </CardContent>
    </Card>
  );
}

export default UserDetails;
