import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";

import {
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";
import PageHeader from "../../SharedComponents/PageHeader";
import { useRecoilState } from "recoil";
import { releaseProfileState } from "../../SharedState/ReleaseProfileState";
import ReleaseProfileInput from "./components/ReleaseProfileInput";
import ControlsBar from "../../SharedComponents/ControlsBar";

import { hasCellValueChanged } from "../../SharedComponents/utils/Utils";
import AddReleaseProfile from "./components/AddReleaseProfile";

const Divider = styled(MuiDivider)(spacing);


function ReleaseProfile() {

  const [releaseProfiles, setReleaseProfiles] = useState([]);
  const [releaseProfileAtom, setReleaseProfileAtom] = useRecoilState(releaseProfileState);
  const [editTableEnabled, setEditTableEnabled] = useState("");

  async function load() {

    let releaseProfiles_ = structuredClone(releaseProfileAtom);

    console.log(releaseProfileAtom)

    setReleaseProfiles(releaseProfiles_);
  }

  function refreshData(cellData) {

    if (hasCellValueChanged(cellData)) {

      setReleaseProfileAtom(structuredClone(releaseProfiles))
    }
  }


  useEffect(() => {

    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releaseProfileAtom]);

  return (
    <React.Fragment>

        <PageHeader pageName={"Release Profile"} parentName={"Config"} />

        <Divider my={6} />

        <ControlsBar addAssumptionElement={
          <AddReleaseProfile/>
        }/>


      {!!releaseProfiles &&
        releaseProfiles.map((releaseProfile) => (
          <ReleaseProfileInput
            key={releaseProfile.releaseProfileDto.id}
            releaseProfileItem={releaseProfile}
            releaseProfile={releaseProfiles}
            refreshData={refreshData}
            setEditData={setEditTableEnabled}
            editData={editTableEnabled}
            setDebt={setReleaseProfiles}
            loadData={load}
          ></ReleaseProfileInput>
        ))}

    </React.Fragment>
  );
}

export default ReleaseProfile;
