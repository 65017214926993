import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";

import {
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";

import {hasCellValueChanged, } from "../../SharedComponents/utils/Utils";
import Settings from "../../../../components/Settings";
import VatConfigInput from "./components/VatConfigInput";
import { useRecoilState } from "recoil";
import { vatState } from "../../SharedState/VatState";
import PageHeader from "../../SharedComponents/PageHeader";
import ControlsBar from "../../SharedComponents/ControlsBar";
import UpdatePowdrModelGlobal from "../../SharedComponents/UpdatePowdr/UpdatePowdrModelGlobal";


const Divider = styled(MuiDivider)(spacing);

function VatConfig() {

  const updatePowdrModelGlobalRef = useRef();

  const [vats, setVats] = useState([]);
  const [editTableEnabled, setEditTableEnabled] = useState("");

  const [vatRateAtom, setVatRateAtom] = useRecoilState(vatState);


  async function loadVat() {

    let vats_ = structuredClone(vatRateAtom);

    setVats(vats_)

  }

  function refreshData(cellData) {


    if (hasCellValueChanged(cellData)) {

      setVatRateAtom(structuredClone(vats));

      //global recalc call here...
      updatePowdrModelGlobalRef.current.updatePowdrModelGlobal();

    }

  }

  useEffect(() => {
    loadVat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vatRateAtom]);


  return (
    <React.Fragment>
      <PageHeader pageName={"Vat Rates"} parentName={"Config"}/>

      <Divider my={6} />

      <ControlsBar/>

      {!!vats &&
        vats.map((vat) => (
          <VatConfigInput
            key={vat.vatDto.id}
            vatItem={vat}
            vat={vats}
            refreshData={refreshData}
            setEditData={setEditTableEnabled}
            editData={editTableEnabled}
            setVat={setVats}
            loadData={loadVat}
          ></VatConfigInput>
        ))}

      <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>

      <Settings/>

    </React.Fragment>
  );
}

export default VatConfig;
