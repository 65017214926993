import React from "react";
import axios from "axios";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";


function ResetPassword({ user }) {


  function resetPassword() {

    axios.post('security/reset', { user: user.email }).then(function(response) {

      window.alert('Password update request successful, please check your email for further steps');
    })

  }

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Reset Password
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Click here to reset your password
                </Typography>
              </Grid>
              <Grid container spacing={6}>
                <Grid item md={6}>
                  <Button variant="contained" style={{ width: 200, height: 50 }}
                          onClick={resetPassword}>
                    Reset Password
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ResetPassword;
