import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";

import {
  CardContent,
  Card as MuiCard,
  TableCell,
  Table,
  TableRow,
  TableBody,
  TableHead,
} from "@mui/material";
import { spacing } from "@mui/system";
import axios from "axios";
import useProject from "../../../../../hooks/useProject";
import PowdrCell from "../../../SharedComponents/PowdrCell/PowdrCell";
import CurrentAssetNavBar from "./CurrentAssetNavBar";
import RowTitle from "../../../SharedComponents/RowTitle";
import MonthHeader, { generateXaxisHeaders } from "../../../SharedComponents/MonthHeader";
import { AssumptionLabel } from "../../../SharedComponents/AssumptionLabel";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { zoomState } from "../../../SharedState/ZoomState";
import { Skeleton } from "@mui/material";
import { revenueAndCostState } from "../../../SharedState/RevenueAndCostState";
import { balanceSheetState } from "../../../SharedState/BalanceSheetState";
import { currentAssetsState } from "../../../SharedState/CurrentAssetsState";


const Card = styled(MuiCard)(spacing);

const tableWidth = {
  width: "100%",
};

const helpTitles = new Map();

helpTitles.set('New Aged Debt', 'New aged debtor should be a positive value');
helpTitles.set('Aged debt released', 'Aged debtor release should be a negative value');
helpTitles.set('Percentage of selected costs', 'Percentage of selected costs should be a negative percentage value to generate a positive  (debit balance) current asset ');



function CurrentAssetInput(props) {
  const { key } = useProject();
  const [XaxisHeaders, setXaxisHeaders] = useState([]);
  const tableRef = useRef(null);
  const zoomAtom = useRecoilValue(zoomState);

  const setProductsAtom = useSetRecoilState(revenueAndCostState);
  const setBalanceAtom = useSetRecoilState(balanceSheetState);
  const setCurrentAssetsAtom = useSetRecoilState(currentAssetsState);


  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Defer heavy rendering to allow loading indicator to show
    setTimeout(() => {
      setIsLoading(false);
    }, 0);
  }, []);

  const {
    currentAssetItem,
    currentAssets,
    refreshData,
    setEditData,
    editData,
    setCurrentAssets,
    loadData,
    products,
    overheads,
    salaries,
  } = props;

  const deleteCurrentAsset = (handleClose) => {
    var config = {
      method: "delete",
      url: "currentassets",
      data: currentAssetItem.currentAssetDto,
    };

    axios(config)
      .then(async function(response) {
        // do something to trigger a redraw
        // console.log(response.data)
        //todo - refresh the balance atom?
        //todo call a mini recalc to update the balance sheet other current assets

        await axios.get("currentassets/" + getCurrentProject().id).then(function(response) {
          setCurrentAssetsAtom(response.data);
          setCurrentAssets([...structuredClone(response.data)]);
        });

        await axios.get("revenuecos/" + getCurrentProject().id).then(function(response) {
          console.log('products should be empty', response.data);
          setProductsAtom(response.data);
        });

        await axios.get("balance/" + getCurrentProject().id).then(function(response) {
          setBalanceAtom(response.data);
        });

        window.location.reload(); //required for Loan Assets as the VatCalculation is out of date and this was an easy enough way to flush it.

        handleClose();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    setXaxisHeaders(generateXaxisHeaders(getCurrentProject().startDate, getCurrentProject().firstBalanceForecast));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClick(id) {
    setEditData(id);
  }


  function getName(row) {

    let name;

    if (row.overrideName !== null && row.name === "Product Name") {
      name = row.overrideName;
    } else {
      name = row.name;
    }

    if (row.name === 'Days Receivable') {
      name = row.overrideName;
    }

    return name;
  }

  function renderHeavyContent() {
    return (
      <div>
        <CurrentAssetNavBar
          item={currentAssetItem.currentAssetDto}
          categories={currentAssetItem.currentAssetCategoryDtos}
          deleteMethod={deleteCurrentAsset}
          refreshDataMethod={loadData}
          products={products}
          overheads={overheads}
          salaries={salaries}
          exportToExcel={{fileName: "Powdr-CurrentAsset-" + currentAssetItem.currentAssetDto.name + "-" + new Date().toDateString(),
            sheet : "Current Asset",
            currentTableRef : tableRef.current}}
          shallowUpdateUrl={'currentassets/currentAssetShallow'}
        ></CurrentAssetNavBar>

        <Card
          mb={6}
          sx={{
            ...tableWidth,
            overflowX: "auto",
            overflow: "scroll",
            paddingTop: 0,
          }}
          id={currentAssetItem.currentAssetDto.name.replaceAll(" ", "-") + "-table"}
        >
          <CardContent sx={{ paddingTop: 0 , zoom: zoomAtom}}>
            <Table ref={tableRef}>
              {/* X AXIS - PRODUCT NAME, JAN, FEB, MAR, ETC... */}
              <TableHead>
                <TableRow
                  key={currentAssetItem.currentAssetDto.name + "-ROW"}
                  id={currentAssetItem.currentAssetDto.name + "-ROW"}
                >
                  <TableCell
                    key={currentAssetItem.currentAssetDto.name + "-BASE"}
                    id={currentAssetItem.currentAssetDto.name + "-BASE"}
                    style={{ position: "sticky", left: 0 }}
                    sx={{
                      minWidth: 200,
                      maxWidth: 200,
                      width: 200,
                      backgroundColor: "#F7F9FC",
                      zIndex: 10,
                    }}
                  >
                    <h2>{currentAssetItem.currentAssetDto.name}</h2>
                    <AssumptionLabel label={currentAssetItem.currentAssetDto.assumptionDesc} color="success" />
                  </TableCell>

                  {XaxisHeaders.map((yHeader, index) => (
                    <MonthHeader key={Math.random() * 10000000} yHeader={yHeader} name={currentAssetItem.currentAssetDto.name} index={index}></MonthHeader>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>

                {currentAssetItem.currentAssetCategoryDtos.map((row) => (
                  <TableRow key={row.id} id={row.id} onClick={() => handleClick(row.id)}>


                    <RowTitle name={getName(row)} alt={helpTitles.get(row.name)}></RowTitle>


                    {row.currentAssetMonthDtos.map((month) => (
                      <PowdrCell
                        key={month.id + "-CELL"}
                        cellData={month}
                        ownerId={row.id}
                        editTableEnabled={editData}
                        items={currentAssets}
                        refreshItemData={refreshData}
                        setItems={setCurrentAssets}
                        assumption={currentAssetItem}
                        categoryName={row.name}
                        parentName={currentAssetItem.currentAssetDto.name}
                        category={row}
                        monthIndex={month.monthIndex}
                        monthAttributeName={'currentAssetMonthDtos'}
                      ></PowdrCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>

      </div>
    )
  }

  return (
    <React.Fragment key={key}>
      {isLoading ? (
        <Skeleton /> // Loading widget or spinner
      ) : (
        renderHeavyContent() // Rendered heavy content
      )}
    </React.Fragment>
  );
}

export default CurrentAssetInput;
