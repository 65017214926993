import {
  Camera, Compass,
  Sliders, StopCircle, UserPlus,
  Users
} from "react-feather";
import { ListAlt } from "@mui/icons-material";

const PowdrStaffSection = [
  {
    href: "/powdr-projects",
    icon: Sliders,
    title: "Projects",
  },
  {
    href: "/project-audit",
    icon: ListAlt,
    title: "Audit",
  },
  {
    href: "/snapshot",
    icon: Camera,
    title: "Snapshots Admin", //name changed slightly so that it'll still appear on the menu for P&L projects
  },
  {
    href: "/companies",
    icon: Compass,
    title: "Companies",
  },
  {
    href: "/adduser",
    icon: UserPlus,
    title: "Add User",
  },
  {
    href: "/edituser",
    icon: Users,
    title: "Edit User",
  },
  {
    href: "/lastloggedin",
    icon: StopCircle,
    title: "Logged In",
  },
];

const powdrStaff = [
  {
    title: "Powdr Staff",
    pages: PowdrStaffSection,
  },
];



export default powdrStaff;
