import React, { useState, useEffect }  from "react";
import { Helmet } from "react-helmet-async";

import {
  Typography,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import getProjectId from "../SharedComponents/ProjectServices";
import axios from "axios";
import PageHeader from "../SharedComponents/PageHeader";
import LoadingModal from "../SharedComponents/LoadingModal";
import PayForProject from "../PowdrAdmin/Projects/components/PayForProject";
import PayForDuplicateProject from "../PowdrAdmin/Projects/components/PayForDuplicateProject";
import DisclaimerModal from "../SharedComponents/Disclaimer/DisclaimerModal";
import ProjectsViewer from "../SharedComponents/ProjectFolder/ProjectsViewer";
import Settings from "../../../components/Settings";



function Home() {
  const { user } = useAuth();
  const [companyToEdit, setCompanyToEdit] = useState();

  const [loadingProject] = useState(false);
  const [payForDuplicates, setPayForDuplicates] = useState(false);

  async function loadHomeData() {

    // eslint-disable-next-line
    let project = await getProjectId();

    if (user != null) {
      await axios.get('company/' + user.company).then(function(response) {
        setCompanyToEdit(response.data);
      });

    }

  }

  useEffect(() => {

    loadHomeData();

    const intervalId = setInterval(loadHomeData, 30000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);


  return (
    <React.Fragment>
      <Helmet title="Blank" />

      <PageHeader pageName={"Home"} parentName={"Powdr"}/>

      <LoadingModal open={loadingProject} title={'Loading Project'} description={'Loading Project Resources, please wait...'} />

      <DisclaimerModal />

      {companyToEdit &&
        <Grid item xs={12}  marginBottom={5} marginTop={10}>
          <Card mb={6} hidden={companyToEdit.accessInvoicePaid === true}>
            <CardContent>
              <Grid item xs={12} marginBottom={5}>

                <Typography variant="h6" gutterBottom>
                  Welcome To Powdr
                </Typography>
              </Grid>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <img src="/static/img/brands/logo-powdr2.png" width={250} style={{borderRadius: '5px'}} alt={"Powdr Logo"}/>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      }

      {companyToEdit &&
        <Grid item xs={12}  marginBottom={5} marginTop={10}>
          <Card mb={6} hidden={companyToEdit.accessInvoicePaid !== true}>
            <CardContent>
              <Grid item xs={12} marginBottom={5}>

                <Typography variant="h6" gutterBottom>
                  Welcome To Powdr
                </Typography>
              </Grid>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <img src="/static/img/brands/logo-powdr2.png" width={250} style={{borderRadius: '5px'}} alt={"Powdr Logo"}/>
                </Grid>
              </Grid>
              <Grid item xs={12} >
                <Typography variant="body2" gutterBottom marginTop={5}>
                  Welcome to Powdr, your account is now <strong>ready</strong>, you can create a new model by clicking the Project button below.
                </Typography>

              </Grid>
            </CardContent>
          </Card>
        </Grid>
      }

      {companyToEdit &&
        <Grid item xs={12}  marginBottom={5}>
          <Card mb={6} hidden={companyToEdit.accessInvoicePaid === true} >
            <CardContent>
              <Grid container spacing={6} >
                <Grid item xs={12}>
                  Need Extra help? <strong>nicola@powdr.co.uk</strong> is here to answer your questions.
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      }


      {companyToEdit &&
        <Grid item xs={12}  marginBottom={5}>
          <Card mb={6}  >
            <CardContent>
              <Grid container spacing={6}  marginBottom={5}>
                <Grid item xs={12}>
                  Create a new Powdr Financial Model:
                </Grid>
              </Grid>
              <Grid container spacing={6} >
                <Grid item xs={12} >
                    <PayForProject/>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      }


      <Grid item xs={12}>
        <Card mb={6}>
          <CardContent>
            <ProjectsViewer></ProjectsViewer>
          </CardContent>
        </Card>
      </Grid>

      <PayForDuplicateProject open={payForDuplicates} setOpen={setPayForDuplicates} />

      <Settings />

    </React.Fragment>
  );
}

export default Home;
