import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";

import {
  CardContent,
  Card as MuiCard,
  TableCell,
  Table,
  TableRow,
  TableBody,
  TableHead,
} from "@mui/material";
import { spacing } from "@mui/system";
import axios from "axios";
import useProject from "../../../../../hooks/useProject";
import PowdrCell from "../../../SharedComponents/PowdrCell/PowdrCell";
import RowTitle from "../../../SharedComponents/RowTitle";
import MonthHeader, { generateXaxisHeaders } from "../../../SharedComponents/MonthHeader";
import { AssumptionLabel } from "../../../SharedComponents/AssumptionLabel";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import PowdrNavBar from "../../../SharedComponents/PowdrNavBar";
import EditHeadCount from "./EditHeadCount";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { zoomState } from "../../../SharedState/ZoomState";
import { headcountsState } from "../../../SharedState/HeadcountsState";
import { Skeleton } from "@mui/material";
import { revenueAndCostState } from "../../../SharedState/RevenueAndCostState";
import { pnlState } from "../../../SharedState/PnLState";

const Card = styled(MuiCard)(spacing);

const tableWidth = {
  width: "100%",
};

const helpTitles = new Map();

helpTitles.set('Average Salary', 'average salary value should be a negative input value');
helpTitles.set('Total Costs', 'Expenses/debits require a negative percentage assumption');
helpTitles.set('Total cost in P&L', 'Expenses/debits require a negative input value');

function HeadCountInput(props) {

  const { project } = useProject();
  const { key } = useProject();

  const [XaxisHeaders, setXaxisHeaders] = useState([]);
  const [openEdit, setOpenEdit] = React.useState(false);
  const tableRef = useRef(null);
  const zoomAtom = useRecoilValue(zoomState);
  const setHeadcountAtom = useSetRecoilState(headcountsState);
  const setProductsAtom = useSetRecoilState(revenueAndCostState);
  const setPnlAtom = useSetRecoilState(pnlState);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Defer heavy rendering to allow loading indicator to show
    setTimeout(() => {
      setIsLoading(false);
    }, 0);
  }, []);

  const {
    headCountItem,
    headCounts,
    refreshData,
    setEditData,
    editData,
    setHeadCounts,
    loadHeadCountData,
  } = props;

  const deleteHeadCount = (handleClose) => {
    var config = {
      method: "delete",
      url: "headcounts",
      data: headCountItem.headCountDto,
    };

    axios(config)
      .then(async function(response) {

        setHeadcountAtom(structuredClone(response.data))

        await axios.get("revenuecos/" + project.id).then(function(response) {
          setProductsAtom(response.data);
        });

        await axios.get("pnl/" + project.id).then(function(response) {
          setPnlAtom(response.data);
        });

        handleClose();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    setXaxisHeaders(generateXaxisHeaders(getCurrentProject().startDate, getCurrentProject().firstForecast));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClick(id) {
    setEditData(id);
  }

  function renderHeavyContent() {
    return (
      <div>
        <PowdrNavBar
          item={headCountItem.headCountDto}
          categories={headCountItem.headCountCategoryDtos}
          deleteMethod={deleteHeadCount}
          refreshDataMethod={loadHeadCountData}
          editAssumptionComponent={<EditHeadCount
                                                  headcount={headCountItem.headCountDto}
                                                  categories={headCountItem.headCountCategoryDtos}
                                                  open={openEdit}
                                                  setOpen={setOpenEdit}>
          </EditHeadCount>}
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
          showExportToExcel={true}
          exportToExcel={{fileName: "Powdr-Headcount-" + headCountItem.headCountDto.name + "-" + new Date().toDateString(),
            sheet : "Headcount",
            currentTableRef : tableRef.current}}
          shallowUpdateUrl={'headcounts/headCountShallow'}
          type={"HEADCOUNT"}
        ></PowdrNavBar>

        <Card
          mb={6}
          sx={{
            ...tableWidth,
            overflowX: "auto",
            overflow: "scroll",
            paddingTop: 0,
          }}
          id={headCountItem.headCountDto.name.replaceAll(" ", "-") + "-table"}
        >
          <CardContent sx={{ paddingTop: 0 , zoom: zoomAtom}}>
            <Table ref={tableRef}>
              {/* X AXIS - PRODUCT NAME, JAN, FEB, MAR, ETC... */}
              <TableHead>
                <TableRow
                  key={headCountItem.headCountDto.name + "-ROW"}
                  id={headCountItem.headCountDto.name + "-ROW"}
                >
                  <TableCell
                    key={headCountItem.headCountDto.name + "-BASE"}
                    id={headCountItem.headCountDto.name + "-BASE"}
                    style={{ position: "sticky", left: 0 }}
                    sx={{
                      minWidth: 200,
                      maxWidth: 200,
                      width: 200,
                      backgroundColor: "#F7F9FC",
                      zIndex: 10,
                    }}
                  >
                    <h2>{headCountItem.headCountDto.name}</h2>
                    <AssumptionLabel label={headCountItem.headCountDto.assumptionDesc} color="success" />
                  </TableCell>
                  {XaxisHeaders.map((yHeader, index) => (
                    <MonthHeader key={Math.random() * 10000000} yHeader={yHeader} name={headCountItem.headCountDto.name} index={index}></MonthHeader>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>


                {headCountItem.headCountCategoryDtos.map((row) => (
                  <TableRow key={row.id} id={row.id} onClick={() => handleClick(row.id)}>

                    <RowTitle name={row.displayName} alt={helpTitles.get(row.displayName)}></RowTitle>

                    {row.headCountMonthDtoList.map((month) => (
                      <PowdrCell
                        key={month.id + "-CELL"}
                        cellData={month}
                        ownerId={row.id}
                        editTableEnabled={editData}
                        items={headCounts}
                        refreshItemData={refreshData}
                        setItems={setHeadCounts}
                        canOverride={row.allowValueOverride}
                        owner={headCountItem.headCountDto}
                        assumption={headCountItem}
                        categoryName={row.name}
                        parentName={headCountItem.headCountDto.name}
                        category={row}
                        monthIndex={month.monthIndex}
                        monthAttributeName={'headCountMonthDtoList'}
                      ></PowdrCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </div>
    )
  }

  return (
    <React.Fragment key={key}>
      {isLoading ? (
        <Skeleton /> // Loading widget or spinner
      ) : (
        renderHeavyContent() // Rendered heavy content
      )}
    </React.Fragment>
  );
}

export default HeadCountInput;
