import React, { useState } from "react";
import axios from "axios";
import {
  Alert,
  Button,
  Card,
  CardContent,
  DialogActions, DialogContentText, FormControl,
  Grid, MenuItem, Select,
  TextField,
  Typography
} from "@mui/material";
import { Field, Formik } from "formik";
import * as Yup from "yup";


const CustomizedSelectForFormik = ({ children, form, field }) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  return (
    <Select
      name={name}
      value={value}
      onChange={(e) => {
        setFieldValue(name, e.target.value);
      }}
    >
      {children}
    </Select>
  );
};

const getCharacterValidationError = (str) => {
  return `Your password must have at least 1 ${str} character`;
};

const checkEmailUnique = async (email) => {
  try {
    const response = await axios.post('/company/validateEmail', { email });
    return !response.data; // Assuming response.data is `false` if the email is unique
  } catch (error) {
    console.error("Error validating email", error);
    return false; // Assume email is not unique if an error occurs
  }
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "Must be at least 3 characters")
    .max(100, "Must be less than 100 characters")
    .required("Required"),
  lastName: Yup.string()
    .min(3, "Must be at least 3 characters")
    .max(100, "Must be less than 100 characters")
    .required("Required"),
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required")
    .test('checkEmailUnique', 'This email is already registered', async (value) => {
      if (!value) return false; // handle empty value

      const isUnique = await checkEmailUnique(value);
      return isUnique; // return true if email is unique, otherwise false
    }),
  mobileTelephone: Yup.string()
    .max(20, "Must be less than 20 characters")
    .required("Telephone is required"),
  password: Yup.string()
    .min(8, "Must be at least 8 characters")
    .max(255)
    .matches(/[0-9]/, getCharacterValidationError("digit"))
    .matches(/[a-z]/, getCharacterValidationError("lowercase"))
    .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
    .required("Required"),
  retypepassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Retype password is required'),
});


function AddUserForm({companies}) {

  const [triggerRefresh, setTriggerRefresh] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(1);

  const [initialValues] = useState({
    company: selectedCompany,
  });

  const handleSubmit = async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {

    try {

      var data = {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        password: values.password,
        mobileTelephone: values.mobileTelephone,
        enabled: true,
        secret: "",
        userType: "USER",
        disabled: false,
        roles: "PARENT",
        company: selectedCompany,
      };

      axios
        .post("security/register/admin", data)
        .then(function (response) {
          resetForm();
          setStatus({ sent: true });
        })
        .catch(function (error) {
          setStatus({ sent: false });
        });

      setSubmitting(false);
    } catch (error) {
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <Card mb={6} sx={{marginTop: 10, marginBottom : 10}}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Add User
        </Typography>
        <Typography variant="body2" gutterBottom>
          Create your user here:
        </Typography>


          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                status,
                setFieldValue
              }) => (
              <form onSubmit={handleSubmit}>

                {status && status.sent && (
                  <Alert severity="success" my={3}>
                    User has been successfully added!
                  </Alert>
                )}

                <Grid container spacing={6} paddingTop={2}>
                  <Grid item md={6}>
                    <TextField
                      error={Boolean(touched.firstName && errors.firstName)}
                      margin="dense"
                      name="firstName"
                      label="First Name"
                      type="text"
                      value={values.firstName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={6} paddingTop={2}>
                  <Grid item md={6}>
                    <TextField
                      error={Boolean(touched.lastName && errors.lastName)}
                      margin="dense"
                      name="lastName"
                      label="Last Name"
                      type="text"
                      value={values.lastName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={6} paddingTop={2}>
                  <Grid item md={6}>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      margin="dense"
                      name="email"
                      label="Email"
                      type="text"
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={6} paddingTop={2}>
                  <Grid item md={6}>
                    <TextField
                      error={Boolean(touched.mobileTelephone && errors.mobileTelephone)}
                      margin="dense"
                      name="mobileTelephone"
                      label="Mobile Telephone"
                      type="text"
                      value={values.mobileTelephone}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={6} paddingTop={2}>
                  <Grid item md={6}>
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      margin="dense"
                      name="password"
                      label="Password"
                      type="password"
                      value={values.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={6} paddingTop={2}>
                  <Grid item md={6}>
                    <TextField
                      error={Boolean(touched.retypepassword && errors.retypepassword)}
                      margin="dense"
                      name="retypepassword"
                      label="Retype Password"
                      type="password"
                      value={values.retypepassword}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid item sx={{ minWidth: 265 }}>
                  <FormControl sx={{ minWidth: 265 }}>
                    <DialogContentText>Company</DialogContentText>
                    <Field
                      name="company"
                      component={CustomizedSelectForFormik}
                      value={values.company}
                      fullWidth
                    >
                      {!!companies &&
                        companies.map((company) => (
                          <MenuItem onClick={() => {
                            setSelectedCompany(company.id)
                            setTriggerRefresh({ ...triggerRefresh })
                          }}
                                    key={company.id} value={company.id}>{company.companyName}</MenuItem>
                        ))}
                    </Field>
                  </FormControl>
                </Grid>


                <DialogActions>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    autoFocus
                    disabled={isSubmitting}
                  >
                    Add User
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>

      </CardContent>
    </Card>
  );
}

export default AddUserForm;
