import React, { useRef, useState, useEffect } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import PowdrCell from "../../../SharedComponents/PowdrCell/PowdrCell";
import { useRecoilValue } from "recoil";
import { revenueAndCostState } from "../../../SharedState/RevenueAndCostState";
import { headcountsState } from "../../../SharedState/HeadcountsState";
import useProject from "../../../../../hooks/useProject";
import PnLMenu from "./PnLMenu";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PowdrNavBar from "../../../SharedComponents/PowdrNavBar";
import Table from "@mui/material/Table";
import PowdrTableHeader from "../../../SharedComponents/PowdrTableHeader";
import TableBody from "@mui/material/TableBody";
import { generateXaxisHeaders } from "../../../SharedComponents/MonthHeader";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import _ from "lodash";
import { getValueOrOverriddenValue } from "../../../SharedComponents/utils/Utils";

const xAxisStyling = {
  minWidth: 200,
  maxWidth: 200,
  fontWeight: "bolder",
  color: "#2052c2",
  backgroundColor: "#F7F9FC",
};

// Style for the modal content box
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxHeight: '90vh',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};


function calculateSplit(revenues, overrideName) {

  let splitTotals = new Array(72).fill(0);
  let arrayOfCategories = [];

  //build totals field
  let totalCategory = {
    totalField: true,
    allowValueOverride: false,
    id : 0,
    name : "Total",
    productMonthDtoList : []
  }


  let categoriesToFind = overrideName === "Revenue from products" ? ["Revenue", "Closing recurring revenue"] : ["Direct product costs"]

  //loop through all the revenue assumptions
  _.forEach(revenues, function (product) {

    //get a category from each based on the overrideName passed (ie Total Revenue - revenue, Direct Products Costs - direct product costs)
    let categoryFound = product.productCategoryDtoList.find(category => categoriesToFind.includes(category.name));

    categoryFound.name = product.productDto.name;
    categoryFound.assumption = product.productDto.assumption;
    categoryFound.products = product.productDto.products;


    //set all the months to be calculated
    _.forEach(categoryFound.productMonthDtoList, function (month, i) {

      month.cellType = "BREAKOUT";


      splitTotals[i] = getValueOrOverriddenValue(month) + splitTotals[i];
    })

    //add that category to this array + rename the row name to be the assumptions name
    arrayOfCategories.push(categoryFound);


  });

  _.forEach(splitTotals, function (split, i) {

    totalCategory.productMonthDtoList[i] = {
      id : i,
      value : split,
      cellType: "BREAKOUT",
      financialTypeEnum : "ACTUAL",
      month : i,
      monthIndex : i,
      valueFormat: "INTEGER"
    }

  });


  arrayOfCategories.push(totalCategory);

  //return all rows
  return arrayOfCategories;

}

function PnlRow({row,
                  setEditData,
                  moveOverhead,
                  loadPnLData,
                  deletePnlCategory,
                  editData,
                  refreshPnLData,
                  setPnl,
                  pnl,
                  pnLItem}) {

  const [open, setOpen] = React.useState(false);
  const tableRef = useRef(null);
  const [XaxisHeaders, setXaxisHeaders] = useState([]);
  const [split, setSplit] = React.useState([]);

  const revenueAtom = useRecoilValue(revenueAndCostState);
  const headcountAtom = useRecoilValue(headcountsState);

  const { project } = useProject();

  useEffect(() => {
    setXaxisHeaders(generateXaxisHeaders(getCurrentProject().startDate, getCurrentProject().firstForecast ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    setSplit(calculateSplit(structuredClone(revenueAtom), row.overrideName));


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  /**
   * When the user clicks on a row, we set the Edit Data value to be that rows id
   *
   * Then the Powdr cell component will show the input boxes for that entir row.
   *
   * So the user can enter values into the P&L
   *
   * @param id
   */
  function handleClick(id) {
    setEditData(id);
  }


  return (
    <React.Fragment>

      <TableRow key={row.id}
                id={row.id}
                sx={{borderStyle: row.totalField === true && !["Net Profit Margin", "EBITDA Margin", "Gross Profit Margin"].includes(row.overrideName) ? "double" : "none", }}
                onClick={() => handleClick(row.id)}>

        <TableCell
          title={"All revenues/credits should be positive values. \n\nAll expenses/debits should be negative values."}
          component="th"
          scope="row"
          variant={"body"}
          sx={{ ...xAxisStyling, backgroundColor: "#F7F9FC" }}
          style={{
            position: "sticky",
            left: 0,
            zIndex: 10,
          }}
          onClick={() => setOpen(!open)}
        >
          {["Revenue from products", "Direct product costs"].includes(row.overrideName) && <ArrowRightIcon /> }
          {row.position !== null
            ? <PnLMenu name={row.overrideName === "" ? row.name : row.overrideName}
                       pnlItem={row}
                       moveOverhead={moveOverhead}
                       refreshPnLData={loadPnLData}
                       projectId={project.id}
                       deletePnlCategory={deletePnlCategory}
                       products={structuredClone(revenueAtom)}
                       headcounts={structuredClone(headcountAtom)}
            ></PnLMenu>
            : row.overrideName === "" ? row.name : row.overrideName
          }
        </TableCell>

        {row.pnLMonthDtoList.map((month) => (
          <PowdrCell
            key={month.id + "-CELL"}
            cellData={month}
            ownerId={row.id}
            editTableEnabled={editData}
            items={pnl}
            refreshItemData={refreshPnLData}
            setItems={setPnl}
            assumption={row}
            categoryName={row.name}
            parentName={pnLItem.pnLDto.name}
            category={row}
            monthIndex={month.monthIndex}
            monthAttributeName={'pnLMonthDtoList'}
            showRightClickMenu={false}
          ></PowdrCell>
        ))}
      </TableRow>

      {["Revenue from products", "Direct product costs"].includes(row.overrideName) &&


        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {row.overrideName}
            </Typography>


            <PowdrNavBar
              item={{name: "Product Breakouts"}}
              categories={split}
              showExportToExcel={true}
              showProgressBar={false}
              showEdit={false}
              showDelete={false}
              showNavigation={true}
              exportToExcel={{
                fileName: "Powdr-P&L-" + row.name + "-" + new Date().toDateString(),
                sheet: "P&L",
                currentTableRef: tableRef.current
              }}
            ></PowdrNavBar>


            <Box
              sx={{
                mt: 2,
                overflowX: 'auto', // Enables horizontal scrolling
              }}
            >

              <Typography id="modal-modal-description" sx={{ mt: 2 }} component="div">
                <Box sx={{ margin: 1 }}>



                  <Table size="small" aria-label="purchases" ref={tableRef}>

                    <PowdrTableHeader name={"Product Breakouts"}
                                      XaxisHeaders={XaxisHeaders}
                                      typeColour={"info"}/>

                    <TableBody>


                      {split.filter(product => !(
                        getCurrentProject().hideDefaultGroups === true &&
                        product.products?.length === 0 &&
                        product.name !== "Total" &&
                        product.name.endsWith("(Default Product Group)")
                      )).map((movement) => (

                        <TableRow key={movement?.id}
                                  id={movement?.id}
                                  sx={{borderStyle: movement?.totalField === true && ["Total"].includes(movement.name) ? "double" : "none", }}>
                          <TableCell
                            component="th"
                            scope="row"
                            variant={"body"}
                            sx={{ ...xAxisStyling, backgroundColor: "#F7F9FC" }}
                            style={{
                              position: "sticky",
                              left: 0,
                              backgroundColor: "#F7F9FC",
                              zIndex: 10,
                            }}
                            onClick={() => setOpen(!open)}
                          >
                            {movement?.name}
                          </TableCell>

                          {movement?.productMonthDtoList.map((month) => (
                            <PowdrCell
                              key={month.id + "-CELL"}
                              cellData={month}
                              ownerId={movement?.id}
                              category={row}
                              monthIndex={month.monthIndex}
                              monthAttributeName={'pnLMonthDtoList'}
                            ></PowdrCell>
                          ))}
                        </TableRow>


                      ))}

                    </TableBody>

                  </Table>
                </Box>
              </Typography>

            </Box>

          </Box>
        </Modal>
      }


    </React.Fragment>
  );
}

export default PnlRow;
