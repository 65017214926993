import React, { useState, useRef } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from "@mui/material";
import { NumberFormatCustom } from "./PowdrCell/PowdrCell";
import { useRecoilState, useRecoilValue } from "recoil";
import { overrideModalCellDataState, overrideModalState } from "../SharedState/OverrideModalState";
import { balanceSheetState } from "../SharedState/BalanceSheetState";
import { pnlState } from "../SharedState/PnLState";
import UpdatePowdrModelGlobal from "./UpdatePowdr/UpdatePowdrModelGlobal";

export default function OverrideValueModal(props) {

  const updatePowdrModelGlobalRef = useRef();

  const { items, assumptionType, callBack } = props;

  const [showSpinner, setShowSpinner] = useState(false);
  const [overriddenValue, setOverriddenValue] = useState('');

  const [overrideModalAtom, setOverrideModalAtom] = useRecoilState(overrideModalState);
  const overrideModalCellDataAtom = useRecoilValue(overrideModalCellDataState);
  const balanceAtom = useRecoilValue(balanceSheetState);
  const pnlAtom = useRecoilValue(pnlState);

  const handleClose = () => {
    setShowSpinner(false);
    setOverrideModalAtom(false);
  };

  function useCalculatedValue() {

    setShowSpinner(true);

    let monthToUpdate = null;

    if (assumptionType === "OVERHEAD") {
      monthToUpdate = findMonthToUpdate( "overheadCategoryDtoList", "overheadMonthDtoList");
    } else if (assumptionType === "PRODUCT") {
      monthToUpdate = findMonthToUpdate( "productCategoryDtoList", "productMonthDtoList");
    } else if (assumptionType === "HEADCOUNT") {
      monthToUpdate = findMonthToUpdate( "headCountCategoryDtos", "headCountMonthDtoList");
    } else if (assumptionType === "CURRENT_LIABILITY") {
      monthToUpdate = findMonthToUpdate( "currentLiabilityCategoryDtos", "currentLiabilityMonthDtos");
    }


    monthToUpdate.valueOverriden = null;
    monthToUpdate.cellType = "CALCULATED";

    //now do a global recalculate
    if (assumptionType === "OVERHEAD") {
      updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(balanceAtom, pnlAtom, null, items, null);
    } else if (assumptionType === "PRODUCT") {
      updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(balanceAtom, pnlAtom, null, null, items);
    } else if (assumptionType === "HEADCOUNT") {
      updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(balanceAtom, pnlAtom, items, null, null);
    } else if (assumptionType === "CURRENT_LIABILITY") {
      updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(balanceAtom, pnlAtom, null, null, null, items);
    }

    setTimeout(() => {
      // Code to run after 8 seconds
      setShowSpinner(false);
      setOverrideModalAtom(false);
    }, 4000);
  }

  function findMonthToUpdate(categoryName, monthName) {

    let listOfMonths = [];

    //collect all months from all assumptions
    for (let assumption of items) {
      for (let category of assumption[categoryName]) {
        listOfMonths = listOfMonths.concat(category[monthName]);
      }
    }

    let monthToUpdate = listOfMonths.find(month => month.id === overrideModalCellDataAtom.id);

    return monthToUpdate;
  }

  function setValueOverriden() {

    setShowSpinner(true);

    let monthToUpdate = null;

    if (assumptionType === "OVERHEAD") {
      monthToUpdate = findMonthToUpdate( "overheadCategoryDtoList", "overheadMonthDtoList");
    } else if (assumptionType === "PRODUCT") {
      monthToUpdate = findMonthToUpdate( "productCategoryDtoList", "productMonthDtoList");
    } else if (assumptionType === "HEADCOUNT") {
      monthToUpdate = findMonthToUpdate( "headCountCategoryDtos", "headCountMonthDtoList");
    } else if (assumptionType === "CURRENT_LIABILITY") {
      monthToUpdate = findMonthToUpdate( "currentLiabilityCategoryDtos", "currentLiabilityMonthDtos");
    }




    let overridenValue_ = parseFloat(overriddenValue);
     if (!isNaN(overridenValue_)) {

       monthToUpdate.valueOverriden = overridenValue_;
       monthToUpdate.cellType = "OVERRIDEN";
       monthToUpdate.previousValueOverriden = -9999;
       monthToUpdate.touched = true;

       //now do a global recalculate
       if (assumptionType === "OVERHEAD") {
         updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(balanceAtom, pnlAtom, null, items, null);
       } else if (assumptionType === "PRODUCT") {
         updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(balanceAtom, pnlAtom, null, null, items);
       } else if (assumptionType === "HEADCOUNT") {
         updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(balanceAtom, pnlAtom, items, null, null);
       }  else if (assumptionType === "CURRENT_LIABILITY") {
         updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(balanceAtom, pnlAtom, null, null, null, items);
       }



       setTimeout(() => {
         // Code to run after 8 seconds
         setShowSpinner(false);
         setOverrideModalAtom(false);
       }, 4000);


       if (callBack !== null & callBack !== undefined) {
         callBack();
       }

    } else {
       alert("Please enter a valid number");
       setShowSpinner(false);
    }
  }

  return (
    <React.Fragment>
      <Dialog
        open={overrideModalAtom}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
              setShowSpinner(false);
             setOverrideModalAtom(false);
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Override Calculated Value"}
        </DialogTitle>
        <DialogContent>

            <TextField
              id={"overridenValueTextField"}
              value={overrideModalCellDataAtom.valueOverriden}
              onChange={(e) => setOverriddenValue(e.target.value)}
              placeholder={'0'}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />

        </DialogContent>
        <DialogActions>
          <Button onClick={useCalculatedValue} color="primary">
            {showSpinner ? "" : "Undo Override"}
          </Button>
          <Button onClick={() => handleClose()} color="primary">
            {showSpinner ? "" : "Cancel"}
          </Button>
          <Button onClick={setValueOverriden} color="primary" autoFocus>
            {showSpinner ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
      <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>
    </React.Fragment>
  );
}
