import React, { useEffect, useState } from "react";
import PageHeader from "../SharedComponents/PageHeader";
import Divider from "@mui/material/Divider";

import AddUserForm from "./components/AddUserForm";
import axios from "axios";

function YourAccount() {

  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    axios.get('company/').then(function(response) {
      setCompanies(response.data);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>

      <PageHeader pageName={"Add User"} parentName={"Powdr Admin"}/>

      <Divider my={6} />

      <AddUserForm companies={companies}/>

    </React.Fragment>
  );
}

export default YourAccount;
