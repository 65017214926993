import React, { useEffect, useState } from "react";

import {
  Table,
  TableRow,
  TableBody,
} from "@mui/material";
import useProject from "../../../../../hooks/useProject";
import RowTitle from "../../../SharedComponents/RowTitle";
import {
  generateXAnnualAxisHeadersAnnual,
} from "../../../SharedComponents/MonthHeader";
import PowdrCell from "../../../SharedComponents/PowdrCell/PowdrCell";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import { Skeleton } from "@mui/material";
import PowdrTableHeader from "../../../SharedComponents/PowdrTableHeader";


export default function RevenueDashboardInput(props) {
  const { key } = useProject();

  const [XaxisHeaders, setXaxisHeaders] = useState([]);

  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    // Defer heavy rendering to allow loading indicator to show
    setTimeout(() => {
      setIsLoading(false);
    }, 0);
  }, []);


  const { productItem } = props;


  useEffect(() => {
    setXaxisHeaders(generateXAnnualAxisHeadersAnnual(getCurrentProject().startDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function renderHeavyContent() {
    return (
      <div>

            <Table>

              <PowdrTableHeader name={productItem?.productDto?.name}
                                assumption={productItem?.productDto?.assumptionDesc}
                                analysisAssumption={productItem?.productDto?.analysisAssumptionDesc}
                                XaxisHeaders={XaxisHeaders}
                                typeColour={"info"}/>

              {/*loop through the categories*/}
              <TableBody>
                {productItem.productCategoryDtoList.map((row) => (
                  <TableRow key={Math.random() * 1000} id={row.id}>

                    <RowTitle name={row.name}></RowTitle>

                    {row.productMonthDtoList.map((month) => (
                      <PowdrCell
                        key={month.id + "-CELL"}
                        cellData={month}
                        ownerId={row.id}
                      ></PowdrCell>
                    ))}

                  </TableRow>
                ))}
              </TableBody>

            </Table>

      </div>
    );
  }

  return (
    <React.Fragment key={key}>
      {isLoading ? (
        <Skeleton /> // Loading widget or spinner
      ) : (
        renderHeavyContent() // Rendered heavy content
      )}
    </React.Fragment>
  );
}