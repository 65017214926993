import React, { useRef } from "react";
import { TableCell, TextField } from "@mui/material";
import NumberFormat from "react-number-format";
import { copyTextToInputs } from "../utils/PowdrCopyPaste";
import Tooltip from '@mui/material/Tooltip';
import { getCurrentProject } from "../ProjectServices";
import { useSetRecoilState } from "recoil";
import { overrideModalCellDataState, overrideModalState } from "../../SharedState/OverrideModalState";
import { pastingState } from "../../SharedState/DrawerState";

const tableStyling = {
  minWidth: 90,
};

export const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString

    />
  );
});


export function formatCalculatedField(valueToFormat) {
  let tempVal = valueToFormat;

  if (tempVal != null) {
    tempVal =
      tempVal < 0
        ? "(" + Math.abs(tempVal).toLocaleString() + ")"
        : tempVal.toLocaleString();
    return tempVal;
  } 
  else {
    return 0;
  }
 
}

function isSafeNumberToUse(val) {
  if (!isNaN(Number.parseFloat(val))) {
    return parseFloat(val);
  } else {
    return 0;
  }
}

function addDirectionalArrow(cell) {

  if (cell.showMovementArrow !== undefined && cell.showMovementArrow === true) {

    if (cell.value === 0) {
      return ""
    }

    if (cell.positiveMovementSymbol === undefined && cell.value > 0) { //positive trend (default)
      return "↑"
    } else if (cell.positiveMovementSymbol !== undefined && cell.value > 0) { //positiveMovementSymbol if defined will override the default ↑ with its value
      return cell.positiveMovementSymbol;
    }


    if (cell.negativeMovementSymbol === undefined && cell.value < 0) { //negative trend (default)
      return "↓"
    } else if (cell.negativeMovementSymbol !== undefined && cell.value < 0) { //negativeMovementSymbol if defined will override the default ↓ with its value
      return cell.negativeMovementSymbol;
    }

  }

  return "";

}


export function formatCalculatedFieldWithPercentageCheck(valueToFormat, valueType, valueOverriden, cell) {

  let tempVal = valueOverriden !== null && valueOverriden !== undefined ? valueOverriden : valueToFormat; //if overriden value is set, then use that

  if (cell.resolution != null && cell.resolution > 0 && valueType === "INTEGER") {

    let cellValue = isSafeNumberToUse(tempVal);

    tempVal =

      tempVal < 0
        ? "(" + (cellValue).toFixed(cell.resolution).toLocaleString().replace("-", "") + ")" + addDirectionalArrow(cell)
        : cellValue.toFixed(cell.resolution).toLocaleString() + addDirectionalArrow(cell);

    return tempVal;
  }

  if (valueType === "FLOAT") {

    if (tempVal == null) {
      return 0;
    }

    tempVal =
      tempVal < 0
        ? "(" + Number(tempVal).toFixed(2) + ")" + addDirectionalArrow(cell)
        : Number(tempVal).toFixed(2) + addDirectionalArrow(cell);

    return tempVal;
  }

  if (valueType === "PERCENTAGE") {
    //return tempVal + "%"

    if (tempVal == null) {
      return 0;
    }

    tempVal =
      isSafeNumberToUse(tempVal) < 0
        ? isSafeNumberToUse(tempVal).toFixed(2) + "%" + addDirectionalArrow(cell)
        : isSafeNumberToUse(tempVal).toFixed(2)  + "%" + addDirectionalArrow(cell);

    return tempVal;
  }

  if (tempVal != null) {
    tempVal =
      tempVal < 0
        ? "(" + Math.round(Math.abs(tempVal)).toLocaleString() + ")" + addDirectionalArrow(cell)
        : Math.round(Math.abs(tempVal)).toLocaleString() + addDirectionalArrow(cell);

    return tempVal;
  }
  else {
    return 0;
  }

}

const PercentageFieldsFormatCustom = React.forwardRef(
  function PercentageFieldsFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isNumericString
        suffix="%"
      />
    );
  }
);

function getCalculatedBackgroundColor(cellData) {

  let bgColour = "#f2f2f2";

  //commented out background color, we may need it later.
  if (cellData.cellType === "CALCULATED" && cellData.financialTypeEnum === "BREACH_LINE") {

    if (cellData.value === 0){
      bgColour = "#008000";
    } else {
      bgColour = "#FF0000";
    }

  }


  if (cellData.cellType === "OVERRIDEN") {
    bgColour = "#FFAF80";
  }

  return bgColour;
}

function getCalculatedTextColor(cellData) {

  let textColour;

  if (cellData.cellType === "CALCULATED" && cellData.financialTypeEnum === "CHECK_LINE") {

    if (cellData.value === 0){
      textColour = "#000000";
    } else {
      textColour = "#FF0000";
    }

  } else if (cellData.cellType === "CALCULATED" && cellData.financialTypeEnum === "BREACH_LINE") {

    if (cellData.value === 0){
      textColour = "#008000";
    } else {
      textColour = "#FF0000";
    }

  } else {
    textColour = "#000000";
  }

  if (cellData.textColour !== undefined) {
    textColour = cellData.textColour;
  }


  return textColour;
}

function getBackgroundColor(cellData) {
  let bgColour;

  switch(cellData.financialTypeEnum) {
    case "ACTUAL":
      bgColour = "#fff2cc";
      break;
    case "FORECAST":
      bgColour = "#E2EFDA";
      break;
    case "OPENING":
      bgColour = "#cbc1a2";
      break;
    default:
      bgColour = "#fff2cc";
  }

  return bgColour;
}




function canOverrideCheck(canOverride, cellData, owner) {

  let firstForecast = Number(getCurrentProject().firstForecast);

  //special case for annual growth rates
  if (owner !== undefined
    && owner !== null
    && owner.assumption === "ANNUAL_GROWTH_RATE"
    && canOverride === true
    && cellData.monthIndex >= firstForecast) {
    return true;
  }

  return canOverride === true && cellData.financialTypeEnum !== "ACTUAL";
}

function PowdrCell(props) {

  const {
    cellData,
    items,
    refreshItemData,
    editTableEnabled,
    ownerId,
    setItems,
    canOverride=false,
    owner,
    assumption,
    categoryName = "",
    parentName = "",
  } = props;

  const inputRef = useRef(null);

  const setOverrideModalAtom = useSetRecoilState(overrideModalState);
  const setOverrideModalCellDataAtom = useSetRecoilState(overrideModalCellDataState);

  const setPastingAtom = useSetRecoilState(pastingState);

  function showOverrideModal(cellData, canOverride) {


    if (canOverride && cellData.financialTypeEnum === "FORECAST") {
      setOverrideModalCellDataAtom(cellData);
      setOverrideModalAtom(true);
    }

  }

  const handleFocus = () => {
    cellData.previousValue = cellData.value;
    cellData.previousValueOverriden = cellData.valueOverriden;
  }

  const handleBlur = (event) => {

    //get the value from the input
    //update the product object
    //call refresh product data
    //which updates the product data object
    //and runs any calculations that might be required
    var updatedItems = [...items];

    if (updatedItems.length > 0) {
      setItems(updatedItems);
      cellData.assumptions = [assumption];
      cellData.categoryName = categoryName;
      cellData.parentName = parentName;
      refreshItemData(cellData);
    }
  };



  function updateItemsWithPastedValues(pastedItemsMap) {

    pastedItemsMap.forEach(copyItemToMonth)

    var updatedItems = [...items];

    if (updatedItems.length > 0) {
      setItems(updatedItems);
      cellData.copyPaste = true; //used to disable the addValueToRestOfYear when copy and pasting
      cellData.assumptions = [assumption];
      cellData.categoryName = categoryName;
      cellData.parentName = parentName;
      refreshItemData(cellData);
    }

  }

  function copyItemToMonth(value, key, map) {

    let itemToProcess;

    for (let i=0; i < items.length; i++) {

      let itemDto = findDtoAttribute(items[i], 'Dto');
      let parentId = findAttribute(cellData);

      if (itemDto.id === Number(parentId)) {
        itemToProcess = items[i];
      }

    }


    //in the items, find the category and then in the category, find the monthly
    let categories = findCategoryAttribute(itemToProcess, "Category");
    let category = categories.find(category => category.id === Number(cellData.category));

    //then set the value from the map
    let months = findCategoryAttribute(category, "Month");

    let month = getObjectById(months, key);

    month.value = Number(value);
    month.touched = true;
  }

  function findAttribute(obj) {
    const attributes = ['currentasset', 'pnl', 'fixedasset','currentliability','equity','debt','headcount','overhead','product', 'balance', 'covenant', 'vat', 'releaseProfile'];

    // Loop through the array of attributes and return the value if found
    for (let i = 0; i < attributes.length; i++) {
      const attribute = attributes[i];
      if (obj.hasOwnProperty(attribute)) {
        return obj[attribute];
      }
    }

    // If none of the attributes were found, return null
    return null;
  }


  function findDtoAttribute(obj, partialEndAttributeNameToMatch) {
    for (let prop in obj) {
      if (prop.endsWith(partialEndAttributeNameToMatch)) {
        return obj[prop];
      }
    }
    return null; // If no matching attribute is found
  }


  function findCategoryAttribute(obj, partialAttributeNameToMatch) {
    for (let prop in obj) {
      if (prop.includes(partialAttributeNameToMatch)) {
        return obj[prop];
      }
    }
    return null; // If no matching attribute is found
  }

  function getObjectById(arr, idValue) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id === Number(idValue)) {
        return arr[i];
      }
    }
    return null; // No object found with matching ID
  }


  async function handlePaste(event) {

    //todo - find the category that we're pasting into
    //copy that values into that category
    //save the atom
    //the screen should redraw and it should be simplified.

    event.preventDefault(); // Prevent the default paste behavior

    try {
      setPastingAtom(true);

      await copyTextToInputs().then(pastedItemsMap => {
        // Update the values in the items array
        updateItemsWithPastedValues(pastedItemsMap);
      }); // Call the copyTextToInputs() method

      loseFocus(event);
    } catch (error) {
      console.error("An error occurred during the paste operation:", error);
    } finally {
      // Ensure these functions are called regardless of success or failure
      setPastingAtom(false);
    }
  }



  function loseFocus(event) {
    // Get the element that triggered the event
    let element = event.target;

    // Remove focus from the element
    element.blur();
  }

  // function pressTabWithinElement(event) {
  //   // Get the element that triggered the event
  //   let element = event.target;
  //
  //   // Create a new KeyboardEvent for the Tab key
  //   let tabEvent = new KeyboardEvent('keydown', {
  //     key: 'Tab',
  //     code: 'Tab',
  //     keyCode: 9,       // 'Tab' key code
  //     charCode: 9,      // 'Tab' character code
  //     which: 9,         // 'Tab' which code
  //     bubbles: true,    // Event bubbles up through the DOM
  //     cancelable: true  // Event can be cancelled
  //   });
  //
  //   // Dispatch the event to the triggering element
  //   element.dispatchEvent(tabEvent);
  //   console.log('Tab pressed');
  // }

  const handleChange = (event) => {

    //try {

      cellData.value = isSafeNumberToUse(event.target.value);

      cellData.touched = true;

    // } catch (e) {
    //   //with quick inputs, on occasion the cellData can be readOnly (I'm not entirely sure why)
    //   console.log('cellData was readonly', event.target.value, cellData)
    // }
  };

  /* Increase the specificity */

  return (
    <React.Fragment>
      {cellData.cellType === "DISABLED" && (
        <TableCell
          id={cellData.id + "-DISABLED"}
          variant={"body"}
          align={"right"}
          sx={{ ...tableStyling, backgroundColor: "white" }}
        ></TableCell>
      )}

      {cellData.cellType === "ENABLED" && (
        <TableCell
          id={cellData.id + "-ENABLED"}
          variant={"body"}
          align={"right"}
          sx={{
            ...tableStyling,
            backgroundColor: getBackgroundColor(cellData),
            paddingBottom: 0,
            paddingTop: 0,
          }}
        >
          {ownerId === editTableEnabled ? (
            <TextField
              id="outlined-basic"
              value={cellData.value === 0 && cellData.touched === false ? null : cellData.value}
              placeholder={cellData.valueFormat === 'PERCENTAGE' ? '0%' : '0'}
              ref={inputRef}
              onBlur={handleBlur}
              onChange={handleChange}
              onPaste={handlePaste}
              onFocus={handleFocus}
              inputProps={{
                style: { textAlign: "right" },
              }}
              sx={{
                padding: 0,
                textAlign: "right",
                "& .MuiOutlinedInput-root.Mui-focused": {
                  borderColor: "yellow",
                  border: "solid",
                },
              }}
              variant="standard"
              InputProps={{
                inputComponent:
                  cellData.valueFormat === "INTEGER"
                    ? NumberFormatCustom
                    : PercentageFieldsFormatCustom,
                disableUnderline: true,
              }}
            />
          ) : cellData.valueFormat === "INTEGER" ? (
            formatCalculatedField(cellData.value)
          ) : (
            isSafeNumberToUse(cellData.value).toFixed(2) + "%"
          )}
        </TableCell>
      )}

      {/* CALCULATED, OVERRIDEN, or BREAKOUT Cell */}
      {(cellData.cellType === "CALCULATED" ||
        cellData.cellType === "OVERRIDEN" ||
        cellData.cellType === "BREAKOUT") && (
        <React.Fragment>
          <Tooltip
            title={
              canOverrideCheck(canOverride, cellData, owner)
                ? "Double click to override value"
                : ""
            }
            disableHoverListener={!canOverrideCheck(canOverride, cellData, owner)}
          >
            <TableCell
              id={cellData.id + "-CALCULATED"}
              variant={"body"}
              align={"right"}
              onBlur={handleBlur}
              onDoubleClick={() => showOverrideModal(cellData, canOverride)}
              sx={{
                ...tableStyling,
                backgroundColor: getCalculatedBackgroundColor(cellData),
                paddingBottom: 0,
                paddingTop: 0,
                textAlign: "right",
                fontWeight: "bolder",
                color: getCalculatedTextColor(cellData),
                cursor: canOverrideCheck(canOverride, cellData, owner)
                  ? "pointer"
                  : "default",
                "&:hover": canOverrideCheck(canOverride, cellData, owner)
                  ? { outline: "2px solid blue" }
                  : {},
              }}
            >
              {formatCalculatedFieldWithPercentageCheck(
                cellData.value,
                cellData.valueFormat,
                cellData.valueOverriden,
                cellData
              )}
            </TableCell>
          </Tooltip>


        </React.Fragment>
      )}

    </React.Fragment>
  );
}

export default PowdrCell;
