import React, { useEffect, useState } from "react";
import axios from "axios";
import useProject from "../../../../hooks/useProject";
import AuditTable from "./components/AuditTable";
import PageHeader from "../../SharedComponents/PageHeader";
import styled from "@emotion/styled";
import { Divider as MuiDivider } from "@mui/material";
import { spacing } from "@mui/system";
import { useParams } from "react-router-dom";
import Settings from "../../../../components/Settings";

const Divider = styled(MuiDivider)(spacing);

export default function Audit() {

  const { projectId } = useParams(); // Access projectId from the URL


  const { project } = useProject();
  const [auditData, setAuditData] = useState(null);


  useEffect(() => {

    let idToUser = project.id;

    if (projectId != null) {
      idToUser = projectId;
    }


    axios.get('/audit/' + idToUser).then(function (response) {
      setAuditData(response.data);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <React.Fragment>

      <PageHeader pageName={"Project Audit"} parentName={"Audit"}/>

      <Divider my={6} />

      {!!auditData &&
        <AuditTable data={auditData}></AuditTable>
      }

      <Settings />

    </React.Fragment>
  )

}