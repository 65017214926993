import React, {useState, useEffect} from "react";
import styled from "@emotion/styled";


import {
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";
import ControlsBar from "../../SharedComponents/ControlsBar";
import AddCovenant from "./components/AddCovenant";
import { useRecoilState, useRecoilValue } from "recoil";
import Settings from "../../../../components/Settings";
import axios from "axios";
import { debtsState } from "../../SharedState/DebtsState";
import CovenantInput from "./components/CovenantInput";
import { calculateCovenants } from "./calculations/Calculations";
import { pnlState } from "../../SharedState/PnLState";
import useAuth from "../../../../hooks/useAuth";
import PayForCovenants from "./components/PayForCovenants";
import { balanceSheetState } from "../../SharedState/BalanceSheetState";
import { covenantState } from "../../SharedState/CovenantState";
import { hasCellValueChanged } from "../../SharedComponents/utils/Utils";
import PageHeader from "../../SharedComponents/PageHeader";


const Divider = styled(MuiDivider)(spacing);


function Covenant() {
  const { user } = useAuth();
  const [company, setCompany] = useState();
  const [covenants, setCovenants] = useState([]);
  const [editTableEnabled, setEditTableEnabled] = useState("");
  const debtsAtom = useRecoilValue(debtsState);
  const pnlAtom = useRecoilValue(pnlState);
  const balanceAtom = useRecoilValue(balanceSheetState);
  const [covenantAtom, setCovenantAtom] = useRecoilState(covenantState);

  async function loadCovenant() {

    console.log('loadCovenant', covenantAtom);
    let covenants = structuredClone(covenantAtom)

    calculateCovenants(covenants, debtsAtom, pnlAtom, balanceAtom);

    setCovenants(covenants);

  }

  // async function loadCovenant(recalculate = false) {
  //
  //   let project = await getProjectId();
  //
  //   const config = {
  //     method: "get",
  //     url: "covenant/" + project.id
  //   };
  //
  //   axios(config)
  //     .then(function(response) {
  //
  //       if (recalculate) {
  //         let covResponse = response.data;
  //         calculateCovenants(covResponse, debtsAtom, pnlAtom, balanceAtom);
  //         setCovenants(covResponse);
  //       } else {
  //         let covResponse = response.data;
  //         //calculateCovenants(covResponse, debtsAtom, pnlAtom, balanceAtom);
  //         setCovenants(covResponse);
  //         setLoaded(true);
  //       }
  //
  //     })
  //     .catch(function(error) {
  //       console.log(error);
  //     });
  // }

  // https://reactjs.org/docs/hooks-reference.html#usecallback
  // const updateBlog = React.useCallback((covenant) => {
  //
  //   setSaving(true);
  //
  //   // filter covenant list to only contain ones that match the editedcovenant of ids
  //   const covenantToUpdate = covenant
  //     .filter(covenant => edited.includes(covenant.covenantDto.id));
  //
  //   if (covenantToUpdate.length === 0) {
  //     setSaving(false);
  //     return;
  //   }
  //
  //   var config = {
  //     method: "put",
  //     url: "covenant/" + covenantToUpdate[0].covenantDto.project,
  //     data: covenantToUpdate,
  //   };
  //
  //   axios(config)
  //     .then(function (response) {
  //       setSaving(false);
  //       edited.length = 0;
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  //
  // useAutosave({ data: covenants, onSave: updateBlog, interval: 2000, saveOnUnmount: true });

  function refreshData(cellData) {

    if (hasCellValueChanged(cellData)) {
      console.log('data changed!')
      calculateCovenants(covenants, debtsAtom, pnlAtom, balanceAtom);

      setCovenantAtom(structuredClone(covenants));
    }



  }



  // function refreshData(cellData) {
  //
  //   if (!edited.includes(cellData.covenant)) {
  //     edited.push(cellData.covenant)
  //   }
  //
  //   //todo calculateCovenants
  //   calculateCovenants(covenants, debtsAtom, pnlAtom, balanceAtom);
  // }

  // useEffect(() => {
  //   calculateCovenants(covenants, debtsAtom, pnlAtom, balanceAtom);
  // }, [covenants, debtsAtom, pnlAtom, balanceAtom]);

  useEffect(() => {
    console.log('Covenant has changed!')
    loadCovenant()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [covenantAtom]);

  useEffect(() => {
    loadCovenant();

    if (user != null) {
      axios.get('company/' + user.company).then(function(response) {
        setCompany(response.data);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>

      <PageHeader pageName={"Covenant"} parentName={"Analytics"}/>


      <Divider my={6} />

      {company &&
        company.projectType === 'PNL' &&
          <ControlsBar addAssumptionElement={
            <PayForCovenants/>
          }
          />
      }

      {company &&
        company.projectType !== 'PNL' &&
          <ControlsBar addAssumptionElement={
            <AddCovenant names={[]} loadData={loadCovenant}  debts={structuredClone(debtsAtom)}  ></AddCovenant>
          }
          />
      }

      {!!covenants &&
        covenants.map((covenant) => (
          <CovenantInput
            key={covenant.covenantDto.id}
            covenantItem={covenant}
            covenant={covenants}
            refreshData={refreshData}
            setEditData={setEditTableEnabled}
            editData={editTableEnabled}
            setCovenants={setCovenants}
            loadData={loadCovenant}
          ></CovenantInput>
        ))}


      <Settings/>

    </React.Fragment>
  );
}

export default Covenant;
