import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControl,
  FormControlLabel, FormGroup,
  FormLabel,
  Grid, LinearProgress, List, ListItem, ListItemText,
  Radio,
  RadioGroup,
  TextField, Typography
} from "@mui/material";
import { Formik } from "formik";
import useProject from "../../../../../hooks/useProject";
import axios from "axios";
import * as Yup from "yup";
import { Add } from "@mui/icons-material";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { overheadsState } from "../../../SharedState/OverheadsState";
import { revenueAndCostState } from "../../../SharedState/RevenueAndCostState";
import { headcountsState } from "../../../SharedState/HeadcountsState";
import { currentLiabilitiesState } from "../../../SharedState/CurrentLiabilitiesState";
import { refreshVatPackage, vatPackageState } from "../../../SharedState/VatPackageState";
import { vatState } from "../../../SharedState/VatState";
import { currentAssetsState } from "../../../SharedState/CurrentAssetsState";
import { getProjectIdNotAsync } from "../../../SharedComponents/ProjectServices";
import UpdatePowdrModelGlobal from "../../../SharedComponents/UpdatePowdr/UpdatePowdrModelGlobal";

function AddOverheads() {

  const updatePowdrModelGlobalRef = useRef();

  const [open, setOpen] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);
  const { project } = useProject();
  const [triggerRefresh, setTriggerRefresh] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState({ id : 0 });
  const [overheadsForProject, setOverheadsForProject] = useState([]);
  const [overheads, setOverheads] = useState([]);
  const [selectedOverheads, setSelectedOverheads] = useState([]);

  const productsAtom = useRecoilValue(revenueAndCostState);
  const headcountsAtom = useRecoilValue(headcountsState);

  const setCurrentLiabiltiesAtom = useSetRecoilState(currentLiabilitiesState);

  const [products, setProducts] = useState([]);
  const [headcounts, setHeadcounts] = useState([])
  const [overheadAtom, setOverheadAtom] = useRecoilState(overheadsState);

  const currentAssetAtom = useRecoilValue(currentAssetsState);
  const setVatPackageAtom =  useSetRecoilState(vatPackageState);
  const vatRateAtom = useRecoilValue(vatState);

  const [assumptionFormatValue, setAssumptionFormatValue] =
    useState("DIRECT_INPUT");
  const [grossProfitLineFlagValue, setGrossProfitLineFlagValue] =
    useState("ABOVE");


  async function loadProjects() {

    await axios.get('projectsByOwner/' + getProjectIdNotAsync().owner).then(function(response) {
      setProjects(response.data);
    })

    axios.get("overheads/" + getProjectIdNotAsync().id).then(function(response) {
      setOverheads(response.data);
    });

  }


  useEffect( () => {
    if (selectedProject !== undefined && selectedProject !==  undefined && assumptionFormatValue === "OVERHEAD_DIVISIONAL_CONSOLIDATION") {

       axios.get("overheads/" + selectedProject.id).then(function(response) {
         setOverheadsForProject(response.data);
       });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject]);

  useEffect(() => {

    if (open === true) {

      setProducts(structuredClone(productsAtom));
      setHeadcounts(structuredClone(headcountsAtom));

      loadProjects();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsAtom, headcountsAtom, open]);

  const validationSchema = Yup.object().shape({
    overheadName: Yup.string()
      .min(3, "Must be at least 3 characters")
      .max(150, "Must be less than 150 characters")
      .required("Required")
      .notOneOf(overheadAtom.map(function(i) {
        return i?.overheadDto?.name;
      }), "Overhead must have a unique name")
  });

  const initialValues = {
    overheadName: "",
    assumptionFormat: "",
    grossProfitLineFlag: "ABOVE",
  };

  const handleChangeProduct = (event) => {

    const productCh = products
      .filter(product => product.productDto.name === event.target.name)[0];

    productCh.productDto.checked = event.target.checked;

    //Clever way of getting the page to refresh
    setTriggerRefresh({
      ...triggerRefresh
    })

  };

  const handleChangeHeadcounts = (event) => {

    //find the department
    const extractedDtos = headcounts.reduce((acc, headcount) => acc.concat(headcount.headCountCategoryDtos), [])
      .filter(category => category.name === 'Quantity')
      .filter(category => category.departmentName === event.target.name)
      .find(category => category.id === Number(event.target.id));

    extractedDtos.checked = event.target.checked;

    //Clever way of getting the page to refresh
    setTriggerRefresh({
      ...triggerRefresh
    })

  };

  function getCheckedDtoIds(headcounts) {
    let ids = headcounts.reduce((acc, headcount) => {
      const checkedIds = headcount.headCountCategoryDtos
        .filter(dto => dto.checked)
        .map(dto => dto.id);
      return acc.concat(checkedIds);
    }, []);

    return ids;
  }

  const handleSubmit = async (
    values,
    { setErrors, setSubmitting, resetForm }
  ) => {
    try {
      setSubmittingForm(true)
      axios
        .post("overheads", {
          name: values.overheadName,
          project: project.id,
          assumption: assumptionFormatValue,
          percentageComplete: 0,
          overrideComplete: false,
          profitLine: grossProfitLineFlagValue,
          markedComplete: false,
          canDelete: true,
          products: products.filter(item => item.productDto.checked === true).map(item => item.productDto["id"]),
          overheads: selectedOverheads,
          linkedProjectId: selectedProject.id,
          headcounts: getCheckedDtoIds(headcounts)
        })
        .then(async function(response) {

          //update the atoms - overheads & pnl & current liabilities (it has a list of overheads)
          await axios.get("overheads/" + project.id).then(function(response) {
            let overheads_ = response.data;
            setOverheadAtom(structuredClone(response.data));

            //what about the vatPackage, shouldn't we recalculate that, since we won't have this new overhead in there?
            let vatPackage = refreshVatPackage(structuredClone(productsAtom), overheads_, structuredClone(vatRateAtom), currentAssetAtom);
            setVatPackageAtom(vatPackage); //basically this just triggers a refresh
          });

          await axios.get("pnl/" + project.id).then(function(response) {
            updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(null, response.data);
          });

          await axios.get("currentliabilities/" + project.id).then(function(response) {
            setCurrentLiabiltiesAtom(response.data);
          });

          setOpen(false);
          setSubmittingForm(false);
          resetForm();
          setSelectedOverheads([]);
          setSelectedProject({ id : 0 });
          setOverheadsForProject([]);

        })
        .catch(function (error) {
          console.log(error);
          setSubmittingForm(false);
        });


    } catch (error) {
      console.log(error)
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const handleChangeAssumption = (event) => {
    setAssumptionFormatValue(event.target.value);
  };

  const handleChangeProfitLine = (event) => {
    setGrossProfitLineFlagValue(event.target.value);
  };

  function findParentOverhead(row) {
    const found = overheads.find(overhead =>   overhead.overheadDto.overheads.includes(row.overheadDto.id));
    return found ? found.overheadDto.name : '';
  }

  const handleSelectModel = (model) => {

    const currentIndex = selectedOverheads.indexOf(model.overheadDto.id);
    const newChecked = [...selectedOverheads];

    if (currentIndex === -1) {
      newChecked.push(model.overheadDto.id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedOverheads(newChecked);
  };

  return (
    <React.Fragment>
      <Grid container>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Grid item xs={12}>
              <Button
                mr={2}
                variant="contained"
                color="primary"
                sx={{ marginBottom: 2 , minWidth : 160}}
                onClick={() => setOpen(true)}
              >
                Overhead
                <Add />
              </Button>

              <Dialog
                open={open}
                onClose={(event, reason) => {
                  if (reason !== 'backdropClick') {
                    setOpen(false)
                  }
                }}
                aria-labelledby="form-dialog-title"
                fullWidth={["OVERHEAD_DIVISIONAL_CONSOLIDATION"].includes(assumptionFormatValue)}
                maxWidth={"md"}
              >
                <form onSubmit={handleSubmit}>
                  <DialogTitle id="form-dialog-title">Add Overhead</DialogTitle>

                  {submittingForm &&  (

                    <DialogContent>

                      <DialogContentText>
                        Adding Product...
                        <LinearProgress my={2} />
                      </DialogContentText>

                    </DialogContent>
                  )}

                  {!submittingForm &&  (
                  <DialogContent sx={{ paddingBottom: 0 }}>
                    <Grid container spacing={3}>
                      <Grid item xs>

                        <DialogContentText>
                          Enter the name of the overhead you wish to add.
                        </DialogContentText>
                        <TextField
                          error={Boolean(touched.overheadName && errors.overheadName)}
                          name="overheadName"
                          autoFocus
                          placeholder={"Overhead Name"}
                          margin="dense"
                          id="overheadName"
                          label="Overhead Name"
                          value={values.overheadName}
                          onChange={handleChange}
                          type="text"
                          helperText={touched.overheadName && errors.overheadName}
                          fullWidth
                        />

                        <FormLabel id="demo-row-radio-buttons-group-label">
                          Assumption Format
                        </FormLabel>
                        <RadioGroup

                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="assumptionFormat"
                          value={assumptionFormatValue}
                          onChange={handleChangeAssumption}
                        >
                          <FormControlLabel
                            value="PERCENTAGE_OF_REVENUE"
                            control={<Radio />}
                            label="Percentage of Revenue"
                          />
                          <FormControlLabel
                            value="ANNUAL_GROWTH_RATE"
                            control={<Radio />}
                            label="Annual Growth Rate"
                          />
                          <FormControlLabel
                            value="MONTHLY_GROWTH_RATE"
                            control={<Radio />}
                            label="Monthly Growth Rate"
                          />
                          <FormControlLabel
                            value="DIRECT_INPUT"
                            control={<Radio />}
                            label="Direct Input"
                          />
                          <FormControlLabel
                            value="PRICE_X_QUANTITY_BASIC"
                            control={<Radio />}
                            label="Price x Quantity (basic)"
                          />
                          <FormControlLabel
                            value="PRICE_X_QUANTITY_VOLUME_DRIVEN"
                            control={<Radio />}
                            label="Price x Quantity (volume driven)"
                          />
                          <FormControlLabel
                            value="PRICE_X_QUANTITY_DEPARTMENT_DRIVEN"
                            control={<Radio />}
                            label="Price x Quantity (department driven)"
                          />
                          <FormControlLabel
                            value="PRICE_X_QUANTITY_PERCENTAGE_PRODUCT"
                            control={<Radio />}
                            label="Percentage of Specific Revenue"
                          />
                          <FormControlLabel
                            value="OVERHEAD_DIVISIONAL_CONSOLIDATION"
                            disabled={getProjectIdNotAsync().projectType !== "PNL_DIVISIONAL"}
                            control={<Radio />}
                            label="Overhead Consolidation"
                          />
                        </RadioGroup>

                        <FormLabel id="demo-row-radio-buttons-group-label">
                          P & L location:
                        </FormLabel>
                        <RadioGroup

                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="grossProfitLineFlag"
                          value={grossProfitLineFlagValue}
                          onChange={handleChangeProfitLine}
                        >
                          <FormControlLabel
                            value={"ABOVE"}
                            control={<Radio />}
                            label="Variable costs (Above Gross Profit)"
                          />
                          <FormControlLabel
                            value={"BELOW"}
                            control={<Radio />}
                            label="Overhead (Below Gross Profit, but Above EBITDA)"
                          />
                          <FormControlLabel
                            value={"EBITDA"}
                            control={<Radio />}
                            label="Below EBITDA"
                          />
                        </RadioGroup>
                      </Grid>


                      {assumptionFormatValue === "OVERHEAD_DIVISIONAL_CONSOLIDATION" &&
                        <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormLabel component="legend">Select a linked project to create your overhead consolidation group</FormLabel>
                          <FormGroup>

                            <Box sx={{minWidth: '50%'}}>
                              <br/>
                              <Typography variant="h6">Projects</Typography>
                              <Box sx={{ minHeight: '90%', maxHeight: '90%', overflow: 'auto', border: '1px solid #ccc', padding: '8px' }}>
                                <List>

                                  {projects.filter(projectToPick => getProjectIdNotAsync().id !== projectToPick.id && getProjectIdNotAsync()?.projectMultiProjectList.filter(multiProject => multiProject.project === projectToPick.id).length > 0).map((row) => (
                                    <ListItem
                                      key={row.id}
                                      button
                                      selected={selectedProject.id === row.id}
                                      onClick={() => setSelectedProject(row)}
                                    >
                                      <ListItemText primary={row.name} />
                                    </ListItem>
                                  ))}
                                </List>
                              </Box>
                            </Box>
                          </FormGroup>

                        </FormControl>
                      </Grid>
                      }

                      {assumptionFormatValue === "OVERHEAD_DIVISIONAL_CONSOLIDATION" &&
                        <Grid item xs>
                          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormLabel component="legend">Select overheads you want to include in this new consolidation, note that some may already be in an existing group.</FormLabel>
                            <FormGroup>

                              <Box sx={{minWidth: '50%'}}>
                                <Typography variant="h6">Overheads</Typography>
                                <Box sx={{ minHeight: '90%', maxHeight: '90%', overflow: 'auto', border: '1px solid #ccc', padding: '8px' }}>
                                  <List>

                                    {overheadsForProject.map((row) => (
                                      <ListItem
                                        key={row.overheadDto.id}
                                        button
                                        selected={selectedProject.id === row.id}
                                        secondaryAction={
                                          <Checkbox
                                            edge="end"
                                            onChange={() => handleSelectModel(row)}
                                            checked={selectedOverheads.indexOf(row.overheadDto.id) !== -1}
                                          />
                                        }
                                      >

                                        <ListItemText primary={row.overheadDto.name} secondary={`Current Group: ${(findParentOverhead(row))}`} />
                                      </ListItem>
                                    ))}
                                  </List>
                                </Box>
                              </Box>

                            </FormGroup>

                          </FormControl>
                        </Grid>
                      }

                      {assumptionFormatValue === "PRICE_X_QUANTITY_VOLUME_DRIVEN" && <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormLabel component="legend">Products To include in percentage of revenue
                            calculation</FormLabel>
                          <FormGroup>

                            {products &&
                              products.filter(product => ["PRICE_X_QUANTITY",
                                "PRICE_X_QUANTITY_COST_INFLATION",
                                "SUBSCRIPTION_MODEL",
                                "HEADS_TO_REVENUE",
                                "HEADCOUNT_UTILISATION",
                                "NON_HEADCOUNT_UTILISATION",
                                "PRICE_X_QUANTITY_BUILDER",
                                "SALES_PER_DAY_REVENUE",
                                "PERCENTAGE_OF_ANOTHER_PRODUCT"].includes(product.productDto.assumption)).map((product) => (
                                <FormControlLabel key={Math.random() * 10000000}
                                                  control={
                                                    <Checkbox checked={product.productDto.checked} onChange={handleChangeProduct}
                                                              name={product.productDto.name} id={product.productDto.id + ""} />
                                                  }
                                                  label={product.productDto.name}
                                />

                              ))}

                          </FormGroup>

                        </FormControl>
                      </Grid>
                      }

                      {assumptionFormatValue === "PRICE_X_QUANTITY_PERCENTAGE_PRODUCT" && <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormLabel component="legend">Products To include in Percentage of Specific Revenue
                            calculation</FormLabel>
                          <FormGroup>

                            {products &&
                              products.map((product) => (
                                <FormControlLabel key={Math.random() * 10000000}
                                                  control={
                                                    <Checkbox checked={product.productDto.checked} onChange={handleChangeProduct}
                                                              name={product.productDto.name} id={product.productDto.id + ""} />
                                                  }
                                                  label={product.productDto.name}
                                />

                              ))}

                          </FormGroup>

                        </FormControl>
                      </Grid>
                      }

                      {assumptionFormatValue === "PRICE_X_QUANTITY_DEPARTMENT_DRIVEN" && <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormLabel component="legend">Headcount To include in Price x Quantity (department driven)
                            calculation</FormLabel>
                          <FormGroup>

                            {headcounts &&
                              headcounts.filter(headcount => headcount.headCountDto.assumption === 'PRICE_X_QUANTITY').map((headcount) => (

                                headcount.headCountCategoryDtos.filter(category => category.name === 'Quantity' && category.departmentName !== '').map((category) => (
                                    <FormControlLabel key={Math.random() * 10000000}
                                                      control={
                                                        <Checkbox checked={category.checked} onChange={handleChangeHeadcounts}
                                                                  name={category.departmentName} id={category.id + ""} />
                                                      }
                                                      label={category.departmentName}
                                    />
                                ))


                              ))}

                          </FormGroup>

                        </FormControl>
                      </Grid>
                      }


                    </Grid>

                  </DialogContent>
                  )}
                  <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary" disabled={submittingForm}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      autoFocus
                      disabled={submittingForm}
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
            </Grid>
          )}
        </Formik>
        <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>
      </Grid>
    </React.Fragment>
  );
}

export default AddOverheads;
