import React, { useState, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import {
  Fab as MuiFab,
} from "@mui/material";

import CloudCircleIcon from '@mui/icons-material/CloudCircle';
import InfoIcon from '@mui/icons-material/Info';
import getProjectId from "../pages/powdr/SharedComponents/ProjectServices";
import axios from "axios";

import { useRecoilState, useRecoilValue } from "recoil";
import { pnlState } from "../pages/powdr/SharedState/PnLState";

import { calculatePnl } from "../pages/powdr/PLGroup/PL/calculations/Calculations";
import { calculateBalanceSheet } from "../pages/powdr/BalanceSheetGroup/BalanceSheet/calculations/Calculations";

import LoadingModal from "../pages/powdr/SharedComponents/LoadingModal";

import UpdatePowdrModelGlobal from "../pages/powdr/SharedComponents/UpdatePowdr/UpdatePowdrModelGlobal";
import { balanceSheetState } from "../pages/powdr/SharedState/BalanceSheetState";
import { drawerContentState, drawerState, pastingState } from "../pages/powdr/SharedState/DrawerState";
import useAuth from "../hooks/useAuth";

const Fab = styled(MuiFab)`
  position: fixed;
  right: ${(props) => props.theme.spacing(24)};
  bottom: ${(props) => props.theme.spacing(4)};
  z-index: 1;
`;


const InfoFab = styled(MuiFab)`
  position: fixed;
  right: ${(props) => props.theme.spacing(44)};
  bottom: ${(props) => props.theme.spacing(4)};
  z-index: 1;
`;

// export async function balanceRecalculation() {
//
//   let project = await getProjectId();
//
//   let pnl = await axios.get(`pnl/${project.id}`);
//
//   let balance = await axios.get(`balance/${project.id}`);
//
//   calculateBalanceSheet(balance.data[0], pnl.data);
//
//   await axios.put(`balance/${project.id}`, balance.data);
//
//   console.log("updated balance");
//
//   await axios.get(`cashflow/${project.id}`);
//
//   console.log("updated cashflow");
//
// }

/**
 * This method does the same of balanceRecalculation except it will also recalc the pnl
 * @returns {Promise<void>}
 */
export async function balanceCashflowPnlRecalculation(setPnlAtom) {

  let project = await getProjectId();

  let pnl = await axios.get(`pnl/${project.id}`);

  calculatePnl(pnl.data)

  setPnlAtom(pnl.data);

  await axios.put("pnl/" + pnl.data.pnLDto.project, pnl.data);

  let balance = await axios.get(`balance/${project.id}`);

  calculateBalanceSheet(balance.data[0], pnl.data);

  await axios.put(`balance/${project.id}`, balance.data);

  console.log("updated balance");

  await axios.get(`cashflow/${project.id}`);

  console.log("updated cashflow");

}


function Settings(props) {

  const { user } = useAuth();

  const updatePowdrModelGlobalRef = useRef();

  const pnlAtom = useRecoilValue(pnlState);
  const balanceAtom = useRecoilValue(balanceSheetState);
  const [updating, setUpdating] = useState(false);
  const [drawerAtom, setDrawerAtom] = useRecoilState(drawerState);
  const pastingAtom = useRecoilValue(pastingState);
  const drawerContentAtom = useRecoilValue(drawerContentState);

  const [message, setMessage] = useState("");

  async function recalculate(methodToInvoke) {
    try {

      setUpdating(true);

      const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

      const start = new Date().getTime();

      if (methodToInvoke) {
        // if methodToInvoke is not null or undefined
        await methodToInvoke();

        window.alert('Recalculate finished')
      }

      const end = new Date().getTime();

      const elapsedTime = end - start;

      if (elapsedTime < 1000) {
        await delay(1000 - elapsedTime);
      }

      setUpdating(false);
    } catch (e) {
      setUpdating(false);
    }
  }

  const toggleDrawer = (open) => () => {
    //setState({ ...state, isOpen: open });
  };

  function updatePowdrModelBalance() {
    updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(balanceAtom, pnlAtom);
  }


  //used for displaying the Notification drawer when dealing with long-running server side actions.
  useEffect(() => {

    setMessage(drawerContentAtom)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerContentAtom]);

  //used for displaying the Notification drawer when dealing with long-running server side actions.
  useEffect(() => {

    console.log('drawer atom changed!', drawerAtom)

    let intervalId;

    if (drawerAtom === true) {
      console.log('drawer atom is true!!')
      const fetchData = () => {
        axios.get('notification/' + user.id)
          .then(function(response) {
            // If the response is 200, update the drawer content
            console.log(response.data.message)

            setMessage(structuredClone(response.data.message))


          })
          .catch(function(error) {
            if (error.response && error.response.status === 404) {
              console.log('CATCHING ERROR', error.response )
              // If a 404 is returned, stop polling and close the drawer
              setDrawerAtom(false);

              setMessage("");
              clearInterval(intervalId);
            } else {
              // Handle other potential errors here, e.g., log them
              setDrawerAtom(false);
              setMessage("");
              console.error("An error occurred while fetching notifications:", error);
            }
          });
      };

      // Fetch data immediately
      fetchData();

      // Start polling every 5 seconds
      intervalId = setInterval(fetchData, 5000);
    }

    // Cleanup function to clear the interval when drawerAtom changes or component unmounts
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerAtom]);

  return (
    <React.Fragment>
      <LoadingModal open={updating} title={'Recalculating Powdr Model'} description={'Assumptions are recalculating, please wait...'} />

      <Fab color="primary" aria-label="Edit" onClick={toggleDrawer(true)} style={{ zIndex: 1300 }}>
        <CloudCircleIcon fontSize={"large"} onClick={() => recalculate(updatePowdrModelBalance)} />
      </Fab>

      {/* Flex container to position InfoFab components side by side */}
      <div style={{ display: 'flex', gap: '16px', zIndex: 1300 }}>
        {drawerAtom && (
          <InfoFab variant="extended" color="primary" aria-label="Edit">
            <span>{message} </span>
            <InfoIcon fontSize={"large"} />
          </InfoFab>
        )}

        {pastingAtom && (
          <InfoFab variant="extended" color="primary" aria-label="Edit">
            <span>Pasting Content .... </span>
            <InfoIcon fontSize={"large"} />
          </InfoFab>
        )}
      </div>

      <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef} />
    </React.Fragment>
  );

}

export default Settings;
