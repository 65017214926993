import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, TextField } from "@mui/material";
import axios from "axios";
import Typography from "@mui/material/Typography";
import useProject from "../../../../../hooks/useProject";
import { useSetRecoilState } from "recoil";
import { drawerContentState, drawerState } from "../../../SharedState/DrawerState";

export default function RestoreSnapshotModal(props) {
  const {open, setOpen, snapshotId, snapshotName} = props;
  const { project } = useProject();
  const [defaultTitle, setDefaultTitle] = useState(true);
  const [snapshotSuccessfullyRestored, setSnapshotSuccessfullyRestored] = useState(false);
  const [inputSnapshotName, setInputSnapshotName] = useState();
  const setDrawerAtom = useSetRecoilState(drawerState);
  const setDrawerContentAtom = useSetRecoilState(drawerContentState);

  useEffect(() => {

    setInputSnapshotName(snapshotName)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  function handleClose() {
    setDefaultTitle(true);
    setSnapshotSuccessfullyRestored(false);
    setOpen(false);
  }

  function restoreSnapshot() {
    setDefaultTitle(false);

    //if consolidated project, then we call it a different endpoint
    if (project.projectType === "PNL_DIVISIONAL") {
      axios.get(`snapshot/restoreConsolidatedProject/${snapshotId}?snapshotName=${encodeURIComponent(inputSnapshotName)}`).then(function(response) {
        setDefaultTitle(false);
        setSnapshotSuccessfullyRestored(true);
        setDrawerContentAtom("Restoring CONSOLIDATED Snapshot")
        setDrawerAtom(true);
      });
    } else {
      axios.get(`snapshot/restore/${snapshotId}?snapshotName=${encodeURIComponent(inputSnapshotName)}`).then(function(response) {
        setDefaultTitle(false);
        setSnapshotSuccessfullyRestored(true);
        setDrawerContentAtom("Restoring Snapshot")
        setDrawerAtom(true);
      });
    }
  }

  const handleSnapshotNameChange = (event) => {
    setInputSnapshotName(event.target.value);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">
          Restore Snapshot
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            { defaultTitle &&
              <Typography>Do you wish to restore this snapshot?</Typography>
            }

            <br/>
            {defaultTitle &&
              <TextField
                name="snapshotName"
                autoFocus
                placeholder={snapshotName}
                id="snapshotName"
                label="Restored Project Name"
                fullWidth
                value={inputSnapshotName}
                onChange={handleSnapshotNameChange}
                type="text"
              />
            }

            { snapshotSuccessfullyRestored &&
              <Typography>Snapshot is being restored, this takes a few minutes,
                please check the home screen. It should appear there</Typography>
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" >
            Close
          </Button>
          {!snapshotSuccessfullyRestored &&
            <Button onClick={restoreSnapshot} color="primary" autoFocus>
              Restore
            </Button>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}
