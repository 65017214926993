import React, { useState, useEffect } from "react";
import PageHeader from "../../SharedComponents/PageHeader";
import Divider from "@mui/material/Divider";
import {
  Container,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  IconButton,
  TableFooter,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress, Typography
} from "@mui/material";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import useAuth from "../../../../hooks/useAuth";
import axios from "axios";
import TrialBalanceTable from "./TrialBalanceTable"; // Adjust the import path as necessary

export default function ConnectionsTable() {
  const { user } = useAuth();
  const [connections, setConnections] = useState([]);

  // State variables for the popup and trial balance data
  const [openPopup, setOpenPopup] = useState(false);
  const [trialBalanceData, setTrialBalanceData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch connections for the company
  useEffect(() => {
    const fetchConnections = async () => {
      try {
        const response = await axios.get(`/oauth-callback/tokens?companyId=${user.company}`);
        const connectionsData = response.data;
        setConnections(connectionsData);
      } catch (error) {
        console.error("Error fetching connections:", error);
      }
    };

    fetchConnections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleForgetConnection = async (id) => {
  //   try {
  //     await axios.delete(`/oauth-callback/tenants/${id}`);
  //     // Refresh the connection list after deletion
  //     const updatedConnections = await axios.get(`/oauth-callback/tokens?companyId=${user.company}`);
  //     setConnections(updatedConnections.data);
  //   } catch (error) {
  //     console.error("Error deleting connection:", error);
  //   }
  // };

  /**
   * Speaks to the xero api and extracts a fresh copy of the Trial balance reports
   * @param id
   * @returns {Promise<void>}
   */
  const handleTrialBalanceRefresh = async (id) => {
    try {
      let refresh = await axios.get(`/xero/trialBalanceRefresh?companyConnectionId=${id}`);
      console.log("Trial Balance Refresh for ID:", id);
      console.log(refresh);
    } catch (error) {
      console.error("Error refreshing trial balance:", error);
    }
  };

  /**
   * Fetches and displays the Trial Balance data in a popup
   * @param id
   * @returns {Promise<void>}
   */
  const handleTrialBalance = async (id) => {
    try {
      setIsLoading(true);
      setOpenPopup(true);

      let response = await axios.get(`/xero/trialBalance?companyConnectionId=${id}`);
      let data = response.data;

      console.log("Trial Balance for ID:", id);
      console.log(data);

      setTrialBalanceData(data.trialBalanceDto); // Adjust based on your API response structure
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching trial balance:", error);
      setIsLoading(false);
    }
  };

  const handleRefreshConnection = async (platform, tenantId) => {
    try {
      await axios.post(`/oauth-callback/tokens/refresh`, {
        companyId: user.company,
        platform,
        tenantId,
      });

      // Refresh the connection list after updating the token
      const updatedConnections = await axios.get(`/oauth-callback/tokens?companyId=${user.company}`);
      setConnections(updatedConnections.data);
    } catch (error) {
      console.error("Error refreshing connection:", error);
    }
  };

  const handleConnect = (platform) => {
    let clientId, baseRedirectUri, scope;

    switch (platform) {
      case "xero":
        clientId = "1AC470B3C33141D19B5D29C460F4923F"; // Replace with your Xero Client ID
        baseRedirectUri = "https://test-api.powdr.co.uk/oauth-callback";
        scope = encodeURIComponent("accounting.reports.read offline_access");
        break;
      case "quickbooks":
        clientId = "QUICKBOOKS_CLIENT_ID"; // Replace with your QuickBooks Client ID
        baseRedirectUri = "https://api.powdr.co.uk/oauth-callback";
        scope = encodeURIComponent("com.intuit.quickbooks.accounting");
        break;
      case "sage":
        clientId = "SAGE_CLIENT_ID"; // Replace with your Sage Client ID
        baseRedirectUri = "https://api.powdr.co.uk/oauth-callback";
        scope = encodeURIComponent("full_access");
        break;
      default:
        return;
    }

    const state = encodeURIComponent(`company_id=${user.company}&platform=${platform}`);
    const authUrl = `https://login.${platform}.com/identity/connect/authorize?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(
      baseRedirectUri
    )}&scope=${scope}&state=${state}`;

    window.location.href = authUrl;
  };

  // Function to toggle row expansion for tenants
  const [openRows, setOpenRows] = useState({}); // State to track which rows are open
  const toggleRow = (id) => {
    setOpenRows((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  // Function to close the popup
  const handleClosePopup = () => {
    setOpenPopup(false);
    setTrialBalanceData(null);
  };

  return (
    <React.Fragment>
      <PageHeader pageName={"Connections (Experimental)"} parentName={"Config"} />
      <Divider my={6} />

      <Container>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="connections table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Platform</TableCell>
                <TableCell>Access Token Expiry</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {connections.map((connection) => (
                <React.Fragment key={connection.id}>
                  <TableRow>
                    <TableCell>
                      <IconButton onClick={() => toggleRow(connection.id)}>
                        {openRows[connection.id] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                      </IconButton>
                    </TableCell>
                    <TableCell>{connection.platform}</TableCell>
                    <TableCell>{new Date(connection.accessTokenExpiresAt).toLocaleString()}</TableCell>
                    <TableCell>
                      {new Date(connection.accessTokenExpiresAt) > new Date() ? "Active" : "Expired"}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleRefreshConnection(connection.platform, connection.tenantId)}
                        sx={{ mx: 1 }}
                      >
                        Refresh Connection
                      </Button>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={5} style={{ paddingBottom: 0, paddingTop: 0 }}>
                      <Collapse in={openRows[connection.id]} timeout="auto" unmountOnExit>
                        <Container>
                          <Table size="small" aria-label="tenants">
                            <TableHead>
                              <TableRow>
                                <TableCell>Company Name</TableCell>
                                <TableCell>Platform</TableCell>
                                <TableCell>Actions</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {connection.companyConnectionDtos?.map((tenant) => (
                                <TableRow key={tenant.id}>
                                  <TableCell>{tenant.companyName}</TableCell>
                                  <TableCell>{tenant.platform}</TableCell>
                                  <TableCell>
                                    {/*<Button*/}
                                    {/*  variant="contained"*/}
                                    {/*  color="secondary"*/}
                                    {/*  onClick={() => handleForgetConnection(tenant.id)}*/}
                                    {/*  sx={{ mx: 1 }}*/}
                                    {/*>*/}
                                    {/*  Forget Connection*/}
                                    {/*</Button>*/}
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      onClick={() => handleTrialBalanceRefresh(tenant.id)}
                                      sx={{ mx: 1 }}
                                    >
                                      Refresh Trial Balance
                                    </Button>
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      onClick={() => handleTrialBalance(tenant.id)}
                                      sx={{ mx: 1 }}
                                    >
                                      Show Trial Balance
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Container>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleConnect("xero")}
                    sx={{ mx: 1 }}
                  >
                    Connect Xero
                  </Button>
                  {/*<Button*/}
                  {/*  variant="contained"*/}
                  {/*  color="primary"*/}
                  {/*  onClick={() => handleConnect("quickbooks")}*/}
                  {/*  sx={{ mx: 1 }}*/}
                  {/*>*/}
                  {/*  Connect QuickBooks*/}
                  {/*</Button>*/}
                  {/*<Button*/}
                  {/*  variant="contained"*/}
                  {/*  color="primary"*/}
                  {/*  onClick={() => handleConnect("sage")}*/}
                  {/*  sx={{ mx: 1 }}*/}
                  {/*>*/}
                  {/*  Connect Sage 200*/}
                  {/*</Button>*/}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Container>

      {/* Popup Dialog for Trial Balance */}
      <Dialog
        open={openPopup}
        onClose={handleClosePopup}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Trial Balance</DialogTitle>
        <DialogContent>
          {isLoading ? (
            <CircularProgress />
          ) : trialBalanceData ? (
            <TrialBalanceTable data={trialBalanceData} />
          ) : (
            <Typography>No data available.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePopup} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
