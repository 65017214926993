import { atom } from 'recoil';
import { handleDataUpdate } from "./RecoilUtils";

export const covenantState = atom({
  key: 'covenantState', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
  effects: [
    ({onSet}) => {
      onSet(async (newAssumptions, oldAssumptions) => {

        const project = JSON.parse(sessionStorage.getItem("project"));


       handleDataUpdate(structuredClone(newAssumptions),
          oldAssumptions,
         'covenant/' + project.id + '/months',
          'covenantMonthDtos',
          'covenantCategoryDtos',
         'covenantDto');

      });
    },
  ]
});
