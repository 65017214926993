import React, { useEffect, useState } from "react";
import axios from "axios";
import PageHeader from "../../SharedComponents/PageHeader";
import styled from "@emotion/styled";
import { Divider as MuiDivider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress } from "@mui/material";
import { spacing } from "@mui/system";
import { green, red } from "@mui/material/colors";

const Divider = styled(MuiDivider)(spacing);

export default function LastLoggedIn() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get('/lastLoggedIn').then((response) => {
      setData(response.data);
      setLoading(false);
    }).catch(error => {
      console.error("There was an error fetching the data!", error);
      setLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <PageHeader pageName={"Last Logged In"} parentName={"Powdr Admin"} />
      <Divider my={6} />
      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>User</TableCell>
                <TableCell>Last Login Time</TableCell>
                <TableCell>Logged In</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.user}</TableCell>
                  <TableCell>{new Date(row.lastLoginTime * 1000).toDateString()}</TableCell>
                  <TableCell>
                    <span style={{ color: row.logged ? green[500] : red[500] }}>
                      ●
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </React.Fragment>
  );
}
