import _ from "lodash";
import { getCurrentProject } from "../../SharedComponents/ProjectServices";


export function actualiseCurrentLiabilities(currentLiabilities) {

  let firstBalanceForecast = getCurrentProject().firstBalanceForecast;

  _.forEach(currentLiabilities, function(currentLiability, i) {

    _.forEach(currentLiability.currentLiabilityCategoryDtos, function(category, x) {

      if (_.isEmpty(category.currentLiabilityMonthDtos)) {
        return;
      }

      category.currentLiabilityMonthDtos[firstBalanceForecast].cellType =
        category.currentLiabilityMonthDtos[firstBalanceForecast - 1].cellType;

      category.currentLiabilityMonthDtos[firstBalanceForecast].financialTypeEnum =
        category.currentLiabilityMonthDtos[firstBalanceForecast - 1].financialTypeEnum;

      //if there is an overriden value present, then we set the value to this and then the valueOverriden to null
      if (category.currentLiabilityMonthDtos[firstBalanceForecast].valueOverriden != null) {
        category.currentLiabilityMonthDtos[firstBalanceForecast].value = category.currentLiabilityMonthDtos[firstBalanceForecast].valueOverriden;
        category.currentLiabilityMonthDtos[firstBalanceForecast].valueOverriden = null;
      }

    });

  });

  return currentLiabilities;
}