import React, { useEffect, useState  } from "react";
import styled from "@emotion/styled";

import {
  CardContent,
  Card as MuiCard,
  TableCell,
  Table,
  TableRow,
  TableBody,
  TableHead,
} from "@mui/material";
import { spacing } from "@mui/system";
import useProject from "../../../../../hooks/useProject";
import PowdrCell from "../../../SharedComponents/PowdrCell/PowdrCell";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import MonthHeader, { generateXaxisHeaders } from "../../../SharedComponents/MonthHeader";
import { calculateSummaryTotals } from "../calculations/CalculateSummary";
import { useRecoilState, useRecoilValue } from "recoil";
import { pnlState } from "../../../SharedState/PnLState";
import { zoomState } from "../../../SharedState/ZoomState";
import axios from "axios";
import { debtsState } from "../../../SharedState/DebtsState";

const Card = styled(MuiCard)(spacing);

const tableWidth = {
  width: "100%",
};

const xAxisStyling = {
  minWidth: 200,
  maxWidth: 200,
  fontWeight: "bolder",
  color: "#2052c2",
  backgroundColor: "#F7F9FC",
};


function DebtSummary() {
  const { key } = useProject();
  const [XaxisHeaders, setXaxisHeaders] = useState([]);
  const [summary, setSummary] = useState([]);
  const [pnlAtom, setPnlAtom] = useRecoilState(pnlState);
  const zoomAtom = useRecoilValue(zoomState);
  const debtsAtom = useRecoilValue(debtsState);

  useEffect(() => {

    let project = getCurrentProject();

    setXaxisHeaders(generateXaxisHeaders(project.startDate, project.firstBalanceForecast));

    axios.get("pnl/" + project.id + "/overdraftCharges").then(function(response) {

      calculateSummaryTotals(debtsAtom, setSummary, project.firstForecast, response.data, setPnlAtom, pnlAtom);

      //todo - what if the P&L overdraft charges changed when not viewing this page, wouldn't the charges need to be recalculated & then the debts calc & then a global recalc (which happens automatically now :D)
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debtsAtom]);

  // Check if the atom is initialized
  if (pnlAtom.length === 0) { // or your logic to check if it's not initialized
    // You can return a loader or null here
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment key={key}>



      {summary &&
        <Card
          mb={6}
          sx={{
            ...tableWidth,
            overflowX: "auto",
            overflow: "scroll",
            paddingTop: 0,
          }}
          id={"Summary-table"}
        >
          <CardContent sx={{ paddingTop: 0, zoom: zoomAtom }}>
            <Table>
              {/* X AXIS - PRODUCT NAME, JAN, FEB, MAR, ETC... */}
              <TableHead>
                <TableRow
                  key={"Summary-table-ROW"}
                  id={"Summary-table-ROW"}
                >
                  <TableCell
                    key={"Summary-table-BASE"}
                    id={"Summary-table-BASE"}
                    style={{ position: "sticky", left: 0 }}
                    sx={{
                      minWidth: 200,
                      maxWidth: 200,
                      width: 200,
                      backgroundColor: "#F7F9FC",
                      zIndex: 10,
                    }}
                  >
                    <h2>Debt Summary</h2>
                  </TableCell>
                  {XaxisHeaders.map((yHeader, index) => (
                    <MonthHeader key={Math.random() * 10000000} yHeader={yHeader} name="Summary-table" index={index}></MonthHeader>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>

                {summary.map((row) => (
                  <TableRow key={row.id} id={row.id} >
                    <TableCell
                      title={""}
                      component="th"
                      scope="row"
                      variant={"body"}
                      sx={{ ...xAxisStyling, backgroundColor: "#F7F9FC" }}
                      style={{
                        position: "sticky",
                        left: 0,
                        backgroundColor: "#F7F9FC",
                        zIndex: 1,
                      }}
                    >
                      {row.name}
                    </TableCell>

                    {row?.productSummaryMonthDtoList?.map((month) => (
                      <PowdrCell
                        key={month.key}
                        cellData={month}
                        productId={999}
                      ></PowdrCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      }


    </React.Fragment>

  );
}

export default DebtSummary;
