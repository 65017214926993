import { getCurrentProject } from "../../SharedComponents/ProjectServices";
import _ from "lodash";

export function actualiseHeadcounts(headcounts) {

  let firstForecast = getCurrentProject().firstForecast;

  //actualise each headcount
  _.forEach(headcounts, function(headcount, i) {

    _.forEach(headcount.headCountCategoryDtos, function(headcountCategory, x) {

      if (_.isEmpty(headcountCategory.headCountMonthDtoList)) {
        return;
      }

      if (["Price", "Growth Rate"].includes(headcountCategory.name) &&
           ["ANNUAL_GROWTH_RATE", "MONTHLY_GROWTH_RATE"].includes(headcount.headCountDto.assumption)) {

        headcountCategory.headCountMonthDtoList[firstForecast].cellType = "ENABLED";
        headcountCategory.headCountMonthDtoList[firstForecast].financialTypeEnum = "ACTUAL";

      } else if (headcountCategory.headCountMonthDtoList[x].cellType === "ENABLED") {

        headcountCategory.headCountMonthDtoList[firstForecast].cellType =
          headcountCategory.headCountMonthDtoList[firstForecast - 1].cellType;

        headcountCategory.headCountMonthDtoList[firstForecast].financialTypeEnum =
          headcountCategory.headCountMonthDtoList[firstForecast - 1].financialTypeEnum;

      }

      //if there is an overriden value present, then we set the value to this and then the valueOverriden to null
      if (headcountCategory.headCountMonthDtoList[firstForecast].valueOverriden != null) {
        headcountCategory.headCountMonthDtoList[firstForecast].value = headcountCategory.headCountMonthDtoList[firstForecast].valueOverriden;
        headcountCategory.headCountMonthDtoList[firstForecast].valueOverriden = null;
      }

    });

  });

  return headcounts;
}