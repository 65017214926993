import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

import {
  Divider as MuiDivider
} from "@mui/material";
import { spacing } from "@mui/system";


import Settings  from "../../../../components/Settings";
import AddCurrentLiability from "./components/AddCurrentLiability";
import {
  calculateCurrentLiabilities,
  calculatePercentageComplete,
  calculateVatCorkscrewAndTriggerBalanceUpdate
} from "./calculations/Calculations";
import CurrentLiabilityInput from "./components/CurrentLiabilityInput";
import PageHeader from "../../SharedComponents/PageHeader";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentLiabilitiesState } from "../../SharedState/CurrentLiabilitiesState";
import { vatPackageState } from "../../SharedState/VatPackageState";
import { corporationTaxState } from "../../SharedState/CorporationTaxState";
import ControlsBar from "../../SharedComponents/ControlsBar";
import UpdatePowdrModel from "../../SharedComponents/UpdatePowdr/UpdatePowdrModel";
import AssumptionNotification from "../../SharedComponents/AssumptionNotificaiton";
import { processAlert } from "../../SharedComponents/utils/NotificationUtils";
import { hasCellValueChanged } from "../../SharedComponents/utils/Utils";
import { currentLiabilitiesAssumptionsMap } from "../../SharedComponents/AssumptionMaps";
import { pnlState } from "../../SharedState/PnLState";
import { overheadsState } from "../../SharedState/OverheadsState";
import { revenueAndCostState } from "../../SharedState/RevenueAndCostState";
import { cashFlowState } from "../../SharedState/CashflowState";
import { headcountsState } from "../../SharedState/HeadcountsState";
import OverrideValueModal from "../../SharedComponents/OverrideValueModal";
import { releaseProfileState } from "../../SharedState/ReleaseProfileState";

const Divider = styled(MuiDivider)(spacing);

function CurrentLiabilities() {

  const updatePowdrRef = useRef();

  const [openAlert, setOpenAlert] = useState(false);
  const [currentLiabilities, setCurrentLiabilities] = useState([]);
  const [editTableEnabled, setEditTableEnabled] = useState("");

  const [currentLiabilitiesAtom, setCurrentLiabilitiesAtom] = useRecoilState(currentLiabilitiesState);

  const pnlAtom = useRecoilValue(pnlState);
  const vatPackageAtom = useRecoilValue(vatPackageState);
  const corporationTaxAtom = useRecoilValue(corporationTaxState);
  const overheadsAtom = useRecoilValue(overheadsState);
  const productsAtom = useRecoilValue(revenueAndCostState);
  const cashflowAtom = useRecoilValue(cashFlowState);
  const headcountsAtom = useRecoilValue(headcountsState);
  const releaseProfileAtom = useRecoilValue(releaseProfileState);

  async function loadCurrentLiabilities() {

    // reset the badge
    processAlert(setOpenAlert, 5);

    let currentLiabilities_ = structuredClone(currentLiabilitiesAtom);

    calculatePercentageComplete(currentLiabilities_, 'currentLiability', 'currentLiabilityCategoryDtos', 'currentLiabilityMonthDtos');

    calculateCurrentLiabilities(corporationTaxAtom, currentLiabilities_, vatPackageAtom, structuredClone(cashflowAtom), pnlAtom.pnLCategoryDtoList, releaseProfileAtom, productsAtom)

    setCurrentLiabilities(currentLiabilities_);

  }

  function triggerUpdate() {
    console.log('triggerUpdate before')

    setCurrentLiabilities([...currentLiabilities])
  }


  function updatePorwdrModel(cellData) {
    updatePowdrRef.current.updatePowdrModel(cellData,
      currentLiabilities,
      "currentLiability",
      "CURRENT_LIABILITIES",
      currentLiabilitiesAssumptionsMap);
  }

  function refreshData(cellData) {

    if (hasCellValueChanged(cellData)) {

      calculatePercentageComplete(currentLiabilities, 'currentLiability', 'currentLiabilityCategoryDtos', 'currentLiabilityMonthDtos');

      calculateCurrentLiabilities(corporationTaxAtom, currentLiabilities, vatPackageAtom, structuredClone(cashflowAtom), pnlAtom.pnLCategoryDtoList, releaseProfileAtom, productsAtom)

      updatePorwdrModel(cellData);

      //todo - we need to clear out the currentliabilites package so that it only contains current liabilities
      let currentLiabilitiesAtomClone = structuredClone(currentLiabilitiesAtom);

      currentLiabilitiesAtomClone = structuredClone(currentLiabilities)

      setCurrentLiabilitiesAtom(currentLiabilitiesAtomClone);
    }

  }

  function calcCurrentLiabilitiesWrapper() {
    calculateVatCorkscrewAndTriggerBalanceUpdate(currentLiabilities, vatPackageAtom)
    setCurrentLiabilities([...currentLiabilities])
  }

  useEffect(() => {
    loadCurrentLiabilities();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLiabilitiesAtom]);


  return (
    <React.Fragment>

      <PageHeader pageName={"Current Liabilities"} parentName={"Assumptions"}/>

      <AssumptionNotification assumptionName={"current liabilities"} openAlert={openAlert} setOpenAlert={setOpenAlert}></AssumptionNotification>

      <Divider my={6} />

      <ControlsBar addAssumptionElement={
        <AddCurrentLiability/>
      }/>


      {!!currentLiabilities &&
        currentLiabilities.map((currentLiability) => (
          <CurrentLiabilityInput
            key={currentLiability.currentLiabilityDto.id}
            currentLiabilityItem={currentLiability}
            currentLiabilities={currentLiabilities}
            refreshData={refreshData}
            setEditData={setEditTableEnabled}
            editData={editTableEnabled}
            setCurrentLiabilities={setCurrentLiabilities}
            loadData={loadCurrentLiabilities}
            products={structuredClone(productsAtom)}
            overheads={structuredClone(overheadsAtom)}
            salaries={structuredClone(headcountsAtom)}
            manualRecalculation={triggerUpdate}
            calcCurrentLiabilitiesWrapper={calcCurrentLiabilitiesWrapper}
          ></CurrentLiabilityInput>
        ))}

      <OverrideValueModal items={structuredClone(currentLiabilitiesAtom)} assumptionType={"CURRENT_LIABILITY"}/>

      <UpdatePowdrModel ref={updatePowdrRef}/>
      <Settings/>


    </React.Fragment>
  );
}

export default CurrentLiabilities;
