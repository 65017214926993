import React, { useState } from "react";
import {
  Button, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel,
  Grid, LinearProgress, MenuItem,
  Select,
  TextField
} from "@mui/material";
import { Field, Formik } from "formik";
import useProject from "../../../../../hooks/useProject";
import axios from "axios";
import * as Yup from "yup";
import { Add } from "@mui/icons-material";
import _ from "lodash";
import * as yup from "yup";
import { getMonthsBetween } from "../../../SharedComponents/utils/Utils";
import Typography from "@mui/material/Typography";
import DivisionalPnL from "./DivisionalPnL";


const CustomizedSelectForFormik = ({ children, form, field }) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  return (
    <Select
      name={name}
      value={value}
      onChange={(e) => {
        setFieldValue(name, e.target.value);
      }}
    >
      {children}
    </Select>
  );
};



function AddProject(props) {
  const [open, setOpen] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(1);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState({ id : 0 });
  const { project } = useProject();
  const { loadData, companies, projects, names, admin, company, disableButton = false } = props;

  const validationSchema = Yup.object().shape({
    projectName: Yup.string()
      .min(3, "Must be at least 3 characters")
      .max(100, "Must be less than 100 characters")
      .required("Required")
      .notOneOf(names, "Must have a unique name"),
    startYear: Yup.string().required("Required"),
    startMonth: Yup.string().required("Required"),
    startForecastYear: Yup.string().when('projectType', {
      is: 'SINGLE_ENTITY',
      then: yup.string().required('Required'),
    }),
    startForecastMonth: Yup.string().when('projectType', {
      is: 'SINGLE_ENTITY',
      then: yup.string().required('Required'),
    }),
    company:  Yup.string().required("Required"),
  });

  function calculateFirstValidForecast(startMonth, startYear, startForecastMonth, startForecastYear) {

    let actualsStartDate = new Date(startYear, startMonth);

    let forecastStartDate = new Date(startForecastYear, startForecastMonth);

    return !isThreeMonthsGreater(actualsStartDate, forecastStartDate);
  }

  function isThreeMonthsGreater(date1, date2) {
    const differenceInMonths = (date2.getFullYear() - date1.getFullYear()) * 12 + (date2.getMonth() - date1.getMonth());
    return differenceInMonths >= 3;
  }

  const initialValues = {
    projectName: "",
    company: company,
    projectType: 'SINGLE_ENTITY',
    startYear: "" + new Date(project?.startDate).getFullYear(),
    startMonth: "" + (new Date(project?.startDate).getMonth() + 1),
    startForecastYear: "",
    startForecastMonth: "",
    projectMultiProjectList: [],
  };

  function doProjectDatesAlign(selectedProjects) {

    //all selected projects should have the same start date
    let validDates = true;

    let startDate;

    if (selectedProjects.length > 1) {
      for (let i = 0; i < selectedProjects.length; i++) {

        let linkedProject = projects.find(project => project.id === selectedProjects[i]);

        if (i === 0) {
          startDate = linkedProject.startDate;
        }

        if (i !== 0 && startDate !== linkedProject.startDate) {
          validDates = false;
        }

      }
    }

    return validDates;
  }

  const handleSubmit = async (
    values,
    { setErrors, setSubmitting, resetForm }
  ) => {

    try {

      const startDate_ = new Date();
      startDate_.setFullYear(values.startYear, values.startMonth, 1);
      startDate_.setDate(1);
      const firstForecastDate_ = new Date();
      firstForecastDate_.setFullYear(values.startForecastYear, values.startForecastMonth, 1);

      let noMonths = getMonthsBetween(startDate_, firstForecastDate_);

      //if project type is MULTI_ENTITY, Then we need to verify that atleast 1 project has been selected
      if (values.projectType === 'MULTI_ENTITY' && values.projectMultiProjectList.length === 0) {
        // show a validation error message
        window.alert('You must select at least 1 project when creating a multi entity project')

        return;
      }

      if (values.projectType === 'PNL_DIVISIONAL' && selectedProjects.length === 0) {
        // show a validation error message
        window.alert('You must select at least 1 project when creating a Divisional P&L Consolidation')

        return;
      }

      if (values.projectType === 'PNL_DIVISIONAL' && !doProjectDatesAlign(selectedProjects)) {
        // show a validation error message
        console.log("showing confirm!")
        window.confirm('Warning, the start dates for the selected models do not match, do you still want to proceed')

        return;
      }


      if (values.projectType === undefined || values.projectName === undefined) {
        // show a validation error message
        window.alert('You must select a company and a project type')

        return;
      }


      setSubmittingForm(true)


      var data = {
        name: values.projectName,
        owner: values.company,
        projectType: values.projectType,
        projectMultiProjectList : selectedProjects.map(integer => {
                                                                    return {
                                                                      id: null,
                                                                      parentProject: null,
                                                                      project: integer
                                                                    }}),
        disabled: false,
        deleted: false,
        firstForecast: noMonths,
        firstBalanceForecast: noMonths,
        startDate: startDate_,
        hideDefaultGroups: true,
      };

      if (values.projectType === 'PNL_DIVISIONAL') {
        data['folder'] = selectedFolder;
      }

      axios
        .post("project", data)
        .then(function (response) {
          loadData();
          setSubmittingForm(false);
          resetForm();
          setSubmitted(true);
        })
        .catch(function (error) {
          console.log(error);
          setSubmittingForm(false);
        });


    } catch (error) {
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };



  return (
    <React.Fragment>
      <Grid container>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}

          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Grid item xs={12}>
              <Button
                disabled={disableButton}
                mr={2}
                variant="contained"
                color="primary"
                sx={{ marginBottom: 2, minWidth : 160 }}
                onClick={() => setOpen(true)}
              >
                Project
                <Add />
              </Button>

              <Dialog
                open={open}
                onClose={() => {setSubmitted(false); setOpen(false);}}
                aria-labelledby="form-dialog-title"
                maxWidth={"md"}
              >
                <form onSubmit={handleSubmit}>

                  {!submitted &&  (
                  <DialogTitle id="form-dialog-title">Add Project</DialogTitle>
                  )}
                  {submitted &&  (

                    <DialogTitle id="form-dialog-title">Project Being Initialised...</DialogTitle>
                  )}

                  {submittingForm &&  (

                    <DialogContent>
                      <DialogContentText>
                        Adding Project...
                        <LinearProgress my={2} />
                      </DialogContentText>
                    </DialogContent>
                  )}

                  {submitted &&  (

                    <DialogContent>
                      <DialogContentText>

                        <br/>Your project has been created! Projects take approximately <strong>5 minutes</strong> to become ready for inputting data.
                        <br/>
                        <br/>
                        <Typography variant="h6">
                          Things To Know:
                        </Typography>
                        <br/>
                        Your new project will include several preconfigured assumptions.
                        <br/>
                        <br/>
                        A Powdr representative will talk you through these assumptions as part of your Introductory meeting.
                        <br/>
                      </DialogContentText>
                    </DialogContent>
                  )}

                  {!submittingForm && !submitted &&  (
                  <DialogContent sx={{ paddingBottom: 0, minWidth: 400 }}>
                    <DialogContentText>
                      Enter the name of the Project you wish to add.
                    </DialogContentText>

                      <Grid item >
                        <TextField
                          error={Boolean(touched.projectName && errors.projectName)}
                          margin="dense"
                          name="projectName"
                          label="Project Name"
                          type="text"
                          value={values.projectName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>



                    {/* COMPANY */}
                    {admin &&

                        <Grid item sx={{ minWidth: 265 }}>
                          <FormControl sx={{ minWidth: 265 }}>
                            <DialogContentText>Company</DialogContentText>
                            <Field
                              name="company"
                              component={CustomizedSelectForFormik}
                              value={values.company}
                              fullWidth
                            >
                              {!!companies &&
                                companies.map((company) => (
                                  <MenuItem onClick={() => {
                                    values.projectMultiProjectList.length = 0;
                                    setSelectedCompany(company.id)
                                    setTriggerRefresh({ ...triggerRefresh })
                                  }}
                                            key={company.id} value={company.id}>{company.companyName}</MenuItem>
                                ))}
                            </Field>
                          </FormControl>
                        </Grid>

                    }


                    {/* PROJECT TYPE */}
                    {admin &&
                      <Grid container>
                        <Grid item sx={{ minWidth: 265 }}>
                          <FormControl sx={{ mt: 2, minWidth: 1 }}>
                            <DialogContentText>Project Type:</DialogContentText>
                            <Field
                              name="projectType"
                              component={CustomizedSelectForFormik}
                              value={values.projectType}
                              fullWidth
                            >
                              <MenuItem onClick={() => {
                                values.projectMultiProjectList.length = 0;
                                setTriggerRefresh({ ...triggerRefresh })
                              }}
                                        key={'SINGLE_ENTITY'} value="SINGLE_ENTITY">Standard</MenuItem>
                              <MenuItem key={'PNL'} value="PNL">P&L Only</MenuItem>
                              <MenuItem key={'PNL_DIVISIONAL'} value="PNL_DIVISIONAL">Divisional P&L Consolidation</MenuItem>
                              {/*<MenuItem key={'MULTI_ENTITY'} value="MULTI_ENTITY">Multi Entity</MenuItem>*/}
                            </Field>
                          </FormControl>
                        </Grid>
                      </Grid>
                    }

                    <Grid container spacing={6} display={values.projectType === 'PNL_DIVISIONAL' ? "flex" : "none"}>
                      <Grid item md={6}>
                        <DivisionalPnL companyId={selectedCompany}
                                       selectedProjects={selectedProjects}
                                       setSelectedProjects={setSelectedProjects}
                                       selectedFolder={selectedFolder}
                                       setSelectedFolder={setSelectedFolder}></DivisionalPnL>
                      </Grid>
                    </Grid>

                    {/* PROJECTS TO INCLUDE - FOR MULTI_ENTITY ONLY */}
                    <Grid item xs display={values.projectType === 'MULTI_ENTITY' ? "flex" : "none"}>
                      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                        <FormLabel component="legend">Projects To include:</FormLabel>
                        <FormGroup>

                          {projects &&
                            projects.filter(project => project.owner === values.company && project.disabled === false && project.projectType !== 'MULTI_ENTITY').map((project) => (
                              <FormControlLabel key={Math.random() * 10000000}
                                                control={
                                                  <Checkbox checked={values.projectMultiProjectList.filter(projectItem => projectItem.project ===project.id).length > 0}
                                                            onChange={() => {

                                                              if (values.projectMultiProjectList.filter(projectItem => projectItem.project ===project.id).length > 0) {

                                                                _.remove(values.projectMultiProjectList, function(n) {
                                                                  return n.project = project.id;
                                                                })
                                                                values.projectMultiProjectList.pop(project.id)
                                                              } else {
                                                                values.projectMultiProjectList.push({project: project.id})
                                                              }
                                                              setTriggerRefresh({...triggerRefresh})
                                                            }}
                                                            name={project.name} id={project.id + ""} />
                                                }
                                                label={project.name}
                              />

                            ))}

                        </FormGroup>

                      </FormControl>
                    </Grid>



                    {/* START DATE - FOR SINGLE_ENTITY ONLY */}
                    <Grid container spacing={6} display={['SINGLE_ENTITY', 'PNL'].includes(values.projectType) ? "flex" : "none"}>
                      <Grid item md={6}>
                        <FormControl sx={{ mt: 2, minWidth: 1 }}>
                          <DialogContentText>Actuals Start Year:</DialogContentText>
                          <Field
                            name="startYear"
                            component={CustomizedSelectForFormik}
                            value={values.startYear}
                            fullWidth
                          >
                            <MenuItem key={2022} value="2022">2022</MenuItem>
                            <MenuItem key={2023} value="2023">2023</MenuItem>
                            <MenuItem key={2024} value="2024">2024</MenuItem>
                          </Field>
                        </FormControl>
                      </Grid>
                      <Grid item md={6}>
                        <FormControl sx={{ mt: 2, minWidth: 1 }}>
                          <DialogContentText>Actuals Start Month:</DialogContentText>
                          <Field
                            name="startMonth"
                            component={CustomizedSelectForFormik}
                            value={values.startMonth}
                            fullWidth
                          >
                            <MenuItem key={0} value=""></MenuItem>
                            <MenuItem key={10} value="0">January</MenuItem>
                            <MenuItem key={20} value="1">February</MenuItem>
                            <MenuItem key={30} value="2">March</MenuItem>
                            <MenuItem key={40} value="3">April</MenuItem>
                            <MenuItem key={50} value="4">May</MenuItem>
                            <MenuItem key={60} value="5">June</MenuItem>
                            <MenuItem key={70} value="6">July</MenuItem>
                            <MenuItem key={80} value="7">August</MenuItem>
                            <MenuItem key={90} value="8">September</MenuItem>
                            <MenuItem key={100} value="9">October</MenuItem>
                            <MenuItem key={110} value="10">November</MenuItem>
                            <MenuItem key={120} value="11">December</MenuItem>
                          </Field>
                        </FormControl>
                      </Grid>
                    </Grid>


                    <Grid container spacing={6} display={['SINGLE_ENTITY', 'PNL'].includes(values.projectType) ? "flex" : "none"}>
                      <Grid item md={6}>
                        <FormControl sx={{ mt: 2, minWidth: 1 }}>
                          <DialogContentText>First Forecast Year:</DialogContentText>
                          <Field
                            name="startForecastYear"
                            component={CustomizedSelectForFormik}
                            value={values.startForecastYear}
                            fullWidth
                          >
                            <MenuItem disabled={values.startYear > 2022} value="2022">2022</MenuItem>
                            <MenuItem disabled={values.startYear > 2023} value="2023">2023</MenuItem>
                            <MenuItem disabled={values.startYear > 2024} value="2024">2024</MenuItem>
                          </Field>
                        </FormControl>
                      </Grid>
                      <Grid item md={6}>
                        <FormControl sx={{ mt: 2, minWidth: 1 }}>
                          <DialogContentText>First Forecast Month:</DialogContentText>
                          <Field
                            name="startForecastMonth"
                            component={CustomizedSelectForFormik}
                            value={values.startForecastMonth}
                            fullWidth
                          >
                            <MenuItem disabled={calculateFirstValidForecast(values.startMonth, values.startYear, 0, values.startForecastYear)} key={1} value="0">January</MenuItem>
                            <MenuItem disabled={calculateFirstValidForecast(values.startMonth, values.startYear, 1, values.startForecastYear)} key={2} value="1">February</MenuItem>
                            <MenuItem disabled={calculateFirstValidForecast(values.startMonth, values.startYear, 2, values.startForecastYear)} key={3} value="2">March</MenuItem>
                            <MenuItem disabled={calculateFirstValidForecast(values.startMonth, values.startYear, 3, values.startForecastYear)} key={4} value="3">April</MenuItem>
                            <MenuItem disabled={calculateFirstValidForecast(values.startMonth, values.startYear, 4, values.startForecastYear)} key={5} value="4">May</MenuItem>
                            <MenuItem disabled={calculateFirstValidForecast(values.startMonth, values.startYear, 5, values.startForecastYear)} key={6} value="5">June</MenuItem>
                            <MenuItem disabled={calculateFirstValidForecast(values.startMonth, values.startYear, 6, values.startForecastYear)} key={7} value="6">July</MenuItem>
                            <MenuItem disabled={calculateFirstValidForecast(values.startMonth, values.startYear, 7, values.startForecastYear)} key={8} value="7">August</MenuItem>
                            <MenuItem disabled={calculateFirstValidForecast(values.startMonth, values.startYear, 8, values.startForecastYear)} key={9} value="8">September</MenuItem>
                            <MenuItem disabled={calculateFirstValidForecast(values.startMonth, values.startYear, 9, values.startForecastYear)} key={10} value="9">October</MenuItem>
                            <MenuItem disabled={calculateFirstValidForecast(values.startMonth, values.startYear, 10, values.startForecastYear)} key={11} value="10">November</MenuItem>
                            <MenuItem disabled={calculateFirstValidForecast(values.startMonth, values.startYear, 11, values.startForecastYear)} key={12} value="11">December</MenuItem>
                          </Field>
                        </FormControl>
                      </Grid>

                      <Grid item md={6}>
                        n.b. When you click submit, it takes <strong>a couple of minutes</strong> for your project to be created.

                      </Grid>
                    </Grid>



                  </DialogContent>
                  )}
                  <DialogActions>


                    <Button onClick={() => {setSubmitted(false); setOpen(false);}} color="primary" disabled={submittingForm}>
                      Close
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      autoFocus
                      sx={{display: submitted ? "none" : "block"}}
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
            </Grid>
          )}
        </Formik>
      </Grid>
    </React.Fragment>
  );
}

export default AddProject;
