import React, { useState } from 'react';
import MUIDataTable from "mui-datatables";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import axios from "axios";
import useProject from "../../../../../hooks/useProject";

import PersonIcon from '@mui/icons-material/Person';
import SmartToyIcon from '@mui/icons-material/SmartToy';


const useStyles = makeStyles({
  jsonDialog: {
    overflow: 'auto',
  },
});

function AuditTable({ data }) {
  const { project } = useProject();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [payloadData, setPayloadData] = useState([]);

  const customColumnLabels = {
    id: "Unique ID",
    month: "Month",
    value: "Value",
    equity: "Equity",
    touched: "User Driven",
    category: "Category",
    cellType: "Cell Type",
    monthName: "Month Name",
    monthIndex: "Month Index",
    parentName: "Parent Name",
    valueFormat: "Value Format",
    categoryName: "Category Name",
    previousValue: "Previous Value",
    financialTypeEnum: "Financial Type",
  };

  const columns = [
    {
      name: "projectName",
      label: "Project",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "timestamp",
      label: "Time",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => format(new Date(value * 1000), 'PPPpp'),
      }
    },
    {
      name: "actionedBy",
      label: "User",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "eventType",
      label: "Event Type",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "userDriven",
      label: "User Driven?",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return value ? <PersonIcon /> : <SmartToyIcon />;
        },
      }
    },
    {
      name: "id",
      label: "Payload",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <Button color="primary" onClick={() => handleOpen(value)}>
            View Payload
          </Button>
        ),
      }
    },
  ];

  const handleOpen = (auditId) => {
    axios.get(`audit/${project.id}/${auditId}`).then(function(response) {
      setPayloadData(JSON.parse(response.data.payloadObject));
      setOpen(true);
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const options = {
    filterType: 'checkbox',
    responsive: 'standard',
  };

  const renderPayloadTable = () => {
    // Guard clauses to handle null, undefined, or empty array scenarios for payloadData
    if (!payloadData || payloadData.length === 0 || typeof payloadData[0] !== 'object' || payloadData[0] === null) {
      return null;
    }

    let columnHeaders = Object.keys(payloadData[0]);
    // Update column headers to use custom names
    columnHeaders = columnHeaders.map(header => customColumnLabels[header] || header);

    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            {columnHeaders.map(header => (
              <TableCell key={header}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {payloadData.map((row, index) => (
            <TableRow key={index}>
              {Object.keys(row).map((key, i) => (
                <TableCell key={`${index}-${i}`}>
                  {row[key]?.toString() || ''}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };



  return (
    <>
      <MUIDataTable
        title={"Audit Log"}
        data={data}
        columns={columns}
        options={options}
      />
      <Dialog open={open} onClose={handleClose} maxWidth="xxl" fullWidth classes={{ paper: classes.jsonDialog }}>
        <DialogTitle>Payload Data</DialogTitle>
        <DialogContent>
          {renderPayloadTable()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AuditTable;
