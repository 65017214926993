import React, { useState }  from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField
} from "@mui/material";
import { spacing } from "@mui/system";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function SignUp() {
  const [saving, setSaving] = useState(false);
  const [lastCompanyName, setLastCompanyName] = useState(null);
  const [lastCompanyEmail, setLastCompanyEmail] = useState(null);
  const navigate = useNavigate();
  //const phoneRegExp = /^((\+44)7[\d]{7,11}|07[\d]{8,12})$/ //UK mobile phone numbers e.g. +447123456789 07123456789



  const handleSubmit = async (
    values,
    { setErrors, setSubmitting, resetForm, isSubmitting }
  ) => {
    setSaving(true);
    setSubmitting(true)
    console.log('on submit!')
    isSubmitting = true

    axios.post('/company', values).then(response => {
      navigate("/auth/sign-up-complete");
    })
      .catch(function (error) {
        window.alert('Powdr signup request denied, please contact Powdr support')
      });
  };

  const getCharacterValidationError = (str) => {
    return `Your password must have at least 1 ${str} character`;
  };

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        companyName: "",
        companySector: "",
        companyWebsite: "",
        approxRevenue: "0 to £250,000",
        password: "",
        confirmPassword: "",
        submit: false,
        license: "STANDARD",
      }}


      validationSchema={Yup.object().shape({
        firstName: Yup.string().max(255).required("First name is required"),
        lastName: Yup.string().max(255).required("Last name is required"),
        companyName: Yup.string()
          .min(3)
          .max(255)
          .required("Company name is required")
          .test('checkCompanyUnique', 'This company is already registered', value => {
            if(value === lastCompanyName) {
              return true; // skip validation if company name hasn't changed
            }

            return axios.post('/company/validateCompany' ,{companyName: value}).then(response => {
              setLastCompanyName(value); // update last company name
              return !response.data;
            });
          }),
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required")
          .test('checkEmailUnique', 'This email is already registered', value => {
            if(value === lastCompanyEmail) {
              return true; // skip validation if company email hasn't changed
            }

            return axios.post('/company/validateEmail' ,{email: value}).then(response => {
              setLastCompanyEmail(value); // update last company email
              return !response.data;
            });
          }),
        phoneNumber: Yup.string()
          .max(20, "Must be less than 20 characters")
          .required("Telephone is required"),
        password: Yup.string()
          .min(8, "Must be at least 8 characters")
          .max(255)
          .matches(/[0-9]/, getCharacterValidationError("digit"))
          .matches(/[a-z]/, getCharacterValidationError("lowercase"))
          .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
          .required("Required"),
        confirmPassword: Yup.string().when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Both password need to be the same"
          ),
        }),
      })}


      onSubmit={handleSubmit}
      // onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
      //   console.log('on submit!')
      //   axios.post('/company', values).then(response => {
      //       navigate("/auth/sign-up-complete");
      //   })
      //     .catch(function (error) {
      //       window.alert('Powdr signup request denied, please contact Powdr support')
      //     });
      // }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="text"
            name="firstName"
            label="First name"
            value={values.firstName}
            error={Boolean(touched.firstName && errors.firstName)}
            fullWidth
            helperText={touched.firstName && errors.firstName}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="text"
            name="lastName"
            label="Last name"
            value={values.lastName}
            error={Boolean(touched.lastName && errors.lastName)}
            fullWidth
            helperText={touched.lastName && errors.lastName}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="email"
            name="email"
            label="Email address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="text"
            name="phoneNumber"
            label="Mobile Phone Number"
            placeholder={""}
            value={values.phoneNumber}
            error={Boolean(touched.phoneNumber && errors.phoneNumber)}
            fullWidth
            helperText={touched.phoneNumber && errors.phoneNumber}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="text"
            name="companyName"
            label="Company Name"
            value={values.companyName}
            error={Boolean(touched.companyName && errors.companyName)}
            fullWidth
            helperText={touched.companyName && errors.companyName}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="text"
            name="companyWebsite"
            label="Company Website"
            value={values.companyWebsite}
            error={Boolean(touched.companyWebsite && errors.companyWebsite)}
            fullWidth
            helperText={touched.companyWebsite && errors.companyWebsite}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="password"
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="password"
            name="confirmPassword"
            label="Confirm password"
            value={values.confirmPassword}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            fullWidth
            helperText={touched.confirmPassword && errors.confirmPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={saving}
          >
            Sign up
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default SignUp;
