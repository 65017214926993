import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";


import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';

import {
  CardContent,
  Card as MuiCard,

  TableCell,
  Table,
  TableRow,
  TableBody,
  TableHead
} from "@mui/material";
import { spacing } from "@mui/system";
import useProject from "../../../../../hooks/useProject";
import PowdrCell from "../../../SharedComponents/PowdrCell/PowdrCell";
import { customBlue } from "../../../../../theme/variants";
import Edit from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

import { Delete } from "@mui/icons-material";

import MonthHeader from "../../../SharedComponents/MonthHeader";
import { getBalanceItemToEdit } from "../calculations/BalanceApiCalls";
import EditFixedAsset from "../../../AssumptionsGroup/FixedAssets/components/EditFixedAsset";
import EditCurrentAsset from "../../../AssumptionsGroup/CurrentAssets/components/EditCurrentAsset";
import EditCurrentLiability from "../../../AssumptionsGroup/CurrentLiabilities/components/EditCurrentLiability";
import EditDebt from "../../../AssumptionsGroup/Debt/components/EditDebt";
import EditEquity from "../../../AssumptionsGroup/Equity/components/EditEquity";
import { CustomColorIconButton } from "../../../SharedComponents/utils/CustomIconButton";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import BalanceNavBar from "./BalanceNavBar";
import { useRecoilValue } from "recoil";
import { debtsState } from "../../../SharedState/DebtsState";
import { Skeleton } from "@mui/material";
import Paper from "@mui/material/Paper";
import { TableContainerPowdr } from "../../../SharedComponents/utils/Utils";
import CommentIcon from "@mui/icons-material/Comment";
import { projectState } from "../../../SharedState/ProjectState";
const Card = styled(MuiCard)(spacing);

const tableWidth = {
  width: "100%",
};

const xAxisStyling = {
  minWidth: 200,
  maxWidth: 200,
  fontWeight: "bolder",
  color: "#2052c2",
  backgroundColor: "#F7F9FC",
};



export function generateXaxisHeadersBalance(startDateStr, lastActual=12) {
  var XaxisHeaders = [];

  var startDate = new Date(startDateStr);

  startDate.setDate(1);

  let currentOverallCount = 0;


  function addHeader(colour, yearLink) {
    for (let i = 0; i < 12; i++) {
      XaxisHeaders.push({
        type: currentOverallCount < lastActual ? 'Actual' : 'Forecast',
        financialYear: startDate.getFullYear(),
        financialMonth: startDate.toLocaleString("default", { month: "short" }),
        backgroundColor: currentOverallCount < lastActual ? "#B1B1B1" : colour,
        id: yearLink,
      });
      currentOverallCount++;
      startDate.setMonth(startDate.getMonth() + 1);
    }
  }

  function addBalance(colour, yearLink) {
    var openingDate = new Date(startDateStr);
    openingDate.setMonth(startDate.getMonth() - 1);
    openingDate.setDate(1);

    XaxisHeaders.push({
      type: "Opening",
      financialYear: openingDate.getFullYear(),
      financialMonth: openingDate.toLocaleString("default", { month: "short" }),
      backgroundColor: colour,
      id: yearLink,
    });

  }

  addBalance("#8b8b8b", "opening");
  addHeader("#B1B1B1", "year0");
  addHeader(customBlue[800], "year1");
  addHeader(customBlue[600], "year2");
  addHeader(customBlue[400], "year3");
  addHeader(customBlue[200], "year4");
  addHeader(customBlue[100], "year5");

  return XaxisHeaders;
}


function BalanceMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openEdit, setOpenEdit] = React.useState({ fixedEdit: false, currentEdit: false, liabilityEdit: false, debtEdit: false, equityEdit: false });
  const [itemToEdit, setItemToEdit] = React.useState(null);
  const [topVal, setTopVal] = React.useState(500);
  const debtsAtom = useRecoilValue(debtsState);

  const { getProject } = useProject();
  const open = Boolean(anchorEl);
  const { name,
    balanceSheetItem,
    move,
    refreshData,
    deleteCategory,
    setLoadingAssumption,
    setOpenComment
  } = props;
  const handleClick = (event) => {
    setTopVal(event.clientY + 15)
    setAnchorEl(event.currentTarget);
  };


  function closedEditModal(close) {
    setOpenEdit({ fixedEdit: false, currentEdit: false, liabilityEdit: false, debtEdit: false, equityEdit: false })
  }

  const handleClose = (event) => {
    setAnchorEl(null);

    if (event.target.outerText === 'Edit') {
      getBalanceItemToEdit(balanceSheetItem, setItemToEdit, setOpenEdit, getProject().id, setLoadingAssumption);
    }

    if (event.target.outerText === 'Move Up') {
      move(balanceSheetItem.id, "UP");
    }

    if (event.target.outerText === 'Move Down') {
      move(balanceSheetItem.id, "DOWN");
    }

    if (event.target.outerText === 'Delete') {
      deleteCategory(balanceSheetItem);
    }

    if (event.target.outerText === 'Comment') {
      setOpenComment(true);
    }


  };

  return (
    <React.Fragment>

        <Tooltip title="" sx={{textAlign: "left"}}>
          <CustomColorIconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            {name} <MoreVertIcon fontSize="small"></MoreVertIcon>

          </CustomColorIconButton>
        </Tooltip>

      <Menu
        anchorReference="anchorPosition"
        anchorPosition={{ top: topVal, left: 475 }}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem value="edit" disabled={['Trade creditors', 'Trade debtors','Inventory','NIC and PAYE','VAT','Corporation Tax'].includes(balanceSheetItem.overrideName)}>
          <ListItemIcon value="edit">
            <Edit fontSize="small" >
              Edit
            </Edit>
          </ListItemIcon>
          Edit
        </MenuItem>
        <MenuItem value="moveup">
          <ListItemIcon value="delete">
            <ArrowDropUp fontSize="small" >
              Move Up
            </ArrowDropUp>
          </ListItemIcon>
          Move Up
        </MenuItem>
        <MenuItem value="movedown">
          <ListItemIcon value="delete">
            <ArrowDropDown fontSize="small" >
              Move Down
            </ArrowDropDown>
          </ListItemIcon>
          Move Down
        </MenuItem>
        <MenuItem value="delete" disabled={balanceSheetItem.canDelete !== undefined && balanceSheetItem.canDelete === false}>
          <ListItemIcon value="delete">
            <Delete fontSize="small" >
              Delete
            </Delete>
          </ListItemIcon>
          Delete
        </MenuItem>
        <MenuItem value="comment">
          <ListItemIcon value="comment">
            <CommentIcon fontSize="small" >
              Comment
            </CommentIcon>
          </ListItemIcon>
          Comment
        </MenuItem>
      </Menu>

      {
        openEdit.fixedEdit && (
        <EditFixedAsset fixedAssetNames={[]}
                        loadData={refreshData}
                        fixedasset={itemToEdit}
                      open={openEdit.fixedEdit}
                      setOpen={closedEditModal}>
        </EditFixedAsset>
      )}

      {
        openEdit.currentEdit && (
          <EditCurrentAsset currentAssetNames={[]}
                          loadData={refreshData}
                            currentasset={itemToEdit}
                          open={openEdit.currentEdit}
                          setOpen={closedEditModal}>
          </EditCurrentAsset>
      )}

      {
        openEdit.liabilityEdit && (
          <EditCurrentLiability currentLiabilityNames={[]}
                            loadData={refreshData}
                                currentliability={itemToEdit}
                            open={openEdit.liabilityEdit}
                            setOpen={closedEditModal}>
          </EditCurrentLiability>
      )}

      {
        openEdit.debtEdit && (
          <EditDebt debtNames={[]}
                                loadData={refreshData}
                    debt={itemToEdit}
                                open={openEdit.debtEdit}
                                setOpen={closedEditModal}
                                debts={structuredClone(debtsAtom)}>
          </EditDebt>
      )}

      {
        openEdit.equityEdit && (
          <EditEquity equityNames={[]}
                    loadData={refreshData}
                      equity={itemToEdit}
                    open={openEdit.equityEdit}
                    setOpen={closedEditModal}>
          </EditEquity>
      )}

    </React.Fragment>
  );
}

function BalanceInput(props) {
  const { project, key } = useProject();
  const [XaxisHeaders, setXaxisHeaders] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const projectAtom = useRecoilValue(projectState);

  useEffect(() => {
    // Defer heavy rendering to allow loading indicator to show
    setTimeout(() => {
      setIsLoading(false);
    }, 0);
  }, []);

  const {
    tableRef,
    balanceSheetItem,
    balanceSheet,
    refreshData,
    setEditData,
    setBalanceSheet,
    loadData,
    editData,
    move,
    deleteCategory,
    setLoadingAssumption
  } = props;

  useEffect(() => {
    setXaxisHeaders(generateXaxisHeadersBalance(getCurrentProject().startDate, getCurrentProject().firstBalanceForecast));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setXaxisHeaders(generateXaxisHeadersBalance(getCurrentProject().startDate, getCurrentProject().firstBalanceForecast));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectAtom]);

  function handleClick(id) {
    setEditData(id);
  }

  function loadDataAndRefreshHeaders() {
    setXaxisHeaders(generateXaxisHeadersBalance(getCurrentProject().startDate, getCurrentProject().firstBalanceForecast));
    loadData();
  }

  function renderHeavyContent() {
    return (
      <div>
        <BalanceNavBar
          item={balanceSheetItem.balanceDto}
          parent={balanceSheetItem}
          refreshDataMethod={loadDataAndRefreshHeaders}
          projectId={project.id}
          shallowUpdateUrl={"balance/shallowUpdate"}
        ></BalanceNavBar>

        <Card
          mb={6}
          sx={{
            ...tableWidth,
            overflowX: "auto",
            overflow: "scroll",
            paddingTop: 0,
          }}
          id={balanceSheetItem.balanceDto.name.replaceAll(" ", "-") + "-table"}
        >
          <CardContent  sx={{ paddingTop: 0 }}>

            <TableContainerPowdr component={Paper} sx={{ overflow: 'auto' }}>

              <Table ref={tableRef}>
                {/* X AXIS - PRODUCT NAME, JAN, FEB, MAR, ETC... */}
                <TableHead >
                  <TableRow
                    key={balanceSheetItem.balanceDto.name + "-ROW"}
                    id={balanceSheetItem.balanceDto.name + "-ROW"}
                  >
                    <TableCell
                      key={balanceSheetItem.balanceDto.name + "-BASE"}
                      id={balanceSheetItem.balanceDto.name + "-BASE"}
                      style={{ position: "sticky", left: 0 }}
                      sx={{
                        minWidth: 200,
                        maxWidth: 200,
                        width: 200,
                        backgroundColor: "#F7F9FC",
                        zIndex: 3, // Ensure the header is above other content
                      }}
                    >

                    </TableCell>
                    {XaxisHeaders.map((yHeader, index) => (
                      <MonthHeader key={Math.random() * 10000000} yHeader={yHeader} name={balanceSheetItem.balanceDto.name} index={index}></MonthHeader>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {balanceSheetItem.balanceCategoryDtos.map((row) => (
                    <TableRow key={row.id}
                              id={row.id}
                              sx={{borderStyle: row.totalField === true && ["TOTAL FIXED ASSETS", "TOTAL CURRENT ASSETS", "TOTAL CURRENT LIABILITIES", "TOTAL DEBT", "Net Assets", "TOTAL EQUITY", "Check Line"].includes(row.overrideName) ? "double" : "none", }}
                              onClick={() => handleClick(row.id)}>
                      <TableCell
                        title={"All assets and equity items should be positive values. \n\nAll liabilities should be negative values."}
                        component="th"
                        scope="row"
                        variant={"body"}
                        sx={{ ...xAxisStyling, backgroundColor: "#F7F9FC" }}
                        style={{
                          position: "sticky",
                          left: 0,
                          backgroundColor: "#F7F9FC",
                          zIndex: 10,

                        }}
                      >
                        {/*{row.overrideName === "" ? row.name : row.overrideName}*/}
                        {row.totalField !== true
                          ? <BalanceMenu name={row.overrideName === "" ? row.name : row.overrideName}
                                         balanceSheetItem={row}
                                         move={move}
                                         refreshData={loadData}
                                         projectId={project.id}
                                         deleteCategory={deleteCategory}
                                         setLoadingAssumption={setLoadingAssumption}
                          ></BalanceMenu>
                          : row.overrideName === "" ? row.name : row.overrideName
                        }
                      </TableCell>

                      {row.balanceMonthDtos.map((month) => (
                        <PowdrCell
                          key={month.id + "-CELL"}
                          cellData={month}
                          ownerId={row.id}
                          editTableEnabled={editData}
                          items={balanceSheet}
                          refreshItemData={refreshData}
                          setItems={setBalanceSheet}
                          assumption={row}
                          categoryName={row.name}
                          parentName={balanceSheetItem.balanceDto.name}
                          category={row}
                          monthIndex={month.monthIndex}
                          monthAttributeName={'balanceMonthDtos'}
                        ></PowdrCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

            </TableContainerPowdr>

          </CardContent>
        </Card>
      </div>
    )
  }
  
  return (
    <React.Fragment key={key}>
      {isLoading ? (
        <Skeleton /> // Loading widget or spinner
      ) : (
        renderHeavyContent() // Rendered heavy content
      )}
    </React.Fragment>
  );
}

export default BalanceInput;
