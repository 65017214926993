import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import {
  CardContent,
  Card as MuiCard,
  TableCell,
  Table,
  TableRow,
  TableBody,
  TableHead,
} from "@mui/material";
import { spacing } from "@mui/system";

import useProject from "../../../../../hooks/useProject";
import InputNavBar from "../../../SharedComponents/InputNavBar";
import MonthHeader, { generateXaxisHeaders } from "../../../SharedComponents/MonthHeader";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import { Skeleton } from "@mui/material";
import Paper from "@mui/material/Paper";
import { TableContainerPowdr } from "../../../SharedComponents/utils/Utils";
import CashflowRow from "./CashflowRow";

const Card = styled(MuiCard)(spacing);

const tableWidth = {
  width: "100%",
};

CashflowRow.propTypes = {};

function CashflowInput(props) {
  const { key } = useProject();
  const [XaxisHeaders, setXaxisHeaders] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Defer heavy rendering to allow loading indicator to show
    setTimeout(() => {
      setIsLoading(false);
    }, 0);
  }, []);

  const {
    tableRef,
    cashflowItem,
    cashflow,
    loadData,
  } = props;

  useEffect(() => {
    setXaxisHeaders(generateXaxisHeaders(getCurrentProject().startDate, getCurrentProject().firstForecast));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function renderHeavyContent() {
    return (
      <div>
        <InputNavBar
          item={cashflowItem.cashflowDto}
          refreshDataMethod={loadData}
          showDelete={false}
        ></InputNavBar>

        <Card
          mb={6}
          sx={{
            ...tableWidth,
            overflowX: "auto",
            overflow: "scroll",
            paddingTop: 0,
          }}
          id={cashflowItem.cashflowDto.name.replaceAll(" ", "-") + "-table"}
        >
          <CardContent  sx={{ paddingTop: 0}}>

            <TableContainerPowdr component={Paper} sx={{ overflow: 'auto' }}>

              <Table ref={tableRef}>
                {/* X AXIS - PRODUCT NAME, JAN, FEB, MAR, ETC... */}
                <TableHead >
                  <TableRow
                    key={cashflowItem.cashflowDto.name + "-ROW"}
                    id={cashflowItem.cashflowDto.name + "-ROW"}
                  >
                    <TableCell
                      key={cashflowItem.cashflowDto.name + "-BASE"}
                      id={cashflowItem.cashflowDto.name + "-BASE"}
                      style={{ position: "sticky", left: 0 }}
                      sx={{
                        minWidth: 200,
                        maxWidth: 200,
                        width: 200,
                        backgroundColor: "#F7F9FC",
                        zIndex: 3, // Ensure the header is above other content
                      }}
                    >

                    </TableCell>
                    {XaxisHeaders.map((yHeader, index) => (
                      <MonthHeader key={Math.random() * 10000000} yHeader={yHeader} name={cashflowItem.cashflowDto.name} index={index}></MonthHeader>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {cashflowItem.cashflowCategoryDtos.filter(item => item.name !== "Exceptionals").map((row) => (

                    <CashflowRow key={row.id} row={row} cashflow={cashflow}></CashflowRow>

                  ))}
                </TableBody>
              </Table>

            </TableContainerPowdr>
          </CardContent>
        </Card>
      </div>
    )
  }
  
  return (
    <React.Fragment key={key}>
      {isLoading ? (
        <Skeleton /> // Loading widget or spinner
      ) : (
        renderHeavyContent() // Rendered heavy content
      )}
    </React.Fragment>
  );
}

export default CashflowInput;
