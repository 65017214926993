import React, { useState, useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

function SelectUserToEdit({ setUserToEdit, companies, users }) {
  const [selectedCompany, setSelectedCompany] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    let selectedCompanyNum = Number(selectedCompany);

    if (selectedCompanyNum) {
      const filtered = users.filter(user => user.company === selectedCompanyNum);
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers([]);
    }
  }, [selectedCompany, users]);

  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
  };

  const handleUserChange = (event) => {
    let selectedUserId = Number(event.target.value);
    const selectedUser = users.find(user => user.id === selectedUserId);
    setUserToEdit(selectedUser);
  };

  return (
    <React.Fragment>
      <div>
        <FormControl sx={{minWidth : 200}} margin="normal">
          <InputLabel id="company-label">Select Company</InputLabel>
          <Select
            labelId="company-label"
            id="company"
            value={selectedCompany}
            onChange={handleCompanyChange}
            label="Select Company"
          >
            <MenuItem value="">
              <em>--Select a Company--</em>
            </MenuItem>
            {companies.map(company => (
              <MenuItem key={company.id} value={company.id}>
                {company.companyName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div>
        <FormControl sx={{minWidth : 200}} margin="normal" disabled={!selectedCompany}>
          <InputLabel id="user-label">Select User</InputLabel>
          <Select
            labelId="user-label"
            id="user"
            onChange={handleUserChange}
            label="Select User"
            disabled={!selectedCompany}
          >
            <MenuItem value="">
              <em>--Select a User--</em>
            </MenuItem>
            {filteredUsers.map(user => (
              <MenuItem key={user.id} value={user.id}>
                {user.firstName} {user.lastName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </React.Fragment>
  );
}

export default SelectUserToEdit;
