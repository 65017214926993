import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button, Checkbox, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControl,
  FormControlLabel, FormGroup,
  FormLabel, Grid, List, ListItem, ListItemText,
  Radio,
  RadioGroup,
  TextField, Typography
} from "@mui/material";
import { Formik} from "formik";
import useProject from "../../../../../hooks/useProject";
import axios from "axios";
import * as Yup from "yup";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { overheadsState } from "../../../SharedState/OverheadsState";
import { revenueAndCostState } from "../../../SharedState/RevenueAndCostState";
import { headcountsState } from "../../../SharedState/HeadcountsState";
import { pnlState } from "../../../SharedState/PnLState";
import { currentLiabilitiesState } from "../../../SharedState/CurrentLiabilitiesState";
import UpdatePowdrModelGlobal from "../../../SharedComponents/UpdatePowdr/UpdatePowdrModelGlobal";
import { balanceSheetState } from "../../../SharedState/BalanceSheetState";
import { getProjectIdNotAsync } from "../../../SharedComponents/ProjectServices";

function updateOverhead(values,
                        project,
                        overhead,
                        setOpen,
                        setSubmitting,
                        setErrors,
                        setOverheadAtom,
                        setPnlAtom,
                        setCurrentLiabiltiesAtom,
                        resetForm,
                        setShowSpinner,
                        balanceAtom,
                        updatePowdrModelGlobalRef,
                        selectedOverheads) {


  try {

    setShowSpinner(true)

    axios
      .put("overheads", {
        name: values.overheadName,
        project: project.id,
        assumption: values.assumptionFormat,
        percentageComplete: 0,
        overrideComplete: false,
        markedComplete: values.markedComplete,
        profitLine: values.grossProfitLineFlag,
        canDelete: values.canDelete,
        vatInputRate: overhead.vatInputRate,
        id: overhead.id,
        linkedProjectId: values.selectedProject_,
        products: overhead.products || [],
        headcounts: overhead.headcounts || [],
        overheads: selectedOverheads || [],
        comment: overhead.comment,
      })
      .then(async function(response) {

        //update the atoms - overheads & pnl & current liabilities (it has a list of overheads)
        await axios.get("overheads/" + project.id).then(function(response) {
          setOverheadAtom(response.data);
        });


        await axios.get("currentliabilities/" + project.id).then(function(response) {
          setCurrentLiabiltiesAtom(response.data);
        });

        await axios.get("pnl/" + project.id).then(function(response) {
          updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(balanceAtom, response.data);
        });

        setOpen(false);

        setSubmitting(false);
        resetForm();
        setShowSpinner(false);

      })
      .catch(function(error) {
        setSubmitting(false);
        setShowSpinner(false)
        setOpen(false);
        resetForm();
        console.log(error);
      });

    setSubmitting(false);
    //resetForm();
  } catch (error) {
    setShowSpinner(false)
    setErrors({ submit: error.message });
    setSubmitting(false);
    setOpen(false);
    resetForm();
  }
}

function EditOverheads(props) {

  const updatePowdrModelGlobalRef = useRef();

  const { project } = useProject();
  const { overhead, open, setOpen } = props;

  const [showSpinner, setShowSpinner] = React.useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState({ id : 0 });
  const [overheadsForProject, setOverheadsForProject] = useState([]);
  const [overheads, setOverheads] = useState([]);
  const [selectedOverheads, setSelectedOverheads] = useState([]);


  const [overheadsAtom, setOverheadsAtom] = useRecoilState(overheadsState);

  const setPnlAtom = useSetRecoilState(pnlState);
  const setCurrentLiabiltiesAtom = useSetRecoilState(currentLiabilitiesState);

  const [products, setProducts] = useState([]);
  const [headcounts, setHeadcounts] = useState([]);

  const productsAtom = useRecoilValue(revenueAndCostState);
  const headcountsAtom = useRecoilValue(headcountsState);
  const balanceAtom = useRecoilValue(balanceSheetState);

  useEffect(() => {

    if (open === true) {
      setProducts(structuredClone(productsAtom));
      setHeadcounts(structuredClone(headcountsAtom));

      loadProjects();
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsAtom, headcountsAtom, open]);

  const validationSchema = Yup.object().shape({
    overheadName: Yup.string()
      .min(3, "Must be at least 3 characters")
      .max(150, "Must be less than 150 characters")
      .required("Required")
      .notOneOf(overheadsAtom.filter(overhead_ => overhead_?.overheadDto?.name !== overhead?.name).map(function(i) {
        return i.overheadDto.name;
      }), "Overhead must have a unique name"),
  });


  const initialValues = {
    overheadName: overhead?.name,
    assumptionFormat: overhead?.assumption,
    grossProfitLineFlag: overhead?.profitLine,
    canDelete: overhead?.canDelete,
    selectedProject_: overhead?.linkedProjectId,
    selectedOverheads_ : overhead?.overheads,
  };

  async function loadProjects() {

    axios.get('projectsByOwner/' + getProjectIdNotAsync().owner).then(function(response) {
      if (overhead.assumption === "OVERHEAD_DIVISIONAL_CONSOLIDATION" && overhead.canDelete === false && overhead.name.endsWith("(Default Overhead Group)")) {
        setProjects(response.data.filter(project => project.id === overhead.linkedProjectId));
        setSelectedProject(response.data.find(project => project.id === overhead.linkedProjectId))
      } else {
        setProjects(response.data); //bespoke groups will display all the linked projects

        if (response.data.length > 0) {
          setSelectedProject(response.data.filter(projectToPick => getProjectIdNotAsync().id !== projectToPick.id && getProjectIdNotAsync()?.projectMultiProjectList.filter(multiProject => multiProject.project === projectToPick.id).length > 0)[0]);
        }
      }

    })

    axios.get("overheads/" + getProjectIdNotAsync().id).then(function(response) {
      setOverheads(response.data);
    });

    setSelectedOverheads(overhead?.overheads);

    // if (selectedProject !== undefined && selectedProject !==  undefined && overhead.linkedProjectId !== null) {
    //
    //   axios.get("overheads/" + overhead?.linkedProjectId).then(function(response) {
    //
    //     setOverheadsForProject(response.data);
    //     setSelectedOverheads(overhead?.overheads);
    //   });
    // }

  }

  useEffect( () => {

    if (selectedProject !== undefined && selectedProject !==  undefined && overhead.assumption === "OVERHEAD_DIVISIONAL_CONSOLIDATION") {

      axios.get("overheads/" + selectedProject.id).then(function(response) {
        setOverheadsForProject(response.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject]);

  const handleChangeProduct = (event) => {


    if (event.target.checked) {
      if (!overhead.products.includes(parseInt(event.target.id))) {
        overhead.products.push(parseInt(event.target.id))
      }
    } else {
      let productsChosenUpdate = overhead.products.filter(function(e) { return e !== parseInt(event.target.id) })
      overhead.products = productsChosenUpdate
    }


  };

  const handleChangeHeadcount = (event) => {


    if (event.target.checked) {
      if (!overhead.headcounts.includes(parseInt(event.target.id))) {
        overhead.headcounts.push(parseInt(event.target.id))
      }
    } else {
      let headcountsChosenUpdate = overhead.headcounts.filter(function(e) { return e !== parseInt(event.target.id) })
      overhead.headcounts = headcountsChosenUpdate
    }


  };

  function findParentOverhead(row) {
    const found = overheads.find(overhead =>   overhead.overheadDto.overheads.includes(row.overheadDto.id));
    return found ? found.overheadDto.name : '';
  }

  const handleSelectModel = (model) => {

    const currentIndex = selectedOverheads.indexOf(model.overheadDto.id);
    const newChecked = [...selectedOverheads];

    if (currentIndex === -1) {
      newChecked.push(model.overheadDto.id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedOverheads(newChecked);
  };



  const handleSubmit = async (
    values,
    { setErrors, setSubmitting, resetForm }
  ) => {

    if (initialValues.assumptionFormat !== values.assumptionFormat) {
      if (window.confirm("WARNING: Ensure you export your data before modifying this assumption. \nPressing \"OK\" will RESET all entered numbers in this assumption table") === true) {
        updateOverhead(values, project, overhead, setOpen, setSubmitting, setErrors, setOverheadsAtom, setPnlAtom, setCurrentLiabiltiesAtom, resetForm, setShowSpinner, balanceAtom, updatePowdrModelGlobalRef, selectedOverheads);
      } else {
        resetForm();
        setOpen(false);
      }
    } else {
      updateOverhead(values, project, overhead, setOpen, setSubmitting, setErrors, setOverheadsAtom, setPnlAtom, setCurrentLiabiltiesAtom, resetForm, setShowSpinner, balanceAtom, updatePowdrModelGlobalRef, selectedOverheads);
    }

  };

  return (
    <React.Fragment>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (

              <Dialog
                open={open}
                onClose={(event, reason) => {
                  if (reason !== 'backdropClick') {
                    setOpen(false)
                  }
                }}
                aria-labelledby="form-dialog-title"
                fullWidth={["OVERHEAD_DIVISIONAL_CONSOLIDATION"].includes(values.assumptionFormat)}
                maxWidth={"md"}
              >
                <form onSubmit={handleSubmit}>
                  <DialogTitle id="form-dialog-title">Edit Overhead</DialogTitle>
                  <DialogContent sx={{ paddingBottom: 0 }}>

                    <Grid container spacing={3}>
                      <Grid item xs>

                    <DialogContentText>
                      Update the name of the overhead you wish to edit.
                    </DialogContentText>
                    <TextField
                      error={Boolean(touched.overheadName && errors.overheadName)}
                      name="overheadName"
                      autoFocus
                      placeholder={"Overhead Name"}
                      margin="dense"
                      id="overheadName"
                      label="Overhead Name"
                      value={values.overheadName}
                      onChange={handleChange}
                      disabled={!values.canDelete}
                      type="text"
                      helperText={touched.overheadName && errors.overheadName}
                      fullWidth
                    />

                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Assumption Format
                    </FormLabel>
                    <RadioGroup
                      list="true"
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="assumptionFormat"
                      value={values.assumptionFormat}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="PERCENTAGE_OF_REVENUE"
                        control={<Radio />}
                        label="Percentage of Revenue"
                        disabled={values.assumptionFormat === "OVERHEAD_DIVISIONAL_CONSOLIDATION"}
                      />
                      <FormControlLabel
                        value="ANNUAL_GROWTH_RATE"
                        control={<Radio />}
                        label="Annual Growth Rate"
                        disabled={values.assumptionFormat === "OVERHEAD_DIVISIONAL_CONSOLIDATION"}
                      />
                      <FormControlLabel
                        value="MONTHLY_GROWTH_RATE"
                        control={<Radio />}
                        label="Monthly Growth Rate"
                        disabled={values.assumptionFormat === "OVERHEAD_DIVISIONAL_CONSOLIDATION"}
                      />
                      <FormControlLabel
                        value="DIRECT_INPUT"
                        control={<Radio />}
                        label="Direct Input"
                        disabled={values.assumptionFormat === "OVERHEAD_DIVISIONAL_CONSOLIDATION"}
                      />
                      <FormControlLabel
                        value="PRICE_X_QUANTITY_BASIC"
                        control={<Radio />}
                        label="Price x Quantity (basic)"
                        disabled={values.assumptionFormat === "OVERHEAD_DIVISIONAL_CONSOLIDATION"}
                      />
                      <FormControlLabel
                        value="PRICE_X_QUANTITY_VOLUME_DRIVEN"
                        control={<Radio />}
                        label="Price x Quantity (volume driven)"
                        disabled={values.assumptionFormat === "OVERHEAD_DIVISIONAL_CONSOLIDATION"}
                      />
                      <FormControlLabel
                        value="PRICE_X_QUANTITY_DEPARTMENT_DRIVEN"
                        control={<Radio />}
                        label="Price x Quantity (department driven)"
                        disabled={values.assumptionFormat === "OVERHEAD_DIVISIONAL_CONSOLIDATION"}
                      />
                      <FormControlLabel
                        value="PRICE_X_QUANTITY_PERCENTAGE_PRODUCT"
                        control={<Radio />}
                        label="Percentage of Specific Revenue"
                        disabled={values.assumptionFormat === "OVERHEAD_DIVISIONAL_CONSOLIDATION"}
                      />
                      <FormControlLabel
                        value="OVERHEAD_DIVISIONAL_CONSOLIDATION"
                        control={<Radio />}
                        label="Overhead Consolidation"
                        disabled={true}
                      />
                    </RadioGroup>

                    <FormLabel id="demo-row-radio-buttons-group-label">
                      P & L location:
                    </FormLabel>
                    
                    <RadioGroup
                      list="true"
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="grossProfitLineFlag"
                      value={values.grossProfitLineFlag}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value={"ABOVE"}
                        control={<Radio />}
                        label="Variable costs (Above Gross Profit)"
                      />
                      <FormControlLabel
                        value={"BELOW"}
                        control={<Radio />}
                        label="Overhead (Below Gross Profit, but Above EBITDA)"
                      />
                      <FormControlLabel
                        value={"EBITDA"}
                        control={<Radio />}
                        label="Below EBITDA"
                      />
                    </RadioGroup>
                      </Grid>




                      {values.assumptionFormat === "OVERHEAD_DIVISIONAL_CONSOLIDATION" &&
                        <Grid item xs>
                          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormLabel component="legend">Select a linked project to create your overhead consolidation group</FormLabel>
                            <FormGroup>

                              <Box sx={{minWidth: '50%'}}>
                                <br/>
                                <Typography variant="h6">Projects</Typography>
                                <Box sx={{ minHeight: '90%', maxHeight: '90%', overflow: 'auto', border: '1px solid #ccc', padding: '8px' }}>
                                  <List>

                                    {projects.filter(projectToPick => getProjectIdNotAsync().id !== projectToPick.id && getProjectIdNotAsync()?.projectMultiProjectList.filter(multiProject => multiProject.project === projectToPick.id).length > 0).map((row) => (
                                      <ListItem
                                        key={row.id}
                                        button
                                        selected={selectedProject.id === row.id}
                                        onClick={() => setSelectedProject(row)}
                                        disabled={overhead.name.includes("(Default Overhead Group)")}
                                      >
                                        <ListItemText primary={row.name} />
                                      </ListItem>
                                    ))}
                                  </List>
                                </Box>
                              </Box>
                            </FormGroup>

                          </FormControl>
                        </Grid>
                      }

                      {values.assumptionFormat === "OVERHEAD_DIVISIONAL_CONSOLIDATION" &&
                        <Grid item xs>
                          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormLabel component="legend">Select overheads you want to include in this new consolidation, note that some may already be in an existing group.</FormLabel>
                            <FormGroup>

                              <Box sx={{minWidth: '50%'}}>
                                <Typography variant="h6">Overheads</Typography>
                                <Box sx={{ minHeight: '90%', maxHeight: '90%', overflow: 'auto', border: '1px solid #ccc', padding: '8px' }}>
                                  <List>

                                    {overheadsForProject.map((row) => (
                                      <ListItem
                                        key={row.overheadDto.id}
                                        button
                                        selected={selectedProject.id === row.id}
                                        disabled={overhead.name.includes("(Default Overhead Group)")}
                                        secondaryAction={
                                          <Checkbox
                                            edge="end"
                                            onChange={() => handleSelectModel(row)}
                                            checked={selectedOverheads.indexOf(row.overheadDto.id) !== -1}
                                          />
                                        }
                                      >

                                        <ListItemText primary={row.overheadDto.name} secondary={`Current Group: ${(findParentOverhead(row))}`} />
                                      </ListItem>
                                    ))}
                                  </List>
                                </Box>
                              </Box>

                            </FormGroup>

                          </FormControl>
                        </Grid>
                      }



                      {values.assumptionFormat === "PRICE_X_QUANTITY_VOLUME_DRIVEN" && <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormLabel component="legend">Products To include in percentage of revenue
                            calculation</FormLabel>
                          <FormGroup>

                            {products &&
                              products.filter(product => ["PRICE_X_QUANTITY",
                                "PRICE_X_QUANTITY_COST_INFLATION",
                                "SUBSCRIPTION_MODEL",
                                "HEADS_TO_REVENUE",
                                "HEADCOUNT_UTILISATION",
                                "NON_HEADCOUNT_UTILISATION",
                                "PRICE_X_QUANTITY_BUILDER",
                                "SALES_PER_DAY_REVENUE",
                                "PERCENTAGE_OF_ANOTHER_PRODUCT"].includes(product.productDto.assumption)).map((product) => (
                                <FormControlLabel key={Math.random() * 10000000}
                                                  control={
                                                    <Checkbox defaultChecked={overhead?.products?.includes(product.productDto.id)}
                                                              onChange={handleChangeProduct}
                                                              name={product.productDto.name} id={product.productDto.id + ""} />
                                                  }
                                                  label={product.productDto.name}
                                />

                              ))}

                          </FormGroup>

                        </FormControl>
                      </Grid>
                      }


                      {values.assumptionFormat === "PRICE_X_QUANTITY_PERCENTAGE_PRODUCT" && <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormLabel component="legend">Products To include in Percentage of Specific Revenue
                            calculation</FormLabel>
                          <FormGroup>

                            {products &&
                              products.map((product) => (
                                <FormControlLabel key={Math.random() * 10000000}
                                                  control={
                                                    <Checkbox defaultChecked={overhead?.products?.includes(product.productDto.id)}
                                                              onChange={handleChangeProduct}
                                                              name={product.productDto.name} id={product.productDto.id + ""} />
                                                  }
                                                  label={product.productDto.name}
                                />

                              ))}

                          </FormGroup>

                        </FormControl>
                      </Grid>
                      }

                      {values.assumptionFormat === "PRICE_X_QUANTITY_DEPARTMENT_DRIVEN" && <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormLabel component="legend">Headcount To include in Price x Quantity (department driven)
                            calculation</FormLabel>
                          <FormGroup>

                            {headcounts &&
                              headcounts.filter(headcount => headcount.headCountDto.assumption === 'PRICE_X_QUANTITY').map((headcount) => (

                                headcount.headCountCategoryDtos.filter(category => category.name === 'Quantity' && category.departmentName !== '').map((category) => (
                                  <FormControlLabel key={Math.random() * 10000000}
                                                    control={
                                                      <Checkbox
                                                                defaultChecked={overhead?.headcounts?.includes(category?.id)}
                                                                onChange={handleChangeHeadcount}x
                                                                name={category.departmentName} id={category.id + ""} />
                                                    }
                                                    label={category.departmentName}
                                  />
                                ))

                              ))}

                          </FormGroup>

                        </FormControl>
                      </Grid>
                      }

                    </Grid>

                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      autoFocus
                      disabled={isSubmitting}
                    >
                      {showSpinner ? <CircularProgress size={24} /> : 'Update'}
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
          )}
        </Formik>
      <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>
    </React.Fragment>
  );
}

export default EditOverheads;
