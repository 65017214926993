import axiosInstance from "../../../utils/axios";
import axios from "axios";
import _ from "lodash";

export function refreshApiCall(setSelf, path) {
  //get the project from the session storage
  let sessionProject = sessionStorage.getItem("project");

  if (sessionProject !== undefined
    && sessionProject !== null
    && sessionProject !== "undefined") {

    let project = JSON.parse(sessionStorage.getItem("project"));

    var accessToken = window.localStorage.getItem("accessToken");
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    axios.get(path + "/" + project.id).then(function(response) {
      //console.log(`Setting ${path} Atom`, response.data);
      setSelf(response.data);
    });

  }
}


export function handleDataUpdate(newAssumptions, oldAssumptions, endpoint, monthArrayName, categoriesArrayName, parentName) {

  //console.info('evaluating: ' + monthArrayName)

  const originalCategoryDtos = extractEquityCategoryDtos(oldAssumptions, categoriesArrayName, parentName);
  const modifiedCategoryDtos = extractEquityCategoryDtos(newAssumptions, categoriesArrayName, parentName);


  let [monthsToUpdate, updateRequired] = findDifferences(originalCategoryDtos, modifiedCategoryDtos, monthArrayName);

  if (updateRequired === true) {
    //console.info("1. MONTHS TO UPDATE " + monthsToUpdate.length + " " + monthArrayName , monthsToUpdate)
    axios.put(endpoint, monthsToUpdate);
  } else {
    //console.log('No Update Required for: ' + monthArrayName, monthsToUpdate)
  }
}

function extractEquityCategoryDtos(assumptionsArray, categoriesArrayName, parentName) {
  if (_.isNil(assumptionsArray) || _.isEmpty(assumptionsArray) || _.isUndefined(assumptionsArray)) return [];

  // Assuming the parent object with the name attribute is accessible
  // as `parentName` in this scope. If `parentName` is actually the name
  // of the parent and you have a way to get the parent object by this name,
  // you should retrieve the parent object accordingly.

  return assumptionsArray.flatMap(item => {
    const categories = item[categoriesArrayName] || [];

    const name = item[parentName];
    // Add the parent object to each category DTO
    return categories.map(category => ({
      ...category,
      parentName: name.name
    }));
  });
}


function findDifferences(originalArray, modifiedArray, monthListName) {

  let project = JSON.parse(sessionStorage.getItem("project"));


  if (_.isNil(originalArray) || _.isEmpty(originalArray) || _.isUndefined(originalArray)) return [];
  if (_.isNil(modifiedArray) || _.isEmpty(modifiedArray) || _.isUndefined(modifiedArray)) return [];

  // Modified to include categoryName in each monthDto
  const originalArrayFlat = _.flatMap(originalArray, item =>
    (item[monthListName] || []).map(monthDto => ({ ...monthDto, categoryName: item.name, parentName: item.parentName }))
  );
  const modifiedArrayFlat = _.flatMap(modifiedArray, item =>
    (item[monthListName] || []).map(monthDto => ({ ...monthDto, categoryName: item.name, parentName: item.parentName }))
  );

  let changes = [];
  let updateRequired = false;

  originalArrayFlat.forEach(originalMonth => {
    const modifiedMonth = _.find(modifiedArrayFlat, { id: originalMonth.id });

    if (modifiedMonth
      && hasValueOrOverridenValueChanged(originalMonth, modifiedMonth)) {

        if (modifiedMonth.value !== null && modifiedMonth.value !== undefined) {
          //console.log(originalMonth, " does not equal ", modifiedMonth);
          delete modifiedMonth["assumptions"];

          modifiedMonth.previousValue = originalMonth.value;

          let startDate = new Date(project.startDate);

          startDate.setMonth(startDate.getMonth() + modifiedMonth.monthIndex);

          //modifiedMonth.parentName = modifiedMonth.parentName;
          modifiedMonth.monthName = startDate.toLocaleString('default', { month: 'long', year : "2-digit" });

          changes.push(modifiedMonth);
          updateRequired = true;
        } else {
          console.info('Null found for list ' + monthListName, modifiedMonth)
        }

    }
  });

  return [changes, updateRequired];
}

/**
 * Method will compare two arrays (monthsDTOS) and call update
 *
 * @param originalArrayFlat
 * @param modifiedArrayFlat
 * @param endpoint
 */
export function compareTwoMonthArrays(originalArrayFlat, modifiedArrayFlat, endpoint) {

  const project = JSON.parse(sessionStorage.getItem("project"));

  //compare the two arrays and call update on the changed values
  let changes = [];
  let updateRequired = false;

  originalArrayFlat.forEach(originalMonth => {
    const modifiedMonth = _.find(modifiedArrayFlat, { id: originalMonth.id });

    if (modifiedMonth
      && hasValueOrOverridenValueChanged(originalMonth, modifiedMonth)) {

      if (modifiedMonth.value !== null && modifiedMonth.value !== undefined) {
        //console.log(originalMonth, " does not equal ", modifiedMonth);
        delete modifiedMonth["assumptions"];

        modifiedMonth.previousValue = originalMonth.value;

        let startDate = new Date(project.startDate);

        startDate.setMonth(startDate.getMonth() + modifiedMonth.monthIndex);

        //modifiedMonth.parentName = modifiedMonth.parentName;
        modifiedMonth.monthName = startDate.toLocaleString('default', { month: 'long', year : "2-digit" });

        changes.push(modifiedMonth);
        updateRequired = true;
      } else {
        console.info('Null found for list', modifiedMonth)
      }

    }
  });

  if (updateRequired === true) {
    //console.info("1. MONTHS TO UPDATE " + monthsToUpdate.length + " " + monthArrayName , monthsToUpdate)
    axios.put(endpoint, changes);
  }
}

function hasValueOrOverridenValueChanged(originalMonth, modifiedMonth) {

  //Has the cell type changed - happens when undo'ing an overriden cell
  if (originalMonth.cellType !== modifiedMonth.cellType) {
    return true;
  }

  //defensive guard against null values on 'value' attribute
  if (originalMonth.value === null || originalMonth.value === undefined) {
    return false;
  }

  if (modifiedMonth.value === null || modifiedMonth.value === undefined) {
    return false;
  }

  //if the value of modified is different to original, then return true
  if (originalMonth.value !== modifiedMonth.value) {
    return true;
  }

  //if the value hasn't changed then we check the overriden value
  if (originalMonth.valueOverriden !== modifiedMonth.valueOverriden) {
    return true;
  }

  //has the cell type changed? For actualising a project
  if (originalMonth.cellType !== modifiedMonth.cellType) {
    return true;
  }

  //has the financialTypeEnum changed? For actualising a project
  if (originalMonth.financialTypeEnum !== modifiedMonth.financialTypeEnum) {
    return true;
  }

  //default fallback
  return false;

}
