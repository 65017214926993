
import React, {  useState }  from "react";
import axios from "axios";
import {
  Alert,
  Box, Button, Card,
  CardContent,
  CircularProgress, DialogActions, DialogContentText, FormControl,
  Grid, MenuItem, Select,
  TextField,
  Typography
} from "@mui/material";
import { Field, Formik } from "formik";
import * as Yup from "yup";

import useProject from "../../../../../hooks/useProject";
import { addMonthsToDate } from "../../../SharedComponents/utils/Utils";
import { differenceInMonths } from "date-fns";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object().shape({
  projectName: Yup.string()
    .min(3, "Must be at least 3 characters")
    .max(100, "Must be less than 100 characters")
    .required("Required"),
  startYear: Yup.string().required("Required"),
  startMonth: Yup.string().required("Required"),
});

const CustomizedSelectForFormik = ({ children, form, field }) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  return (
    <Select
      name={name}
      value={value}
      onChange={(e) => {
        setFieldValue(name, e.target.value);
      }}
    >
      {children}
    </Select>
  );
};

function ProjectSetupPowdrStaff(props) {
  const navigate = useNavigate();


  const [triggerRefresh, setTriggerRefresh] = useState([]);
  const { setProject } = useProject();

  const {project, companies} = props;

  const initialValues = {
    projectName: project?.name,
    owner: project?.owner,
    startYear: "" + new Date(project?.startDate).getFullYear(),
    startMonth: "" + (new Date(project?.startDate).getMonth() + 1),
    startForecastYear: "" + addMonthsToDate(new Date(project?.startDate), project?.firstForecast).getFullYear(),
    startForecastMonth: "" + (addMonthsToDate(new Date(project?.startDate), project?.firstForecast).getMonth() + 1),
    license: project?.license,
    projectType: project?.projectType,
  };



  function calculateFirstValidForecast(startMonth, startYear, startForecastMonth, startForecastYear) {

    let actualsStartDate = new Date(Date.UTC(startYear, startMonth));

    let forecastStartDate = new Date(Date.UTC(startForecastYear, startForecastMonth));

    return !isThreeMonthsGreater(actualsStartDate, forecastStartDate);
  }

  function isThreeMonthsGreater(date1, date2) {
    const differenceInMonths = (date2.getFullYear() - date1.getFullYear()) * 12 + (date2.getMonth() - date1.getMonth());
    return differenceInMonths >= 3;
  }



  const handleSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {

      let startDate = new Date(values.startYear + "-" + values.startMonth + "-01");

      let noMonths = Math.abs(differenceInMonths(startDate, new Date(values.startForecastYear, values.startForecastMonth)) +1);

      var data = {
        name: values.projectName,
        owner: values.owner,
        id: project.id,
        projectType: values.projectType,
        projectMultiProjectList : project.projectMultiProjectList,
        disabled: project.disabled,
        deleted: project.deleted,
        firstForecast: noMonths,
        license: project.license,
        firstBalanceForecast: noMonths,
        initialised: project.initialised,
        lastEdit: new Date(),
        startDate: new Date(Date.UTC(values.startYear, values.startMonth - 1, 1)),
        folderId: project.folderId,
        hideDefaultGroups: project.hideDefaultGroups,
      };

      axios
        .put("project", data)
        .then(function (response) {
          setStatus({ sent: true });

          //This will set the edited project as the currently active project
          setProject(response.data)

          navigate("/home");
          window.location.reload();
        })
        .catch(function (error) {
          setStatus({ sent: false });
        });

      setSubmitting(false);
    } catch (error) {
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Project Setup
        </Typography>
        <Typography variant="body2" gutterBottom>
          Update your projects name
        </Typography>

        {initialValues == null ? (
          <Box display="flex" justifyContent="center" my={6}>
            <CircularProgress />
          </Box>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                status,
              }) => (
              <form onSubmit={handleSubmit}>
                {status && status.sent && (
                  <Alert severity="success" my={3}>
                    Your project start date has been successfully updated! (If you have updated the <strong>first forecast values</strong>, then this may take several minutes to complete in the background)
                  </Alert>
                )}
                <Grid container spacing={6}>
                  <Grid item md={6}>
                    <TextField
                      error={Boolean(touched.projectName && errors.projectName)}
                      margin="dense"
                      name="projectName"
                      label="Project Name"
                      type="text"
                      value={values.projectName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={6}>
                  <Grid item md={6}>
                    <FormControl sx={{ mt: 2, minWidth: 1 }}>
                      <DialogContentText>Start Year:</DialogContentText>
                      <Field
                        name="startYear"
                        component={CustomizedSelectForFormik}
                        value={values.startYear}
                        fullWidth
                      >
                        <MenuItem value="2022">2022</MenuItem>
                        <MenuItem value="2023">2023</MenuItem>
                        <MenuItem value="2024">2024</MenuItem>
                        <MenuItem value="2025">2025</MenuItem>
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item md={6}>
                    <FormControl sx={{ mt: 2, minWidth: 1 }}>
                      <DialogContentText>Start Month:</DialogContentText>
                      <Field
                        name="startMonth"
                        component={CustomizedSelectForFormik}
                        value={values.startMonth}
                        fullWidth
                      >
                        <MenuItem value="1">January</MenuItem>
                        <MenuItem value="2">February</MenuItem>
                        <MenuItem value="3">March</MenuItem>
                        <MenuItem value="4">April</MenuItem>
                        <MenuItem value="5">May</MenuItem>
                        <MenuItem value="6">June</MenuItem>
                        <MenuItem value="7">July</MenuItem>
                        <MenuItem value="8">August</MenuItem>
                        <MenuItem value="9">September</MenuItem>
                        <MenuItem value="10">October</MenuItem>
                        <MenuItem value="11">November</MenuItem>
                        <MenuItem value="12">December</MenuItem>
                      </Field>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={6} >
                  <Grid item md={6}>
                    <FormControl sx={{ mt: 2, minWidth: 1 }}>
                      <DialogContentText>First Forecast Year:</DialogContentText>
                      <Field
                        name="startForecastYear"
                        component={CustomizedSelectForFormik}
                        value={values.startForecastYear}
                        fullWidth
                      >
                        <MenuItem disabled={values.startYear > 2022} value="2022">2022</MenuItem>
                        <MenuItem disabled={values.startYear > 2023} value="2023">2023</MenuItem>
                        <MenuItem disabled={values.startYear > 2024} value="2024">2024</MenuItem>
                        <MenuItem disabled={values.startYear > 2025} value="2025">2025</MenuItem>
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item md={6}>
                    <FormControl sx={{ mt: 2, minWidth: 1 }}>
                      <DialogContentText>First Forecast Month:</DialogContentText>
                      <Field
                        name="startForecastMonth"
                        component={CustomizedSelectForFormik}
                        value={values.startForecastMonth}
                        fullWidth
                      >
                        <MenuItem disabled={calculateFirstValidForecast(values.startMonth, values.startYear, 1, values.startForecastYear)} key={1} value="1">January</MenuItem>
                        <MenuItem disabled={calculateFirstValidForecast(values.startMonth, values.startYear, 2, values.startForecastYear)} key={2} value="2">February</MenuItem>
                        <MenuItem disabled={calculateFirstValidForecast(values.startMonth, values.startYear, 3, values.startForecastYear)} key={3} value="3">March</MenuItem>
                        <MenuItem disabled={calculateFirstValidForecast(values.startMonth, values.startYear, 4, values.startForecastYear)} key={4} value="4">April</MenuItem>
                        <MenuItem disabled={calculateFirstValidForecast(values.startMonth, values.startYear, 5, values.startForecastYear)} key={5} value="5">May</MenuItem>
                        <MenuItem disabled={calculateFirstValidForecast(values.startMonth, values.startYear, 6, values.startForecastYear)} key={6} value="6">June</MenuItem>
                        <MenuItem disabled={calculateFirstValidForecast(values.startMonth, values.startYear, 7, values.startForecastYear)} key={7} value="7">July</MenuItem>
                        <MenuItem disabled={calculateFirstValidForecast(values.startMonth, values.startYear, 8, values.startForecastYear)} key={8} value="8">August</MenuItem>
                        <MenuItem disabled={calculateFirstValidForecast(values.startMonth, values.startYear, 9, values.startForecastYear)} key={9} value="9">September</MenuItem>
                        <MenuItem disabled={calculateFirstValidForecast(values.startMonth, values.startYear, 10, values.startForecastYear)} key={10} value="10">October</MenuItem>
                        <MenuItem disabled={calculateFirstValidForecast(values.startMonth, values.startYear, 11, values.startForecastYear)} key={11} value="11">November</MenuItem>
                        <MenuItem disabled={calculateFirstValidForecast(values.startMonth, values.startYear, 12, values.startForecastYear)} key={12} value="12">December</MenuItem>
                      </Field>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item md={6}>
                  <FormControl sx={{ mt: 2, minWidth: 1 }} disabled={values.projectType === "PNL_DIVISIONAL"}>
                    <DialogContentText>Project Type:</DialogContentText>
                    <Field
                      name="projectType"
                      component={CustomizedSelectForFormik}
                      value={values.projectType}
                      fullWidth
                    >
                      <MenuItem key={1} value="SINGLE_ENTITY" onClick={() => window.alert("Caution: Changing license type will swap Corporation Tax and Depreciation to be CALCULATED rather than direct input")}>Standard</MenuItem>
                      <MenuItem key={2} value="PNL" onClick={() => window.alert("Caution: Changing license type will swap Corporation Tax and Depreciation to be DIRECT INPUT rather than calculated")}>P & L</MenuItem>
                      <MenuItem disabled={values.projectType !== "PNL_DIVISIONAL"} key={3} value="PNL_DIVISIONAL" onClick={() => window.alert("Caution: Changing license type will swap Corporation Tax and Depreciation to be DIRECT INPUT rather than calculated")}>Divisional P&L Consolidation</MenuItem>
                    </Field>
                  </FormControl>
                </Grid>

                <Grid item md={6}>
                  <FormControl sx={{ mt: 2, minWidth: 1 }}>
                    <DialogContentText>Company:</DialogContentText>
                    <Field
                      name="owner"
                      component={CustomizedSelectForFormik}
                      value={values.owner}
                      fullWidth
                    >
                      {!!companies &&
                        companies.map((company) => (
                          <MenuItem onClick={() => {
                            //values.projectMultiProjectList.length = 0;
                            setTriggerRefresh({ ...triggerRefresh })
                          }}
                                    key={company.id} value={company.id}>{company.companyName}</MenuItem>
                        ))}
                    </Field>
                  </FormControl>
                </Grid>

                <Grid id={"actuals"} container spacing={6} flexWrap={"nowrap"}>
                  <Grid item xs={12} flexWrap={"nowrap"}></Grid>
                </Grid>

                <DialogActions>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    autoFocus
                    disabled={isSubmitting}
                  >
                    Update
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        )}
      </CardContent>
    </Card>
  );
}

export default ProjectSetupPowdrStaff;