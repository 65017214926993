export let revenueAssumptionMap = new Map([
  ["DIRECT_INPUT", "Gross Profit"],
  ["PRICE_X_QUANTITY", "Gross Profit"],
  ["PRICE_X_QUANTITY_COST_INFLATION", "Gross Profit"],
  ["SUBSCRIPTION_MODEL", "Gross Profit"],
  ["ANNUAL_GROWTH_RATE", "Gross Profit"],
  ["MONTHLY_GROWTH_RATE", "Gross Profit"],
  ["HEADS_TO_REVENUE", "Gross Profit"],
  ["HEADCOUNT_UTILISATION", "Revenue"],
  ["NON_HEADCOUNT_UTILISATION", "Gross Profit"],
  ["PRICE_X_QUANTITY_BUILDER", "Gross Profit"],
  ["SALES_PER_DAY_REVENUE", "Gross Profit"],
  ["PERCENTAGE_OF_ANOTHER_PRODUCT", "Gross Profit"],
  ["REVENUE_DIVISIONAL_CONSOLIDATION", "Value In P&L"],
  ["LOAN_ASSET_SISTER_PRODUCT", "Gross Profit"],
]);

export let overheadAssumptionMap = new Map([
  ["ANNUAL_GROWTH_RATE", "Value In P&L"],
  ["MONTHLY_GROWTH_RATE", "Value In P&L"],
  ["PERCENTAGE_OF_REVENUE", "Value In P&L"],
  ["DIRECT_INPUT", "Value In P&L"],
  ["PRICE_X_QUANTITY_BASIC", "Overhead Cost"],
  ["PRICE_X_QUANTITY_VOLUME_DRIVEN", "Value In P&L"],
  ["PRICE_X_QUANTITY_DEPARTMENT_DRIVEN", "Value In P&L"],
  ["PRICE_X_QUANTITY_PERCENTAGE_PRODUCT", "Value In P&L"],
  ["OVERHEAD_DIVISIONAL_CONSOLIDATION", "Value In P&L"]
]);

export let headcountAssumptionMap = new Map([
  ["PRICE_X_QUANTITY", "Total cost in P&L"],
  ["ANNUAL_GROWTH_RATE", "Total cost in P&L"],
  ["MONTHLY_GROWTH_RATE", "Total cost in P&L"],
  ["DIRECT_INPUT", "Total cost in P&L"],
  ["HEADCOUNT_DIVISIONAL_CONSOLIDATION", "Total cost in P&L"]
])

export let fixedAssetAssumptionsMap = new Map([
  ["SIMPLE_CORKSCREW", "Closing"],
  ["SIMPLE_CORKSCREW_WITH_INPUT_DA", "Closing"],
  ["SIMPLE_CORKSCREW_WITH_DA_PERCENTAGE", "Closing"]
]);

export let currentAssetsAssumptionsMap = new Map([
  ["SIMPLE_CORKSCREW", "Closing"],
  ["SIMPLE_CORKSCREW_WITH_INPUT_DA", "Closing"],
  ["SIMPLE_CORKSCREW_WITH_DA_PERCENTAGE", "Closing"],
  ["INVENTORY_MODELLING", "Closing"],
  ["PERCENTAGE_OF_DEBTORS", "Closing"],
  ["PERCENTAGE_OF_CERTAIN_OVERHEADS", "Closing"],
  ["PERCENTAGE_OF_CERTAIN_REVENUE", "Closing"],
  ["TRADE_DEBTORS", "Debtors in the balance sheet"],
  ["LOAN_ASSET", "Closing"],
  ["ACCRUAL", "Closing"],
]);

export let currentLiabilitiesAssumptionsMap = new Map([
  ["TRADE_CREDITORS","Creditors in the balance sheet"],
  ["PERCENTAGE_OF_DEBTORS", "Closing"],
  ["PERCENTAGE_OF_OVERHEADS", "Closing"],
  ["PERCENTAGE_OF_REVENUE", "Closing"],
  ["SIMPLE_CORKSCREW", "Closing"],
  ["NIC_PAYE", "NIC and PAYE"],
  ["CORPORATION_TAX", "Closing Tax Liability"],
  ["VAT_LIABILITY", "Closing VAT"],
  ["VAT_MONTHLY_LIABILITY", "Closing VAT"],
  ["VAT_QUARTERLY_LIABILITY", "Closing VAT"],
  ["PERCENTAGE_OF_CREDITORS", "Closing"],
  ["PERCENTAGE_OF_CERTAIN_OVERHEADS", "Closing"],
  ["PERCENTAGE_OF_CERTAIN_REVENUE", "Closing"],
  ["ACCRUAL", "Closing"],
]);

export let debtAssumptionsMap = new Map([
  ["SIMPLE_CORKSCREW", "Closing"],
  ["LOAN_DEBT", "Closing"],
  ["CID_DEBT", "Closing Borrowings"],
  ["PIK_DEBT", "Closing"],
  ["ABL_DEBT", "Closing Borrowings"],
]);


export let equityAssumptionsMap = new Map([
  ["SIMPLE_CORKSCREW", "Closing"]
]);