import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import {
  CardContent,
  Card as MuiCard,
  TableCell,
  Table,
  TableRow,
  TableBody,
  TableHead,
} from "@mui/material";
import { spacing } from "@mui/system";
import useProject from "../../../../../hooks/useProject";
import PowdrCell from "../../../SharedComponents/PowdrCell/PowdrCell";

import { generateXAnnualAxisHeadersAnnual } from "../../../SharedComponents/MonthHeader";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import { useRecoilValue } from "recoil";
import { zoomState } from "../../../SharedState/ZoomState";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CashflowAnnualMovementModal from "./CashflowAnnualMovementModal";
import { calculateCashflow } from "../../CashFlow/calculations/Calculations";
import _ from "lodash";
import { cashFlowState } from "../../../SharedState/CashflowState";
import { balanceSheetState } from "../../../SharedState/BalanceSheetState";
import { pnlState } from "../../../SharedState/PnLState";
import { totalUpItems } from "../../../Analysis/RevenueDashboard/calculations/Calculations";
const Card = styled(MuiCard)(spacing);



const tableStyling = {
  minWidth: 90,
  fontWeight: "bolder",
  color: "white",
};

const tableWidth = {
  width: "100%",
};

const xAxisStyling = {
  minWidth: 200,
  maxWidth: 200,
  fontWeight: "bolder",
  color: "#2052c2",
  backgroundColor: "#F7F9FC",
};

function CashflowAnnualInput(props) {
  const { key } = useProject();
  const [XaxisHeaders, setXaxisHeaders] = useState([]);
  const [showCurrentLiabilities, setShowCurrentLiabilities] = useState(false);
  const [showCurrentAssets, setShowCurrentAssets] = useState(false);
  const [showDebts, setShowDebts] = useState(false);

  const cashflowAtom = useRecoilValue(cashFlowState);
  const balanceAtom = useRecoilValue(balanceSheetState);
  const pnlAtom = useRecoilValue(pnlState);

  const [currentAssets, setCurrentAssets] = useState();
  const [currentLiabilities, setCurrentLiabilities] = useState();
  const [debts, setDebts] = useState();

  const zoomAtom = useRecoilValue(zoomState);


  const {
    tableRef,
    cashflowItem
  } = props;


  useEffect(() => {

    let cashflow_ = calculateCashflow(
      structuredClone(cashflowAtom),
      structuredClone(balanceAtom), pnlAtom);

    setCurrentAssets(getCategory("Movement In Other Current Assets", cashflow_));
    setCurrentLiabilities(getCategory("Movement In Other Current Liabilities", cashflow_));
    setDebts(getCategory("Net Debt Received/Paid", cashflow_));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashflowAtom]);

  function getCategory(movementInOtherCurrentAssets, cashflow_) {
    if (_.isNil(cashflow_) || _.isEmpty(cashflow_)) {
      return undefined;
    }

    let category = cashflow_.cashflowCategoryDtos.find(category => category.name === movementInOtherCurrentAssets);

    _.forEach(category.movementDetails.categories, function (row) {

      row.cashflowMonthDtos = buildYearTotalCategory(row)

    });

    return category;
  }

  function buildYearTotalCategory(category, resolution = 0) {

    let cashflowMonthDtos = [];


    let yearIndex = 0;
    for (let i=0; i < 72;){

      let yearTotal = totalUpItems(category.cashflowMonthDtos, i);

      cashflowMonthDtos.push({
        id: yearIndex,
        value:  yearTotal ,
        month: yearIndex,
        monthIndex: yearIndex,
        cellType: "CALCULATED",
        resolution: resolution,
      })
      i = i + 12;
      yearIndex++;

    }

    return cashflowMonthDtos;
  }

  useEffect(() => {
    setXaxisHeaders(generateXAnnualAxisHeadersAnnual(getCurrentProject().startDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setShowCurrentLiabilities(false);
    setShowCurrentAssets(false);
    setShowDebts(false);
  }

  function showModal(name) {

    if (name === "Movement In Other Current Liabilities") {
      //open modal window
      setShowCurrentLiabilities(true);
    }

    if (name === "Movement In Other Current Assets") {
      //open modal window
      setShowCurrentAssets(true);
    }

    if (name === "Net Debt Received/Paid") {
      //open modal window
      setShowDebts(true);
    }

  }

  return (
    <React.Fragment key={key}>

      <Card
        mb={6}
        sx={{
          ...tableWidth,
          overflowX: "auto",
          overflow: "scroll",
          paddingTop: 0,
        }}
        id={cashflowItem.cashflowDto.name.replaceAll(" ", "-") + "-table"}
      >
        <CardContent  sx={{ paddingTop: 5, zoom: zoomAtom }}>
          <Table ref={tableRef}>
            {/* X AXIS - PRODUCT NAME, JAN, FEB, MAR, ETC... */}
            <TableHead >
              <TableRow
                key={cashflowItem.cashflowDto.name + "-ROW"}
                id={cashflowItem.cashflowDto.name + "-ROW"}
              >
                <TableCell
                  key={cashflowItem.cashflowDto.name + "-BASE"}
                  id={cashflowItem.cashflowDto.name + "-BASE"}
                  style={{ position: "sticky", left: 0 }}
                  sx={{
                    minWidth: 200,
                    maxWidth: 200,
                    width: 200,
                    backgroundColor: "#F7F9FC",
                  }}
                >

                </TableCell>
                {XaxisHeaders.map((yHeader, index) => (
                  <TableCell
                    key={
                      cashflowItem.cashflowDto.name.replaceAll(" ", "-") +
                      "-" +
                      yHeader.id +
                      "-" +
                      index
                    }
                    id={
                      cashflowItem.cashflowDto.name.replaceAll(" ", "-") +
                      "-" +
                      yHeader.id +
                      "-" +
                      index
                    }
                    variant={"head"}
                    align="right"
                    sx={{
                      ...tableStyling,
                      backgroundColor: yHeader.backgroundColor,
                    }}
                  >
                    {yHeader.type}
                    <br />
                    {yHeader.financialMonth}
                    <br />
                    {yHeader.financialYear}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {cashflowItem.cashflowCategoryDtos.map((row) => (
                <TableRow key={row.id}
                          id={row.id}
                          sx={{borderStyle: row.totalField === true && ["EBITDA", "Cashflow From Operating Activities", "Cashflow From Investing Activities", "Cashflow From Financing Activities", "Net Cashflow", "Opening Cash Balance", "Closing Cash Balance"].includes(row.name) ? "double" : "none", }}
                          onClick={() => showModal(row.name)}>
                  <TableCell
                    component="th"
                    scope="row"
                    variant={"body"}
                    sx={{ ...xAxisStyling, backgroundColor: "#F7F9FC" }}
                    style={{
                      position: "sticky",
                      left: 0,
                      backgroundColor: "#F7F9FC",
                      zIndex: 1,
                    }}
                    onClick={() => showModal(row.name)}
                  >
                    {["Movement In Other Current Liabilities", "Movement In Other Current Assets", "Net Debt Received/Paid"].includes(row.name) && <ArrowRightIcon /> }
                    {row.name}
                  </TableCell>

                  {row.cashflowMonthDtos.map((month) => (
                    <PowdrCell
                      key={month.id + "-CELL"}
                      cellData={month}
                      ownerId={row.id}
                    ></PowdrCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>

        <CashflowAnnualMovementModal open={showCurrentLiabilities} rowData={currentLiabilities} onClose={handleClose}></CashflowAnnualMovementModal>
        <CashflowAnnualMovementModal open={showCurrentAssets} rowData={currentAssets} onClose={handleClose}></CashflowAnnualMovementModal>
        <CashflowAnnualMovementModal open={showDebts} rowData={debts} onClose={handleClose}></CashflowAnnualMovementModal>

      </Card>
    </React.Fragment>
  );
}

export default CashflowAnnualInput;
