import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";

import {
  CardContent,
  Card as MuiCard,
  TableCell,
  Table,
  TableRow,
  TableBody,
  TableHead, Skeleton
} from "@mui/material";
import { spacing } from "@mui/system";
import axios from "axios";
import RowTitle from "../../../SharedComponents/RowTitle";
import getProjectId, { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import PowdrNavBar from "../../../SharedComponents/PowdrNavBar";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { zoomState } from "../../../SharedState/ZoomState";
import MonthHeader, { generateXaxisHeaders } from "../../../SharedComponents/MonthHeader";
import PowdrCell from "../../../SharedComponents/PowdrCell/PowdrCell";
import useProject from "../../../../../hooks/useProject";
import { AssumptionLabel } from "../../../SharedComponents/AssumptionLabel";
import { covenantState } from "../../../SharedState/CovenantState";

const Card = styled(MuiCard)(spacing);

const tableWidth = {
  width: "100%",
};


function CovenantInput(props) {
  const { key } = useProject();
  const [XaxisHeaders, setXaxisHeaders] = useState([]);
  const [openEdit, setOpenEdit] = React.useState(false);
  const tableRef = useRef(null);
  const zoomAtom = useRecoilValue(zoomState);
  const [isLoading, setIsLoading] = useState(true);
  const setCovenantsAtom = useSetRecoilState(covenantState);

  const {
    covenantItem,
    covenant,
    refreshData,
    setEditData,
    editData,
    setCovenants,
    loadData
  } = props;

  const deleteCovenant = (handleClose) => {
    var config = {
      method: "delete",
      url: "covenant",
      data: covenantItem.covenantDto,
    };

    axios(config)
      .then(async function(response) {

        let project = await getProjectId();

        await axios.get("covenant/" + project.id).then(function(response) {
          setCovenantsAtom(response.data);
        });

        handleClose();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    // Defer heavy rendering to allow loading indicator to show
    setTimeout(() => {
      setIsLoading(false);
    }, 0);
  }, []);

  useEffect(() => {
    setXaxisHeaders(generateXaxisHeaders(getCurrentProject().startDate, getCurrentProject().firstBalanceForecast));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClick(id) {
    setEditData(id);
  }

  function getName(row) {

    let name;

    if (row.overrideName !== null && row.name === "Debt") {
      name = row.overrideName;
    } else {
      name = row.name;
    }

    return name;
  }


  function renderHeavyContent() {
    return (
      <div>
        <PowdrNavBar
          item={covenantItem.covenantDto}
          categories={covenantItem.covenantCategoryDtos}
          deleteMethod={deleteCovenant}
          refreshDataMethod={loadData}
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
          showExportToExcel={true}
          exportToExcel={{fileName: "Powdr-Covenant-" + covenantItem.covenantDto.name + "-" + new Date().toDateString(),
            sheet : "Covenant",
            currentTableRef : tableRef.current}}
          showProgressBar={false}
          showEdit={false}
          shallowUpdateUrl={"covenant/shallowUpdate"}
          type="COVENANT"
        ></PowdrNavBar>


        <Card
          mb={6}
          sx={{
            ...tableWidth,
            overflowX: "auto",
            overflow: "scroll",
            paddingTop: 0,
          }}
          id={covenantItem.covenantDto.name.replaceAll(" ", "-") + "-table"}
        >
          <CardContent sx={{ paddingTop: 0 , zoom: zoomAtom}}>
            <Table ref={tableRef}>
              {/* X AXIS - PRODUCT NAME, JAN, FEB, MAR, ETC... */}
              <TableHead>
                <TableRow
                  key={covenantItem.covenantDto.name + "-ROW"}
                  id={covenantItem.covenantDto.name + "-ROW"}
                >
                  <TableCell
                    key={covenantItem.covenantDto.name + "-BASE"}
                    id={covenantItem.covenantDto.name + "-BASE"}
                    style={{ position: "sticky", left: 0 }}
                    sx={{
                      minWidth: 200,
                      maxWidth: 200,
                      width: 200,
                      backgroundColor: "#F7F9FC",
                    }}
                  >
                    <h2>{covenantItem.covenantDto.name}</h2>
                    <AssumptionLabel label={covenantItem.covenantDto.assumptionDesc} color="success" />
                  </TableCell>
                  {XaxisHeaders.map((yHeader, index) => (
                    <MonthHeader key={Math.random() * 10000000} yHeader={yHeader} name={covenantItem.covenantDto.name} index={index}></MonthHeader>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>

                {covenantItem.covenantCategoryDtos.map((row) => (
                  <TableRow key={row.id} id={row.id} onClick={() => handleClick(row.id)}>

                    <RowTitle name={getName(row)}></RowTitle>

                    {row.covenantMonthDtos.map((month) => (
                      <PowdrCell
                        key={month.id + "-CELL"}
                        cellData={month}
                        ownerId={row.id}
                        editTableEnabled={editData}
                        items={covenant}
                        refreshItemData={refreshData}
                        setItems={setCovenants}
                      ></PowdrCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>


      </div>
    )
  }


  return (
    <React.Fragment key={key}>
      {isLoading ? (
        <Skeleton /> // Loading widget or spinner
      ) : (
        renderHeavyContent() // Rendered heavy content
      )}
    </React.Fragment>
  );
}

export default CovenantInput;
