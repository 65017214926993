import React, { forwardRef, useImperativeHandle } from 'react';
import { useRecoilState, useRecoilValue } from "recoil";
import { pnlState } from "../../SharedState/PnLState";
import { refreshVatPackage, vatPackageState } from "../../SharedState/VatPackageState";
import { currentAssetsState } from "../../SharedState/CurrentAssetsState";
import _ from "lodash";
import { calculatePnl } from "../../PLGroup/PL/calculations/Calculations";
import { vatState } from "../../SharedState/VatState";
import { overheadsState } from "../../SharedState/OverheadsState";
import { headcountsState } from "../../SharedState/HeadcountsState";
import { calculateHeadCounts } from "../../AssumptionsGroup/HeadCount/calculations/Calculations";
import { getCurrentProject } from "../ProjectServices";
import { calculateOverheads } from "../../AssumptionsGroup/Overheads/calculations/Calculations";
import { revenueAndCostState } from "../../SharedState/RevenueAndCostState";
import {
  copyPnlToHeadcounts,
  copyPnlToOverheads,
} from "./UpdatePowdrModelUtils";
import { overheadsConsolidationState } from "../../SharedState/OverheadsConsoliationState";
import { revenueAndCostConsolidationState } from "../../SharedState/RevenueAndCostConsolidationState";
import { headcountsConsolidationState } from "../../SharedState/HeadcountsConsolidationState";



/**
 * Used to recalculate after a P&L actual is entered
 *
 * Overheads or Headcounts need to be recalculated when an Actual in the P&L is entered.
 * And then anything that depends on the P&L values - such as Cashflow, Current Assets & Current Liabilities
 *
 * Steps:
 *
 * 1.  Using Celldata, find the Headcount or Overhead this row relates to (if any)
 * 2.  Copy over the values to the Headcount or Overhead - using the assumptionToCategoryMap
 * 3.  Recalculate and Save the Headcount or Overhead
 * 4.  Copy over the calculated values from the Headcounts or Overheads to the P&L
 * 5.  Recalculate the P&L with the new values in place
 * 6.  Save the P&L
 * 6.1 Recalculate the VAT package, because the overheads may have changed - no need if headcounts
 * 7   Recalculate Current Assets (Percentage of Overheads Assumptions)
 * 8   Recalculate Current Liabilities (passing in the new VatPackage and the Overheads)
 * 9.  Copy over the Current Liabilities & Current Asset values to the Balance Sheet
 * 10. Recalculate and Save the Balance Sheet
 * 11. Recalculate and Save the cashflow
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
const UpdatePowdrModelPnl = forwardRef((props, ref) => {

  //const setPnlAtom = useSetRecoilState(pnlState);
  const [pnlAtom, setPnlAtom] = useRecoilState(pnlState);
  //const [balanceAtom, setBalanceAtom] = useRecoilState(balanceSheetState);
  //const [cashflowAtom, setCashflowAtom] = useRecoilState(cashFlowState);

  const productsAtom = useRecoilValue(revenueAndCostState);
  const [overheadAtom, setOverheadAtom] = useRecoilState(overheadsState);
  const [headcountAtom, setHeadcountAtom] = useRecoilState(headcountsState);

  const currentAssetAtom = useRecoilValue(currentAssetsState);
  //const [currentAssetAtom, setCurrentAssetsAtom] = useRecoilState(currentAssetsState);
  //const [currentLiabilitiesAtom, setCurrentLiabilitiesAtom] = useRecoilState(currentLiabilitiesState);

  const [vatPackageAtom, setVatPackageAtom] = useRecoilState(vatPackageState);
  const vatRateAtom = useRecoilValue(vatState);

  const overheadsConsolidations = useRecoilValue(overheadsConsolidationState);
  const revenueAndCostConsolidation = useRecoilValue(revenueAndCostConsolidationState);
  const headcountConsolidation = useRecoilValue(headcountsConsolidationState);

  // const corporationTaxAtom = useRecoilValue(corporationTaxState);

  useImperativeHandle(ref, () => ({


    updatePowdrModelPnl(cellData,
                     pnlFromComponent,
                     assumptionToCategoryMapOverheads,
                     assumptionToCategoryMapHeadcounts) {

      const tick = performance.now();

      //1. Using cellData, find the Headcount or Overhead this row relates to (if any)
      if (!_.isNil(cellData.assumptions) && !_.isNil(cellData.assumptions[0].headcountId)) {

        let headCounts = structuredClone(headcountAtom);

        let pnlHeadcount = pnlFromComponent.pnLCategoryDtoList.find(category => category.headcountId === cellData.assumptions[0].headcountId);
        let headcount = headCounts.find(headcount_ => headcount_.headCountDto && headcount_.headCountDto.id === cellData.assumptions[0].headcountId);

        //2. Copy over the values to the Headcount or Overhead - using the assumptionToCategoryMap
        copyPnlToHeadcounts(pnlHeadcount, headcount);

        //3. Recalculate and Save the Headcount or Overhead
        calculateHeadCounts(headCounts, productsAtom, pnlAtom, setPnlAtom, revenueAndCostConsolidation, headcountConsolidation);

        setHeadcountAtom(structuredClone(headCounts));

        //4. Copy over the calculated values from the Headcounts or Overheads to the P&L
        updatePnl([headcount], assumptionToCategoryMapHeadcounts, "headCount", pnlFromComponent);

        //5. Recalculate the P&L with the new values in place
        calculatePnl(pnlFromComponent, getCurrentProject().id);

        //6. Save the P&L
        //setPnlAtom(pnlFromComponent);
      }

      let vatPackage = structuredClone(vatPackageAtom);
      let overheads = structuredClone(overheadAtom);

      //1. Using cellData, find the Headcount or Overhead this row relates to (if any)
      if (!_.isNil(cellData.assumptions) && !_.isNil(cellData.assumptions[0].overheadId)) {

        let pnlOverhead = pnlFromComponent.pnLCategoryDtoList.find(category => category.overheadId === cellData.assumptions[0].overheadId);
        let overhead = overheads.find(overhead_ => overhead_.overheadDto && overhead_.overheadDto.id === cellData.assumptions[0].overheadId);

        //2. Copy over the values to the Headcount or Overhead - using the assumptionToCategoryMap
        copyPnlToOverheads(pnlOverhead, overhead);

        //3. Recalculate and Save the Headcount or Overhead
        calculateOverheads(overheads, pnlFromComponent, productsAtom, headcountAtom, overheadsConsolidations)

        setOverheadAtom(structuredClone(overheads));

        //4. Copy over the calculated values from the Headcounts or Overheads to the P&L
        updatePnl([overhead], assumptionToCategoryMapOverheads, "overhead", pnlFromComponent);

        //5. Recalculate the P&L with the new values in place
        calculatePnl(pnlFromComponent, getCurrentProject().id);

        //6. Save the P&L
        //setPnlAtom(pnlFromComponent);


        //6.1 Recalculate and Save VatPackage
        vatPackage = refreshVatPackage(structuredClone(productsAtom), overheads, structuredClone(vatRateAtom), currentAssetAtom);
        setVatPackageAtom(vatPackage); //basically this just triggers a refresh
      }

      //if its a regular cell in the P&L then all we need do is run calculate and save the changes.
      if (!_.isNil(cellData.assumptions) && _.isNil(cellData.assumptions[0].overheadId)
        && _.isNil(cellData.assumptions[0].headcountId)) {

        //5. Recalculate the P&L with the new values in place
        calculatePnl(pnlFromComponent, getCurrentProject().id);

        //6. Save the P&L
        //setPnlAtom(structuredClone(pnlFromComponent));

      }

      //
      // //7. Recalculate Current Assets (Percentage of Overheads Assumptions)
      // let currentAssets = structuredClone(currentAssetAtom);
      // calculateCurrentAssetsNotAsync(currentAssets.currentAssetDataSetDtoList, vatPackage, pnlFromComponent)
      // setCurrentAssetsAtom(currentAssets)
      //
      // //8. Recalculate Current Liabilities (passing in the new VatPackage and the Overheads)
      // let currentLiabilities = structuredClone(currentLiabilitiesAtom);
      // calculateCurrentLiabilities(structuredClone(corporationTaxAtom),
      //   structuredClone(currentLiabilitiesAtom.currentLiabilityDataSetDtoList),
      //   structuredClone(productsAtom),
      //   overheads,
      //   vatPackage,
      //   currentLiabilities.allCashflowData,
      //   structuredClone(pnlFromComponent[0].pnLCategoryDtoList),
      //   currentLiabilities.currentLiabilityDataSetDtoList,
      //   getOpeningAgedCreditor(),
      //   true);
      // setCurrentLiabilitiesAtom(currentLiabilities);
      //
      // //9. Copy over the Current Liabilities & Current Asset values to the Balance Sheet
      // let balance = structuredClone(balanceAtom); //atoms are read only
      //
      // //copy Current Liabilities to the balance sheet
      // updateBalance(currentAssets.currentAssetDataSetDtoList,
      //   currentAssetsAssumptionsMap,
      //   "CURRENT_ASSETS",
      //   "currentAsset",
      //   balance[0]);
      //
      // //copy Current Liabilities to the balance sheet
      // updateBalance(currentLiabilities.currentLiabilityDataSetDtoList,
      //   currentLiabilitiesAssumptionsMap,
      //   "CURRENT_LIABILITIES",
      //   "currentLiability",
      //   balance[0]);
      //
      // updatePnlCorporationTax(currentLiabilities.currentLiabilityDataSetDtoList, pnlFromComponent[0])
      //
      //
      //
      // //10. Recalculate and Save the Balance Sheet
      // calculateBalanceSheet(balance[0], pnlFromComponent);
      // //setBalanceAtom(structuredClone(balance));
      //
      // //11. Recalculate and Save the cashflow
      // let cashflow = calculateCashflow(structuredClone(cashflowAtom[0]),
      //   balance[0],
      //   pnlFromComponent);
      //
      // setCashflowAtom([cashflow]);
      //
      // //Then recalculate the balance sheet with the NEW Cash At Bank values
      // calculateBalanceSheet(balance[0], pnlAtom);
      // setBalanceAtom(balance);

      //6. Save the P&L
      setPnlAtom(structuredClone(pnlFromComponent));

      const tock = performance.now();

      console.log(`Main full stack thread took ${tock - tick} ms`);

    }

  }));


  /**
   * Balance has an opening month value that occurs before the model begins, this method retrieves that for Trade creditors
   * @returns {*}
   */
  // function getOpeningAgedCreditor() {
  //   let openingAgedCreditorCategory = balanceAtom[0].balanceCategoryDtos.find(category => category.overrideName === 'Trade creditors')
  //   return openingAgedCreditorCategory.balanceMonthDtos[0].value;
  // }

  function updatePnl(assumptions, assumptionToCategoryMap, assumptionName, pnl) {

    _.forEach(assumptions, function(assumption, i) {

      let categoryName = assumptionToCategoryMap.get(assumption[assumptionName + 'Dto'].assumption);

      //todo - what is balanceCategory isn't found
      let pnlCategory = pnl.pnLCategoryDtoList.find(category => category.overrideName === assumption[assumptionName + 'Dto'].name && (category.headcountId === assumption[assumptionName + 'Dto'].id || category.overheadId === assumption[assumptionName + 'Dto'].id));

      let categoryToCopy;
      if (assumptionName === "headCount") {
        categoryToCopy = assumption[assumptionName + 'CategoryDtos'].find(category => category.name === categoryName);
      } else {
        categoryToCopy = assumption[assumptionName + 'CategoryDtoList'].find(category => category.name === categoryName || category.overrideName === categoryName)
      }

      //now copy over the values
      _.forEach(categoryToCopy[assumptionName + 'MonthDtoList'], function(monthDto, i) {
        if (i >= getCurrentProject().firstForecast) {
          pnlCategory.pnLMonthDtoList[i].value = monthDto.valueOverriden || monthDto.value;
        }
      });

    });

  }




  return (
    <div></div>
  );
});

export default UpdatePowdrModelPnl;
