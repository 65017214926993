import React, { useRef, useState, useEffect } from "react";
import {
  Button, Checkbox, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControl,
  FormControlLabel, FormGroup,
  FormLabel, Grid,
  Radio,
  RadioGroup,
  TextField
} from "@mui/material";
import { Formik } from "formik";
import useProject from "../../../../../hooks/useProject";
import axios from "axios";
import * as Yup from "yup";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { revenueAndCostState } from "../../../SharedState/RevenueAndCostState";
import { overheadsState } from "../../../SharedState/OverheadsState";
import { headcountsState } from "../../../SharedState/HeadcountsState";
import { currentAssetsState } from "../../../SharedState/CurrentAssetsState";
import UpdatePowdrModelGlobal from "../../../SharedComponents/UpdatePowdr/UpdatePowdrModelGlobal";
import { pnlState } from "../../../SharedState/PnLState";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import ReleaseSelect from "./ReleaseSelect";

function validateAccruals(currentAsset, assumption) {

  // Helper function to validate the existence of an entry in the release profile
  function validateReleaseProfile(items, releaseProfile, itemType) {
    for (let item of items) {
      const matchingProfile = releaseProfile.find(profile => profile.item === item);
      if (!matchingProfile) {
        window.alert(`You Must select a release profile for each Accrual item.`);
        return false;
      }
    }
    return true;
  }

  if (assumption === "ACCRUAL") {

    // Validate products
    if (!validateReleaseProfile(currentAsset.products, currentAsset.productsReleaseProfile, "product")) {
      return false;
    }

    // Validate overheads
    if (!validateReleaseProfile(currentAsset.overheads, currentAsset.overheadsReleaseProfile, "overhead")) {
      return false;
    }

    // Validate salaries
    if (!validateReleaseProfile(currentAsset.salaries, currentAsset.salariesReleaseProfile, "salary")) {
      return false;
    }
  }

  // If all validations pass
  return true;
}

function updateCurrentAsset(values, project, currentasset, loadData, setOpen, setSubmitting, setErrors, setShowSpinner, setCurrentAssets, pnlAtom, updatePowdrModelGlobalRef, setProductsAtom) {

  //validate Accruals
  let passed = validateAccruals(currentasset, values.assumptionFormat);
  if (passed === false) {return;}

  setShowSpinner(true)
  setSubmitting(true)
  try {
    axios
      .put("currentassets", {
        name: values.currentAssetName,
        project: project.id,
        assumption: values.assumptionFormat,
        percentageComplete: 0,
        overrideComplete: false,
        canDelete: values.canDelete,
        id: currentasset.id,
        directProductCosts: values.directProductCosts,
        products: currentasset.products || [],
        overheads: currentasset.overheads || [],
        salaries: currentasset.salaries || [],
        productsReleaseProfile: currentasset.productsReleaseProfile || [],
        overheadsReleaseProfile: currentasset.overheadsReleaseProfile || [],
        salariesReleaseProfile: currentasset.salariesReleaseProfile || [],
        comment: currentasset.comment,
      })
      .then(async function(response) {

        //update the atoms - Current Assets & Balance
        await axios.get("currentassets/" + project.id).then(function(response) {
          setCurrentAssets(response.data);
        });

        await axios.get("revenuecos/" + getCurrentProject().id).then(function(response) {
          setProductsAtom(response.data);
        });


        await axios.get("balance/" + project.id).then(function(response) {
          updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(response.data, pnlAtom);
        });

        //Need to pause for 5 seconds
        //Show the loading screen
        //Then close
        setTimeout(() => {
          // Code to run after 8 seconds
          setShowSpinner(false);
          setOpen(false);
        }, 8000); // Time is in milliseconds

        //setOpen(false);
      })
      .catch(function(error) {
        setShowSpinner(false);
        setOpen(false);
        console.log(error);
      });

    setSubmitting(false);
    //resetForm();
  } catch (error) {
    setShowSpinner(false);
    setErrors({ submit: error.message });
    setSubmitting(false);
  }
}


function EditCurrentAsset(props) {

  const updatePowdrModelGlobalRef = useRef();

  const { project } = useProject();
  const { currentAssetNames, loadData, currentasset, open, setOpen } = props;

  const [showSpinner, setShowSpinner] = React.useState(false);

  const [productsAtom, setProductsAtom] = useRecoilState(revenueAndCostState);
  const overheadsAtom = useRecoilValue(overheadsState);
  const salariesAtom = useRecoilValue(headcountsState);

  const [products, setProducts] = useState([]);
  const [overheads, setOverheads] = useState([]);
  const [salaries, setSalaries] = useState([]);

  const setCurrentAssets = useSetRecoilState(currentAssetsState);
  const pnlAtom = useRecoilValue(pnlState);

  const [triggerRefresh, setTriggerRefresh] = useState([]);

  useEffect(() => {

    setProducts(structuredClone(productsAtom));
    setOverheads(structuredClone(overheadsAtom));
    setSalaries(structuredClone(salariesAtom));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsAtom]);

  const validationSchema = Yup.object().shape({
    currentAssetName: Yup.string()
      .min(3, "Must be at least 3 characters")
      .max(150, "Must be less than 150 characters")
      .required("Required")
      .notOneOf(currentAssetNames, "Must have a unique name"),
  });

  const initialValues = {
    currentAssetName: currentasset?.name,
    assumptionFormat: currentasset?.assumption,
    balancePositionFlag: currentasset?.position,
    canDelete: currentasset?.canDelete,
    directProductCosts : currentasset?.directProductCosts,
  };

  const handleChangeProduct = (event) => {

    if (event.target.checked) {
      if (!currentasset.products.includes(parseInt(event.target.id))) {
        currentasset.products.push(parseInt(event.target.id))
      }
    } else {
      let productsChosenUpdate = currentasset.products.filter(function(e) { return e !== parseInt(event.target.id) })
      currentasset.products = productsChosenUpdate
    }

    setTriggerRefresh({
      ...triggerRefresh
    })
  };


  const handleChangeOverhead = (event) => {

    console.log('handleChangeOverhead', event.target.checked)

    if (event.target.checked) {
      if (!currentasset.overheads.includes(parseInt(event.target.id))) {
        currentasset.overheads.push(parseInt(event.target.id))
      }
    } else {
      let overheadsChosenUpdate = currentasset.overheads.filter(function(e) { return e !== parseInt(event.target.id) })
      currentasset.overheads = overheadsChosenUpdate
    }

    setTriggerRefresh({
      ...triggerRefresh
    })
  };

  const handleChangeSalary = (event) => {

    console.log('handleChangeSalary', event.target.checked)

    if (event.target.checked) {
      if (!currentasset.salaries.includes(parseInt(event.target.id))) {
        currentasset.salaries.push(parseInt(event.target.id))
      }
    } else {
      let productsChosenUpdate = currentasset.salaries.filter(function(e) { return e !== parseInt(event.target.id) })
      currentasset.salaries = productsChosenUpdate
    }

    setTriggerRefresh({
      ...triggerRefresh
    })

  };

  const handleSubmit = async (
    values,
    { setErrors, setSubmitting, resetForm }
  ) => {

    if (initialValues.assumptionFormat !== values.assumptionFormat) {

      if (window.confirm("WARNING: Ensure you export your data before modifying this assumption. \nPressing \"OK\" will RESET all entered numbers in this assumption table") === true) {
        updateCurrentAsset(values, project, currentasset, loadData, setOpen, setSubmitting, setErrors, setShowSpinner, setCurrentAssets, pnlAtom, updatePowdrModelGlobalRef, setProductsAtom);
      } else {
        setShowSpinner(false);
        resetForm();
        setOpen(false);
      }
    } else {
      updateCurrentAsset(values, project, currentasset, loadData, setOpen, setSubmitting, setErrors, setShowSpinner, setCurrentAssets, pnlAtom, updatePowdrModelGlobalRef, setProductsAtom);
    }

  };

  const removeReleaseProfile = (itemId, type, setProfile) =>  {

    if (type === "product") {
      let tempList = structuredClone(currentasset.productsReleaseProfile);

      // Find the index of the existing productId
      const existingIndex = tempList.findIndex(item => item.item === itemId);

      // If the productId exists, remove it from the list
      if (existingIndex !== -1) {
        tempList.splice(existingIndex, 1);
      }

      currentasset.productsReleaseProfile = tempList
    }

    if (type === "overhead") {
      let tempList = structuredClone(currentasset.overheadsReleaseProfile);

      // Find the index of the existing productId
      const existingIndex = tempList.findIndex(item => item.item === itemId);

      // If the productId exists, remove it from the list
      if (existingIndex !== -1) {
        tempList.splice(existingIndex, 1);
      }

      currentasset.overheadsReleaseProfile = tempList
    }

    if (type === "salary") {
      let tempList = structuredClone(currentasset.salariesReleaseProfile);

      // Find the index of the existing productId
      const existingIndex = tempList.findIndex(item => item.item === itemId);

      // If the productId exists, remove it from the list
      if (existingIndex !== -1) {
        tempList.splice(existingIndex, 1);
      }

      currentasset.salariesReleaseProfile = tempList
    }

    setProfile('')

  }

  const handleDropdownChange = (selectValue, itemId, type, setProfile) => {

    console.log(selectValue, itemId, type)

    if (type === "product") {

      let tempList = structuredClone(currentasset.productsReleaseProfile);

      // Find the index of the existing productId
      const existingIndex = tempList.findIndex(item => item.item === itemId);

      // If the productId exists, remove it from the list
      if (existingIndex !== -1) {
        tempList.splice(existingIndex, 1);
      }

      tempList.push({ item: itemId, linkTypeEnum: 'PRODUCT', releaseProfile: selectValue  });

      //if there is no record then we add it
      currentasset.productsReleaseProfile = tempList
    }

    if (type === "overhead") {

      let tempList = structuredClone(currentasset.overheadsReleaseProfile);

      // Find the index of the existing productId
      const existingIndex = tempList.findIndex(item => item.item === itemId);

      // If the productId exists, remove it from the list
      if (existingIndex !== -1) {
        tempList.splice(existingIndex, 1);
      }

      tempList.push({ item: itemId, linkTypeEnum: 'OVERHEAD', releaseProfile: selectValue  });

      //if there is no record then we add it
      currentasset.overheadsReleaseProfile = tempList
    }

    if (type === "salary") {

      let tempList = structuredClone(currentasset.salariesReleaseProfile);

      // Find the index of the existing productId
      const existingIndex = tempList.findIndex(item => item.item === itemId);

      // If the productId exists, remove it from the list
      if (existingIndex !== -1) {
        tempList.splice(existingIndex, 1);
      }

      tempList.push({ item: itemId, linkTypeEnum: 'SALARY', releaseProfile: selectValue  });

      //if there is no record then we add it
      currentasset.salariesReleaseProfile = tempList
    }

    setProfile(selectValue);
  };

  return (
    <React.Fragment>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (

              <Dialog
                open={open}
                onClose={(event, reason) => {
                  if (reason !== 'backdropClick') {
                    setOpen(false)
                  }
                }}
                aria-labelledby="form-dialog-title"
                fullWidth={["PERCENTAGE_OF_CERTAIN_OVERHEADS", "PERCENTAGE_OF_CERTAIN_REVENUE", "ACCRUAL"].includes(values.assumptionFormat)}
                maxWidth={"xl"}
              >
                <form onSubmit={handleSubmit}>
                  <DialogTitle id="form-dialog-title">Edit Current Asset</DialogTitle>
                  <DialogContent sx={{ paddingBottom: 0 }}>

                    <Grid container spacing={3}>
                      <Grid item xs>

                    <DialogContentText>
                      Update the name of the current asset you wish to edit.
                    </DialogContentText>
                    <TextField
                      error={Boolean(touched.currentAssetName && errors.currentAssetName)}
                      name="currentAssetName"
                      autoFocus
                      placeholder={"Current Asset Name"}
                      margin="dense"
                      id="currentAssetName"
                      label="Current Asset Name"
                      value={values.currentAssetName}
                      onChange={handleChange}
                      disabled={!values.canDelete}
                      type="text"
                      helperText={touched.currentAssetName && errors.currentAssetName}
                      fullWidth
                    />

                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Assumption Format
                    </FormLabel>
                    <RadioGroup
                      list="true"
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="assumptionFormat"
                      value={values.assumptionFormat}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        key="SIMPLE_CORKSCREW"
                        value="SIMPLE_CORKSCREW"
                        control={<Radio />}
                        label="Simple Corkscrew"
                      />
                      <FormControlLabel
                        key="PERCENTAGE_OF_DEBTORS"
                        value="PERCENTAGE_OF_DEBTORS"
                        control={<Radio />}
                        label="Percentage of debtors"
                      />
                      <FormControlLabel
                        key="PERCENTAGE_OF_CERTAIN_OVERHEADS"
                        value="PERCENTAGE_OF_CERTAIN_OVERHEADS"
                        control={<Radio />}
                        label="Percentage of overheads"
                      />
                      <FormControlLabel
                        key="PERCENTAGE_OF_CERTAIN_REVENUE"
                        value="PERCENTAGE_OF_CERTAIN_REVENUE"
                        disabled={currentasset?.assumption === "LOAN_ASSET"}
                        control={<Radio />}
                        label="Percentage of revenue"
                      />
                      <FormControlLabel
                        key="LOAN_ASSET"
                        value="LOAN_ASSET"
                        control={<Radio />}
                        label="Loan"
                      />
                      <FormControlLabel
                        key="ACCRUAL"
                        value="ACCRUAL"
                        control={<Radio />}
                        label="Accrued Income"
                      />
                    </RadioGroup>

                      </Grid>


                      {values.assumptionFormat === "ACCRUAL" && <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormLabel key={Math.random() * 10000000} component="legend">Products Revenue To include in percentage of accrual
                            calculation</FormLabel>
                          <FormGroup>


                            {products &&
                              products.map((product) => (
                                <Grid container spacing={2} alignItems="center" sx={{paddingBottom: 3}}>
                                  <Grid item xs={9}>
                                    <FormControlLabel
                                      key={Math.random() * 10000000}
                                      control={
                                        <Checkbox defaultChecked={currentasset.products.includes(product.productDto.id)}
                                                  onChange={handleChangeProduct}
                                                  name={product.productDto.name} id={product.productDto.id + ""} />
                                      }
                                      label={product.productDto.name}
                                    />
                                  </Grid>

                                  <Grid item xs={3}>
                                    <ReleaseSelect hidden={!currentasset.products.includes(product.productDto.id)}
                                                   handleChangeOverride={handleDropdownChange}
                                                   itemId={product.productDto.id}
                                                   type={'product'}
                                                   key={Math.random() * 10000000}
                                                   initialValue={currentasset.productsReleaseProfile.find(link => link.item === product.productDto.id)}
                                                   removeReleaseProfile={removeReleaseProfile}/>
                                  </Grid>
                                </Grid>
                              ))}


                            <FormLabel component="legend">Overheads To include in percentage of accrual
                              calculation</FormLabel>


                            {overheads &&
                              overheads.map((overhead) => (
                                <Grid container spacing={2} alignItems="center" sx={{paddingBottom: 3}}>
                                  <Grid item xs={9}>
                                    <FormControlLabel
                                      key={Math.random() * 10000000}
                                      control={
                                        <Checkbox defaultChecked={currentasset.overheads.includes(overhead.overheadDto.id)}
                                                  onChange={handleChangeOverhead}
                                                  name={overhead.overheadDto.name} id={overhead.overheadDto.id + ""} />
                                      }
                                      label={overhead.overheadDto.name}
                                    />
                                  </Grid>

                                  <Grid item xs={3}>
                                    <ReleaseSelect hidden={!currentasset.overheads.includes(overhead.overheadDto.id)}
                                                   handleChangeOverride={handleDropdownChange}
                                                   itemId={overhead.overheadDto.id}
                                                   key={Math.random() * 10000000}
                                                   type={'overhead'}
                                                   initialValue={currentasset.overheadsReleaseProfile.find(link => link.item === overhead.overheadDto.id)}
                                                   removeReleaseProfile={removeReleaseProfile}/>
                                  </Grid>
                                </Grid>
                              ))}

                            <FormLabel component="legend">Salaries To include in percentage of accrual
                              calculation</FormLabel>


                            {salaries &&
                              salaries.map((salary) => (
                                <Grid container spacing={2} alignItems="center" sx={{paddingBottom: 3}}>
                                  <Grid item xs={9}>
                                    <FormControlLabel
                                      key={Math.random() * 10000000}
                                      control={
                                        <Checkbox defaultChecked={currentasset.salaries.includes(salary.headCountDto.id)} onChange={handleChangeSalary}
                                                  name={salary.headCountDto.name} id={salary.headCountDto.id + ""} />
                                      }
                                      label={salary.headCountDto.name}
                                    />
                                  </Grid>

                                  <Grid item xs={3}>
                                    <ReleaseSelect hidden={!currentasset.salaries.includes(salary.headCountDto.id)}
                                                   handleChangeOverride={handleDropdownChange}
                                                   itemId={salary.headCountDto.id}
                                                   key={Math.random() * 10000000}
                                                   type={'salary'}
                                                   initialValue={currentasset.salariesReleaseProfile.find(link => link.item === salary.headCountDto.id)}
                                                   removeReleaseProfile={removeReleaseProfile}/>
                                  </Grid>
                                </Grid>
                              ))}


                          </FormGroup>

                        </FormControl>
                      </Grid>
                      }


                      {values.assumptionFormat === "PERCENTAGE_OF_CERTAIN_REVENUE" && <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormLabel component="legend">Products To include in percentage of revenue
                            calculation</FormLabel>
                          <FormGroup>

                            {products &&
                              products.map((product) => (
                                <FormControlLabel key={Math.random() * 10000000}
                                                  control={
                                                    <Checkbox defaultChecked={currentasset.products.includes(product.productDto.id)}
                                                              onChange={handleChangeProduct}
                                                              name={product.productDto.name} id={product.productDto.id + ""} />
                                                  }
                                                  label={product.productDto.name}
                                />

                              ))}

                          </FormGroup>

                        </FormControl>
                      </Grid>
                      }



                      {values.assumptionFormat === "PERCENTAGE_OF_CERTAIN_OVERHEADS" && <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormLabel component="legend">Expenses To include in percentage of overheads
                            calculation</FormLabel>
                          <FormGroup>

                            {overheads &&
                              overheads.map((overhead) => (
                                <FormControlLabel key={Math.random() * 10000000}
                                  control={
                                    <Checkbox defaultChecked={currentasset.overheads.includes(overhead.overheadDto.id)}
                                              onChange={handleChangeOverhead}
                                              name={overhead.overheadDto.name} id={overhead.overheadDto.id + ""} />
                                  }
                                  label={overhead.overheadDto.name}
                                />

                              ))}

                            <FormControlLabel key={Math.random() * 10000000}
                                              control={
                                                <Checkbox defaultChecked={values.directProductCosts}

                                                          onChange={handleChange}
                                                          name={"directProductCosts"}/>
                                              }
                                              label={"Direct Product Costs"}
                            />

                          </FormGroup>

                        </FormControl>
                      </Grid>
                      }

                      {values.assumptionFormat === "PERCENTAGE_OF_CERTAIN_OVERHEADS" && <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormLabel component="legend">Salaries To include in percentage of overheads
                            calculation</FormLabel>
                          <FormGroup>

                            {salaries &&
                              salaries.map((salary) => (
                                <FormControlLabel key={Math.random() * 10000000}
                                                  control={
                                                    <Checkbox defaultChecked={currentasset.salaries.includes(salary.headCountDto.id)} onChange={handleChangeSalary}
                                                              name={salary.headCountDto.name} id={salary.headCountDto.id + ""} />
                                                  }
                                                  label={salary.headCountDto.name}
                                />

                              ))}

                          </FormGroup>

                        </FormControl>
                      </Grid>
                      }

                    </Grid>


                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary" disabled={showSpinner}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      autoFocus
                      disabled={isSubmitting}
                    >
                      {showSpinner ? <CircularProgress size={24} /> : 'Update'}
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
          )}
        </Formik>
      <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>
    </React.Fragment>
  );;
}

export default EditCurrentAsset;
