import React, { useState, useEffect } from "react";
import PageHeader from "../SharedComponents/PageHeader";
import Divider from "@mui/material/Divider";
import ResetPassword from "./components/ResetPassword";
import EditUserForm from "./components/EditUserForm";
import axios from "axios";
import SelectUserToEdit from "./components/SelectUserToEdit";

function EditUser() {

  const [users, setUsers] = useState([]);
  const [userToEdit, setUserToEdit] = useState();
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    axios.get('company/').then(function(response) {
      setCompanies(response.data);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    axios.get('security/users').then(function (response) {
      setUsers(response.data.users);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>

      <PageHeader pageName={"Edit User"} parentName={"Powdr Admin"}/>

      <Divider my={6} />

      <SelectUserToEdit setUserToEdit={setUserToEdit} companies={companies} users={users}/>

      <EditUserForm user={userToEdit} companies={companies}/>

      <ResetPassword user={userToEdit}/>

    </React.Fragment>
  );
}

export default EditUser;
