import React from "react";
import PageHeader from "../SharedComponents/PageHeader";
import Divider from "@mui/material/Divider";
import ResetPassword from "./components/ResetPassword";
import UserDetails from "./components/UserDetails";
import useAuth from "../../../hooks/useAuth";

function YourAccount() {

  const { user } = useAuth();

  return (
    <React.Fragment>

      <PageHeader pageName={"Your Account"} parentName={"Company"}/>

      <Divider my={6} />

      <UserDetails user={user}/>

      <ResetPassword user={user}/>

    </React.Fragment>
  );
}

export default YourAccount;
