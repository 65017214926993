import _ from "lodash";
import { getCurrentProject, getProjectIdNotAsync } from "../ProjectServices";
import {
  currentAssetsAssumptionsMap, currentLiabilitiesAssumptionsMap, debtAssumptionsMap, equityAssumptionsMap,
  fixedAssetAssumptionsMap,
  headcountAssumptionMap,
  overheadAssumptionMap
} from "../AssumptionMaps";
import { calculateFixedAssets } from "../../AssumptionsGroup/FixedAssets/calculations/Calculations";
import { calculateCurrentAssets } from "../../AssumptionsGroup/CurrentAssets/calculations/Calculations";
import { calculateCurrentLiabilities } from "../../AssumptionsGroup/CurrentLiabilities/calculations/Calculations";
import { calculateDebt } from "../../AssumptionsGroup/Debt/calculations/Calculations";
import { calculateEquity } from "../../AssumptionsGroup/Equity/calculations/Calculations";


/**
 * Copies all P&L overhead actuals to the overhead assumptions
 *
 * @param pnl
 * @param overheads
 */
export function copyAllPnlToOverheads(pnl, overheads) {

  for (let overhead of overheads) {

    let pnlOverhead = pnl.pnLCategoryDtoList.find(category => category.overheadId === overhead.overheadDto.id);

    copyPnlToOverheads(pnlOverhead, overhead)
  }

}

/**
 * Method take a pnl category (of type overhead)
 * and copies the Actuals (only) to the related overhead in the overheads
 *
 * @param pnlOverhead
 * @param overheads
 */
export function copyPnlToOverheads(pnlOverhead, overhead) {

  let categoryName = overheadAssumptionMap.get(overhead.overheadDto.assumption);

  //find the overhead category we want to copy over too.
  let overheadCategory = overhead.overheadCategoryDtoList.find(category => category.name === categoryName || category.overrideName === categoryName)

  if (overheadCategory !== undefined && pnlOverhead !== undefined) {

    //loop through the pnlOverhead monthDtos
    _.forEach(pnlOverhead.pnLMonthDtoList, function(pnlMonthDto, i) {

      //only do this for the Actuals (one exception is ANNUAL_GROWTH_RATES, which always has a minimum of 12 actuals)
      if (i < getNumberOfActuals(overhead.overheadDto.assumption)) {

        //copy the value to the overhead
        overheadCategory.overheadMonthDtoList[i].value = pnlMonthDto.value;
      }

    });
  }

}

function getNumberOfActuals(assumption) {

  let firstForecast = Number(getCurrentProject().firstForecast);

  if (assumption === "ANNUAL_GROWTH_RATE" && firstForecast < 12) {
    return 12;
  }


  return getCurrentProject().firstForecast
}

/**
 * Copies all P&L headcount actuals to the headcount assumptions
 *
 * @param pnl
 * @param headcounts
 */
export function copyAllPnlToHeadcounts(pnl, headcounts) {

  for (let headcount of headcounts) {

    let pnlOverhead = pnl.pnLCategoryDtoList.find(category => category.headcountId === headcount.headCountDto.id);

    copyPnlToHeadcounts(pnlOverhead, headcount)
  }

}

export function copyPnlToHeadcounts(pnlHeadcount, headcount) {

  let categoryName = headcountAssumptionMap.get(headcount.headCountDto.assumption);

  //find the headcount category we want to copy over too.
  let headcountCategory = headcount.headCountCategoryDtos.find(category => category.name === categoryName);

  if (headcountCategory !== undefined && pnlHeadcount !== undefined) {
    //loop through the pnlOverhead monthDtos
    _.forEach(pnlHeadcount.pnLMonthDtoList, function(pnlMonthDto, i) {

      //only do this for the Actuals
      if (i < getNumberOfActuals(headcount.headCountDto.assumption)) {

        //copy the value to the overhead
        headcountCategory.headCountMonthDtoList[i].value = pnlMonthDto.value;
      }

    });
  }

}


export function updatePnlCorporationTax(currentLiabilities, pnl) {

  let project = getProjectIdNotAsync();

  if (project.projectType !== "PNL") { //for P&L projects, corporation tax is manually entered

    let clCorporationTax = currentLiabilities
      .find(currentLiability => currentLiability.currentLiabilityDto.assumption === 'CORPORATION_TAX');

    let pnlCorporationTax = pnl.pnLCategoryDtoList.find(category => category.name === 'Corporation Tax');

    _.forEach(pnlCorporationTax.pnLMonthDtoList, function(monthDto, i) {

      if (i >= getCurrentProject().firstForecast) {
        monthDto.value = clCorporationTax.currentLiabilityCategoryDtos[9].currentLiabilityMonthDtos[i].value;
      }

    });
  }

}

export function updatePnl(assumptions, assumptionToCategoryMap, assumptionName, pnl) {

  _.forEach(assumptions, function(assumption, i) {

    let categoryName = assumptionToCategoryMap.get(assumption[assumptionName + 'Dto'].assumption);

    //todo - what is balanceCategory isn't found
    let pnlCategory = pnl.pnLCategoryDtoList.find(category => category.overrideName === assumption[assumptionName + 'Dto'].name && (category.headcountId === assumption[assumptionName + 'Dto'].id || category.overheadId === assumption[assumptionName + 'Dto'].id));

    let categoryToCopy;
    if (assumptionName === "headCount") {
      categoryToCopy = assumption[assumptionName + 'CategoryDtos'].find(category => category.name === categoryName);
    } else {
      categoryToCopy = assumption[assumptionName + 'CategoryDtoList'].find(category => category.name === categoryName || category.overrideName === categoryName)
    }

    //now copy over the values
    _.forEach(categoryToCopy[assumptionName + 'MonthDtoList'], function(monthDto, i) {
      pnlCategory.pnLMonthDtoList[i].value = monthDto.valueOverriden || monthDto.value;

      /**
       * For some unknown reason that I can't narrow down, the pnl actuals for HEADCOUNT_DIVISIONAL_CONSOLIDATION
       * are being set to ENABLED, this is correct for every assumption type, except this one.
       *
       * So this patch forces them back to being calculated. It's a hack I know, but I've ran out of time to figure this one
       * out and am adding it to the pile of technical debt.
       *
       */
      if (assumption[assumptionName + 'Dto'].assumption === "HEADCOUNT_DIVISIONAL_CONSOLIDATION") {
        pnlCategory.pnLMonthDtoList[i].cellType = "CALCULATED";
      }

    });

  });

}

export function updateBalance(assumptions, assumptionToCategoryMap, balanceType, assumptionName, balance) {

  _.forEach(assumptions, function(assumption, i) {

    let categoryName = assumptionToCategoryMap.get(assumption[assumptionName + 'Dto'].assumption);

    //todo - what is balanceCategory isn't found
    let balanceCategory = balance.balanceCategoryDtos.find(category => category.overrideName === assumption[assumptionName + 'Dto'].name && category.balanceType === balanceType);

    let categoryToCopy = assumption[assumptionName + 'CategoryDtos'].find(category => category.name === categoryName);

    if (categoryToCopy !== undefined) {
      //now copy over the values
      _.forEach(categoryToCopy[assumptionName + 'MonthDtos'], function(monthDto, i) {
        balanceCategory.balanceMonthDtos[i + 1].value = monthDto.value;
      });
    }

  });

}



export function refreshAndCalculateFixedAssets(balance, fixedAssets) {

    for (let fixedAsset of fixedAssets) {

      let balanceFixedAsset = balance.balanceCategoryDtos.find(category => category.balanceType === "FIXED_ASSETS" && category.balanceExternalId === fixedAsset.fixedAssetDto.id);

      copyBalanceToFixedAssets(balanceFixedAsset, fixedAsset, fixedAssetAssumptionsMap);

    }

    calculateFixedAssets(fixedAssets);

    updateBalance(fixedAssets,
      fixedAssetAssumptionsMap,
      "FIXED_ASSETS",
      "fixedAsset",
      balance);

}

export function copyBalanceToFixedAssets(balanceItem, fixedAsset) {

  let categoryName = fixedAssetAssumptionsMap.get(fixedAsset.fixedAssetDto.assumption);

  //find the overhead category we want to copy over too.
  let fixedAssetCategory = fixedAsset.fixedAssetCategoryDtos.find(category => category.name === categoryName)

  //loop through the pnlOverhead monthDtos
  _.forEach(balanceItem.balanceMonthDtos, function(monthDto, i) {

    if (i !== 0) {
      //only do this for the Actuals
      if (i <= getCurrentProject().firstBalanceForecast) {

        //copy the value to the overhead
        fixedAssetCategory.fixedAssetMonthDtos[i - 1].value = monthDto.value;
      }
    }

  });
}

export function refreshAndCalculateCurrentAssets(balance, currentAssets, vatPackage, pnl, releaseProfileAtom, producsAtom) {

  for (let currentAsset of currentAssets) {

    let balanceFixedAsset = balance.balanceCategoryDtos.find(category => category.balanceType === "CURRENT_ASSETS" && category.balanceExternalId === currentAsset.currentAssetDto.id);

    copyBalanceToCurrentAssets(balanceFixedAsset, currentAsset);

  }

  calculateCurrentAssets(currentAssets, vatPackage, pnl, releaseProfileAtom, producsAtom)

  updateBalance(currentAssets,
    currentAssetsAssumptionsMap,
    "CURRENT_ASSETS",
    "currentAsset",
    balance);
}

export function copyBalanceToCurrentAssets(balanceItem, currentAsset) {

  let categoryName = currentAssetsAssumptionsMap.get(currentAsset.currentAssetDto.assumption);

  //find the overhead category we want to copy over too.
  let category = currentAsset.currentAssetCategoryDtos.find(category => category.name === categoryName)

  if (category !== undefined) {

    //loop through the pnlOverhead monthDtos
    _.forEach(balanceItem.balanceMonthDtos, function(monthDto, i) {

      if (i !== 0) {
        //only do this for the Actuals
        if (i <= getCurrentProject().firstBalanceForecast) {

          //copy the value to the overhead
          category.currentAssetMonthDtos[i - 1].value = monthDto.value;
        }
      }

    });
  }
}


export function refreshAndCalculateCurrentLiabilities(balance, currentLiabilities, vatPackage, pnl, products, overheads, corporationTax, cashflow, releaseProfileAtom, productsAtom) {

  for (let currentLiability of currentLiabilities) {

    let balanceFixedAsset = balance.balanceCategoryDtos.find(category => category.balanceType === "CURRENT_LIABILITIES" && category.balanceExternalId === currentLiability.currentLiabilityDto.id);

    copyBalanceToCurrentLiabilities(balanceFixedAsset, currentLiability);

  }

  calculateCurrentLiabilities(corporationTax,
    currentLiabilities,
    vatPackage,
    cashflow,
    structuredClone(pnl.pnLCategoryDtoList),
    releaseProfileAtom,
    productsAtom
    );

  updateBalance(currentLiabilities,
    currentLiabilitiesAssumptionsMap,
    "CURRENT_LIABILITIES",
    "currentLiability",
    balance);

  updatePnlCorporationTax(currentLiabilities, pnl)
}

export function copyBalanceToCurrentLiabilities(balanceItem, currentLiability) {

  let categoryName = currentLiabilitiesAssumptionsMap.get(currentLiability.currentLiabilityDto.assumption);

  //find the overhead category we want to copy over too.
  let category = currentLiability.currentLiabilityCategoryDtos.find(category => category.name === categoryName)

  //loop through the pnlOverhead monthDtos
  _.forEach(balanceItem.balanceMonthDtos, function(monthDto, i) {

    if (i !== 0) {
      //only do this for the Actuals
      if (i <= getCurrentProject().firstBalanceForecast) {

        //copy the value to the overhead
        category.currentLiabilityMonthDtos[i - 1].value = monthDto.value;
      }
    }

  });
}


export function refreshAndCalculateDebts(balance, debts) {

  for (let debt of debts) {

    let balanceFixedAsset = balance.balanceCategoryDtos.find(category => category.balanceType === "DEBT" && category.balanceExternalId === debt.debtDto.id);

    copyBalanceToDebts(balanceFixedAsset, debt);

  }

  calculateDebt(debts, structuredClone(balance));

  updateBalance(debts,
    debtAssumptionsMap,
    "DEBT",
    "debt",
    balance);

}


export function copyBalanceToDebts(balanceItem, debt) {

  let categoryName = debtAssumptionsMap.get(debt.debtDto.assumption);

  //find the overhead category we want to copy over too.
  let debtCategory = debt.debtCategoryDtos.find(category => category.name === categoryName)

  //loop through the pnlOverhead monthDtos
  _.forEach(balanceItem.balanceMonthDtos, function(monthDto, i) {

    if (i !== 0) {
      //only do this for the Actuals
      if (i <= getCurrentProject().firstBalanceForecast) {

        //copy the value to the overhead
        debtCategory.debtMonthDtos[i - 1].value = monthDto.value;
      }
    }

  });
}


export function refreshAndCalculateEquity(balance, equities) {

  for (let equity of equities) {

    let balanceFixedAsset = balance.balanceCategoryDtos.find(category => category.balanceType === "EQUITY" && category.balanceExternalId === equity.equityDto.id);

    copyBalanceToEquity(balanceFixedAsset, equity);

  }

  calculateEquity(equities);

  updateBalance(equities,
    equityAssumptionsMap,
    "EQUITY",
    "equity",
    balance);

}

export function copyBalanceToEquity(balanceItem, equity) {

  if (_.isNil(equity)){
    return;
  }

  let categoryName = equityAssumptionsMap.get(equity.equityDto.assumption);

  //find the overhead category we want to copy over too.
  let equityCategory = equity.equityCategoryDtos.find(category => category.name === categoryName)

  //loop through the pnlOverhead monthDtos
  _.forEach(balanceItem.balanceMonthDtos, function(monthDto, i) {

    if (i !== 0) {
      //only do this for the Actuals
      if (i <= getCurrentProject().firstBalanceForecast) {

        //copy the value to the overhead
        equityCategory.equityMonthDtos[i - 1].value = monthDto.value;
      }
    }

  });
}