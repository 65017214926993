import React, { useState } from "react";
import {
  Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField
} from "@mui/material";
import { Formik } from "formik";
import useProject from "../../../../../hooks/useProject";
import axios from "axios";
import * as Yup from "yup";
import { useSetRecoilState } from "recoil";
import { balanceSheetState } from "../../../SharedState/BalanceSheetState";

function updateEquity(values, project, equity, loadData, setOpen, setSubmitting, setErrors, setEquities, setBalanceSheet, setShowSpinner) {

  setShowSpinner(true)

  try {
    axios
      .put("equity", {
        name: values.equityName,
        project: project.id,
        assumption: values.assumptionFormat,
        percentageComplete: 0,
        overrideComplete: false,
        canDelete: values.canDelete,
        id: equity.id,
        comment: equity.comment
      })
      .then(async function(response) {

        //update the atoms - Equity & Balance
        await axios.get("equity/" + project.id).then(function(response) {
          setEquities(response.data);
        });
        await axios.get("balance/" + project.id).then(function(response) {
          setBalanceSheet(response.data);
        });

        setShowSpinner(true)
        setOpen(false);
      })
      .catch(function(error) {
        setShowSpinner(true)
        console.log(error);
      });

    setSubmitting(false);
    //resetForm();
  } catch (error) {
    setErrors({ submit: error.message });
    setSubmitting(false);
  }
}

function EditEquity(props) {
  const { project } = useProject();
  const { loadData, equity, open, setOpen } = props;

  const setBalanceSheet = useSetRecoilState(balanceSheetState);
  const [equityAtom, setEquities] = useState([]);
  const [showSpinner, setShowSpinner] = React.useState(false);

  const validationSchema = Yup.object().shape({
    equityName: Yup.string()
      .min(3, "Must be at least 3 characters")
      .max(150, "Must be less than 150 characters")
      .required("Required")
      .notOneOf(equityAtom.filter(equity_ => equity_?.equityDto?.name !== equity?.name).map(function(i) {
        return i.equityDto.name;
      }), "Must have a unique name"),
  });

  const initialValues = {
    equityName: equity?.name,
    assumptionFormat: equity?.assumption,
    balancePositionFlag: equity?.position,
    canDelete: equity?.canDelete,
  };

  const handleSubmit = async (
    values,
    { setErrors, setSubmitting, resetForm }
  ) => {

    if (initialValues.assumptionFormat !== values.assumptionFormat) {
      if (window.confirm("WARNING: Ensure you export your data before modifying this assumption. \nPressing \"OK\" will RESET all entered numbers in this assumption table") === true) {
        updateEquity(values, project, equity, loadData, setOpen, setSubmitting, setErrors, setEquities, setBalanceSheet, setShowSpinner);
      } else {
        resetForm();
        setOpen(false);
      }
    } else {
      updateEquity(values, project, equity, loadData, setOpen, setSubmitting, setErrors, setEquities, setBalanceSheet, setShowSpinner);
    }

  };


  return (
    <React.Fragment>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (

              <Dialog
                open={open}
                onClose={(event, reason) => {
                  if (reason !== 'backdropClick') {
                    setOpen(false)
                  }
                }}
                aria-labelledby="form-dialog-title"
              >
                <form onSubmit={handleSubmit}>
                  <DialogTitle id="form-dialog-title">Edit Equity</DialogTitle>
                  <DialogContent sx={{ paddingBottom: 0 }}>
                    <DialogContentText>
                      Update the name of the current equity you wish to edit.
                    </DialogContentText>
                    <TextField
                      error={Boolean(touched.equityName && errors.equityName)}
                      name="equityName"
                      autoFocus
                      placeholder={"Equity Name"}
                      margin="dense"
                      id="equityName"
                      label="Equity Name"
                      value={values.equityName}
                      onChange={handleChange}
                      disabled={!values.canDelete}
                      type="text"
                      helperText={touched.equityName && errors.equityName}
                      fullWidth
                    />

                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Assumption Format
                    </FormLabel>
                    <RadioGroup
                      list="true"
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="assumptionFormat"
                      value={values.assumptionFormat}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="SIMPLE_CORKSCREW"
                        control={<Radio />}
                        label="Simple Corkscrew"
                      />

                    </RadioGroup>

                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      autoFocus
                      disabled={isSubmitting}
                    >
                      {showSpinner ? <CircularProgress size={24} /> : 'Update'}
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
          )}
        </Formik>
    </React.Fragment>
  );;
}

export default EditEquity;
