import React, { useEffect, useState, useRef }  from "react";
import {
  Button, Checkbox, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControl,
  FormControlLabel, FormGroup,
  FormLabel, Grid,
  Radio,
  RadioGroup,
  TextField
} from "@mui/material";
import { Formik} from "formik";
import useProject from "../../../../../hooks/useProject";
import axios from "axios";
import * as Yup from "yup";
import { useRecoilState, useRecoilValue } from "recoil";
import { debtsState } from "../../../SharedState/DebtsState";
import UpdatePowdrModelGlobal from "../../../SharedComponents/UpdatePowdr/UpdatePowdrModelGlobal";
import { pnlState } from "../../../SharedState/PnLState";
import { balanceSheetState } from "../../../SharedState/BalanceSheetState";


function updateDebt(values, project, debt, setOpen, setSubmitting, setErrors, setDebts, setShowSpinner, pnlAtom, updatePowdrModelGlobalRef, ablAssetsChosen) {

  setShowSpinner(true)

  try {
    axios
      .put("debt", {
        name: values.debtName,
        project: project.id,
        assumption: values.assumptionFormat,
        percentageComplete: 0,
        overrideComplete: false,
        canDelete: values.canDelete,
        id: debt.id,
        pikOnPik: values.pikOnPik,
        debts: debt.debts,
        ablAssets : [...ablAssetsChosen],
        comment: debt.comment
      })
      .then(async function(response) {

        //update the atoms - Debt & Balance
        await axios.get("debt/" + project.id).then(function(response) {
          setDebts(response.data);
        });
        await axios.get("balance/" + project.id).then(function(response) {
          updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(response.data, pnlAtom);
        });

        setShowSpinner(false);
        setOpen(false);
      })
      .catch(function(error) {
        setShowSpinner(false);
        console.log(error);
      });

    setSubmitting(false);
    //resetForm();
  } catch (error) {
    setErrors({ submit: error.message });
    setSubmitting(false);
  }
}

function EditDebt(props) {

  const updatePowdrModelGlobalRef = useRef();

  const { project } = useProject();
  const { debt, open, setOpen } = props;

  const [debts, setDebts] = useState([]);
  const [debtsAtom, setDebtsAtom] = useRecoilState(debtsState);
  const pnlAtom = useRecoilValue(pnlState);

  const [showSpinner, setShowSpinner] = React.useState(false);
  const balanceSheetAtom = useRecoilValue(balanceSheetState);
  const [ablAssetsChosen, setAblAssetsChosen] = useState([]);

  useEffect(() => {

    setDebts(structuredClone(debtsAtom));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debtsAtom]);

  useEffect(() => {

    setAblAssetsChosen([...debt.ablAssets]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setOpen]);

  const validationSchema = Yup.object().shape({
    debtName: Yup.string()
      .min(3, "Must be at least 3 characters")
      .max(150, "Must be less than 150 characters")
      .required("Required")
      .notOneOf(debtsAtom.filter(debt_ => debt_?.debtDto?.name !== debt?.name).map(function(i) {
        return i.debtDto.name;
      }), "Must have a unique name"),
  });

  const initialValues = {
    debtName: debt?.name,
    assumptionFormat: debt?.assumption,
    balancePositionFlag: debt?.position,
    canDelete: debt?.canDelete,
    pikOnPik: debt?.pikOnPik,
  };

  const handleChangeAblFacility = (event) => {

    let chosenAssets = new Set([...ablAssetsChosen]);

    if (event.target.checked){
      chosenAssets.add(Number.parseInt(event.target.id))
    } else if (event.target.checked === false) {
      chosenAssets.delete(Number.parseInt(event.target.id))
    }

    setAblAssetsChosen(chosenAssets);
  }

  const handleChangeDebt = (event) => {


    if (event.target.checked) {
      if (!debt.debts.includes(parseInt(event.target.id))) {
        debt.debts.push(parseInt(event.target.id))
      }
    } else {
      let debtsChosenUpdate = debt.debts.filter(function(e) { return e !== parseInt(event.target.id) })
      debt.debts = debtsChosenUpdate
    }


  };

  const handleSubmit = async (
    values,
    { setErrors, setSubmitting, resetForm }
  ) => {

    if (initialValues.assumptionFormat !== values.assumptionFormat) {
      if (window.confirm("WARNING: Ensure you export your data before modifying this assumption. \nPressing \"OK\" will RESET all entered numbers in this assumption table") === true) {
        updateDebt(values, project, debt, setOpen, setSubmitting, setErrors, setDebtsAtom, setShowSpinner, pnlAtom, updatePowdrModelGlobalRef, ablAssetsChosen);
      } else {
        resetForm();
        setOpen(false);
      }
    } else {
      updateDebt(values, project, debt, setOpen, setSubmitting, setErrors, setDebtsAtom, setShowSpinner, pnlAtom, updatePowdrModelGlobalRef, ablAssetsChosen);
    }

  };


  return (
    <React.Fragment>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (

              <Dialog
                open={open}
                onClose={(event, reason) => {
                  if (reason !== 'backdropClick') {
                    setOpen(false)
                  }
                }}
                aria-labelledby="form-dialog-title"
              >
                <form onSubmit={handleSubmit}>
                  <DialogTitle id="form-dialog-title">Edit Debt</DialogTitle>
                  <DialogContent sx={{ paddingBottom: 0 }}>

                    <Grid container spacing={3}>
                      <Grid item xs>


                    <DialogContentText>
                      Update the name of the debt you wish to edit.
                    </DialogContentText>
                    <TextField
                      error={Boolean(touched.debtName && errors.debtName)}
                      name="debtName"
                      autoFocus
                      placeholder={"Debt Name"}
                      margin="dense"
                      id="debtName"
                      label="Debt Name"
                      value={values.debtName}
                      onChange={handleChange}
                      disabled={!values.canDelete}
                      type="text"
                      helperText={touched.debtName && errors.debtName}
                      fullWidth
                    />

                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Assumption Format
                    </FormLabel>
                    <RadioGroup
                      list="true"
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="assumptionFormat"
                      value={values.assumptionFormat}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="SIMPLE_CORKSCREW"
                        control={<Radio />}
                        label="Simple Corkscrew"
                      />
                      <FormControlLabel
                        value="LOAN_DEBT"
                        control={<Radio />}
                        label="Loan"
                      />
                      <FormControlLabel
                        value="CID_DEBT"
                        control={<Radio />}
                        label="CID Facility"
                      />
                      <FormControlLabel
                        value="PIK_DEBT"
                        control={<Radio />}
                        label="PIK Facility"
                      />
                      <FormControlLabel
                        value="ABL_DEBT"
                        control={<Radio />}
                        label="ABL facility"
                      />
                    </RadioGroup>

                      </Grid>


                      {values.assumptionFormat === "ABL_DEBT" && <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormLabel component="legend">Assets To include in ABL facility
                            calculation</FormLabel>
                          <FormGroup>

                            {balanceSheetAtom &&
                              balanceSheetAtom.balanceCategoryDtos.filter(category => ['Fixed Assets', 'Current Assets'].includes(category.name) && category.totalField === false).map((category) => (
                                <FormControlLabel key={Math.random() * 10000000}
                                                  control={
                                                    <Checkbox checked={[...ablAssetsChosen].includes(category.id)} onChange={handleChangeAblFacility}
                                                              name={category.overrideName} id={category.id + ""} />
                                                  }
                                                  label={category.overrideName}
                                />

                              ))}

                          </FormGroup>

                        </FormControl>
                      </Grid>
                      }

                      {values.assumptionFormat === "PIK_DEBT" && <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormLabel component="legend">Debts To include in PIK Interest
                            calculation</FormLabel>
                          <FormGroup>

                            {debts &&
                              debts.filter(debtFormat => debtFormat.debtDto.assumption !== "PIK_DEBT").map((pik) => (
                                <FormControlLabel key={Math.random() * 10000000}
                                                  control={
                                                    <Checkbox defaultChecked={debt?.debts?.includes(pik.debtDto.id)}
                                                              onChange={handleChangeDebt}
                                                              name={pik.debtDto.name} id={pik.debtDto.id + ""} />
                                                  }
                                                  label={pik.debtDto.name}
                                />

                              ))}

                          </FormGroup>

                        </FormControl>
                      </Grid>
                      }

                      {values.assumptionFormat === "PIK_DEBT" && <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormLabel component="legend">PIK on PIK?</FormLabel>
                          <FormGroup>



                            <RadioGroup
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="pikOnPik"
                              value={values.pikOnPik}
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                value="true"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="false"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>

                          </FormGroup>

                        </FormControl>
                      </Grid>
                      }




                    </Grid>

                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      autoFocus
                      disabled={isSubmitting}
                    >
                      {showSpinner ? <CircularProgress size={24} /> : 'Update'}
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
          )}
        </Formik>
      <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>
    </React.Fragment>
  );;
}

export default EditDebt;
