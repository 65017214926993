import { getCurrentProject } from "../../SharedComponents/ProjectServices";
import _ from "lodash";

export function actualiseProducts(products) {

  let firstForecast = getCurrentProject().firstForecast;

  //actualise each product
  _.forEach(products, function(product, i) {

    _.forEach(product.productCategoryDtoList, function(productCategory, x) {

      if (_.isEmpty(productCategory.productMonthDtoList)) {
        return;
      }

      if (["Quantity Growth Rates",
        "Sales Price Growth Rates",
        "Growth Rates",
        "Cost Price Growth Rates",
        "Churn Rate",
        "Price increase (% of opening revenue)"].includes(productCategory.name)) {

        productCategory.productMonthDtoList[firstForecast].cellType = "DISABLED"
        productCategory.productMonthDtoList[firstForecast].financialTypeEnum = "ACTUAL"
      } else {

          productCategory.productMonthDtoList[firstForecast].cellType =
            productCategory.productMonthDtoList[firstForecast - 1].cellType;

          productCategory.productMonthDtoList[firstForecast].financialTypeEnum =
            productCategory.productMonthDtoList[firstForecast - 1].financialTypeEnum;

      }

      //if there is an overriden value present, then we set the value to this and then the valueOverriden to null
      if (productCategory.productMonthDtoList[firstForecast].valueOverriden != null) {
        productCategory.productMonthDtoList[firstForecast].value = productCategory.productMonthDtoList[firstForecast].valueOverriden;
        productCategory.productMonthDtoList[firstForecast].valueOverriden = null;
      }

    });

  });

  return products;
}