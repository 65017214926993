import React, { useEffect, useState, useContext }  from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  Grid,
  Card,
  CardContent, Stack
} from "@mui/material";
import { spacing } from "@mui/system";
import axios from "axios";

import ProjectSetupPowdrStaff from "./components/ProjectSetupPowdrStaff";
import useProject from "../../../../hooks/useProject";
import { PowdrContext } from "../../../../contexts/PowdrContext";
import AddProject from "./components/AddProject";
import { AssumptionLabel } from "../../SharedComponents/AssumptionLabel";
import { Briefcase } from "react-feather";
import LoadingBar from "../../SharedComponents/LoadingBar";
import getProjectId  from "../../SharedComponents/ProjectServices";
import navItems from "../../../../components/sidebar/dashboardItems";
import ProjectsViewer from "../../SharedComponents/ProjectFolder/ProjectsViewer";
import Settings from "../../../../components/Settings";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Spacer = styled.div(spacing);

const AboutIcon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)};

  svg {
    width: 14px;
    height: 14px;
  }
`;


function PowdrProjects() {
  const [projects, setProjects] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [loaded, setLoaded] = useState(false);

  const [projectToEdit, setProjectToEdit] = useState({  });
  const { setProject } = useProject();

  const {refreshOverheadsShallowFromContext,
    refreshHeadcountsShallowFromContext, refreshPnlFromContext} = useContext(PowdrContext)

  async function loadProjects() {

    await getProjectId();

    axios.get('projects/').then(function(response) {
      setProjects(response.data);
      setProjectToEdit(projects.find(project => project.id === projectToEdit.id))
      setLoaded(true)
    })

    axios.get('company/').then(function(response) {
      setCompanies(response.data);
    })

  }

  useEffect(() => {

    loadProjects();

    // const intervalId = setInterval(loadProjects, 30000);
    //
    // return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function showProject(projectId) {
    setProjectToEdit(projects.find(project => project.id === parseInt(projectId)))
    setShowTable(false);
  }

  function hideProject() {
    setShowTable(true);
  }

  function enableDisableProject() {
    if (window.confirm("Are you sure you wish to enable/disable this project") === true) {
      //call init
      projectToEdit.disabled = !projectToEdit.disabled;
      axios.put('project', projectToEdit).then(value => {
        loadProjects();
        setProjectToEdit(projects.find(project => project.id === projectToEdit.id))
      })
    }
  }

  function viewProject(e) {

    try {
      navItems[0].pages[5].children[1]['badge'] = "";
      navItems[0].pages[5].children[2]['badge'] = "";
      navItems[0].pages[5].children[3]['badge'] = "";
      navItems[0].pages[5].children[4]['badge'] = "";
      navItems[0].pages[5].children[5]['badge'] = "";
      navItems[0].pages[5].children[6]['badge'] = "";
      navItems[0].pages[5].children[7]['badge'] = "";
    } catch (e) {

    }

    setProject(projects.find(project => project.id === parseInt(e.target.id)))
    refreshOverheadsShallowFromContext();
    refreshHeadcountsShallowFromContext();
    refreshPnlFromContext()

    // eslint-disable-next-line no-restricted-globals
    location.reload();
  }



  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Powdr Projects
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Pages
        </Link>
        <Typography>Projects</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <LoadingBar loaded={loaded} assumptionName={"Projects"}/>

      {loaded &&
        <Stack spacing={4} direction="row" sx={{ paddingBottom: 6 }}>
          <AddProject names={projects.map(function(proj) {
            return proj.name
          })} loadData={loadProjects} companies={companies} projects={projects} admin={true} company={1}></AddProject>
          {!showTable && <Button variant="contained" onClick={hideProject}>Show Projects</Button>}
        </Stack>
      }

      {showTable &&
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <ProjectsViewer adminMode={true} showProject={showProject}></ProjectsViewer>
            </CardContent>
          </Card>
        </Grid>
      }

      {!showTable && loaded &&

        <Grid container spacing={6} >
          <Grid item xs={12} display={projectToEdit?.projectType === 'MULTI_ENTITY' ? 'flow' : 'none'}>
              <Card mb={6}>
                <CardContent>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      Projects
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      List of linked projects for this multi entity project
                    </Typography>
                  </Grid>
                  <Grid container spacing={6}>
                    <Grid item xs={12}>

                      <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Name</TableCell>
                              <TableCell align="right">Owner</TableCell>
                              <TableCell align="right">Initialised</TableCell>
                              <TableCell align="right">Start Date</TableCell>
                              <TableCell align="right">Project Enabled</TableCell>
                              <TableCell align="right">Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {projects.filter(project => projectToEdit?.projectMultiProjectList.filter(multiProject => multiProject.project === project.id).length > 0).map((row) => (
                              <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.name}
                                </TableCell>
                                <TableCell align="right">{row.companyName}</TableCell>
                                <TableCell align="right">{row.initialised ? 'YES' : 'NO'}</TableCell>
                                <TableCell align="right">{new Date(row.startDate).toDateString()}</TableCell>
                                <TableCell align="right">{row.disabled ? 'NO' : 'YES'}</TableCell>
                                <TableCell align="right"><Button
                                  disabled={row.disabled} id={row.id} onClick={viewProject}>View</Button></TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
          </Grid>

          <Grid item xs={12}>

            <Card mb={6}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Project
                </Typography>

                <Spacer mb={4} />

                <Grid container direction="row" alignItems="center" mb={2}>
                  <Grid item>
                    <AboutIcon>
                      <Briefcase />
                    </AboutIcon>
                  </Grid>
                  <Grid item>
                    Project Status:  <AssumptionLabel label={projectToEdit?.disabled ? 'Awaiting Activation' : 'Project Active'}
                                            color={projectToEdit?.disabled ? 'error' : 'success'} />
                  </Grid>
                </Grid>

              </CardContent>
            </Card>
          </Grid>


          <Grid item xs={12}>
            <ProjectSetupPowdrStaff project={projectToEdit} companies={companies}  refreshProjects={loadProjects}/>
          </Grid>

          <Grid item xs={12} >
            <Card mb={6}>
              <CardContent>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Disable/Enable Project
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Enabling a project, will allow clients to access and enter data into their forecasting model.
                  </Typography>
                </Grid>
                <Grid container spacing={6}>
                  <Grid item md={6}>
                    <Button variant="contained" style={{ width: 200, height: 100 }} onClick={enableDisableProject}>
                      {projectToEdit?.disabled ? 'Enable' : 'Disable'} Project
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      }

      <Settings />

    </React.Fragment>
  );
}

export default PowdrProjects;
