import React, { useState } from 'react';
import MUIDataTable from "mui-datatables";
import { Button } from '@mui/material';
import { format } from "date-fns";
import CommentIcon from '@mui/icons-material/Comment';

import RestoreSnapshotModal from "./RestoreSnapshotModal";
import CommentModal from "../../../SharedComponents/CommentModal";

function SnapTable({ data }) {
  const [restoreModal, setRestoreModal] = useState(false);
  const [snapshotToRestoreId, setSnapshotToRestoreId] = useState(0);
  const [selectedProjectName, setSelectedProjectName] = useState('');
  const [openComment, setOpenComment] = useState(false);
  const [selectedItem, setSelectedItem] = useState({ comment: "" });
  const [snapshotUrl, setSnapshotUrl] = useState("");


  const handleCommentClick = (item) => {
    setSelectedItem(item);
    setSnapshotUrl("/snapshot/comment/" + item.id + "/" + item.projectId)
    setOpenComment(true);
  };

  const columns = [
    {
      name: "projectName",
      label: "Project",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "timestamp",
      label: "Time",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          if (!value) {
            return '';
          }

          let date;
          if (typeof value === 'number') {
            // Convert seconds to milliseconds
            date = new Date(value * 1000);
          } else if (typeof value === 'string') {
            // Try parsing as ISO string
            date = new Date(value);
          } else {
            return 'Invalid Date';
          }

          // Check if date is valid
          if (isNaN(date.getTime())) {
            return 'Invalid Date';
          }

          // Format the date using date-fns
          return format(date, 'PPPpp'); // Adjust format as needed
        },
      }
    },
    {
      name: "comment",
      label: "Comment",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;
          const item = data[rowIndex];
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CommentIcon
                onClick={() => handleCommentClick(item)}
                sx={{ marginLeft: 1, cursor: 'pointer' }}
              />
            </div>
          );
        },
      }
    },
    {
      name: "actionedBy",
      label: "User",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "eventType",
      label: "Event Type",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "id",
      label: "Snapshot",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const value = data[dataIndex].id;
          const projectName = data[dataIndex].projectName;
          return (
            <Button color="primary" onClick={() => handleOpen(value, projectName)}>
              Restore Snapshot
            </Button>
          );
        },
      }
    },
  ];

  const handleOpen = (auditId, projectName) => {
    setSnapshotToRestoreId(auditId);
    setSelectedProjectName(projectName);
    setRestoreModal(true);
  };

  const options = {
    filterType: 'checkbox',
    responsive: 'standard',
  };

  return (
    <>
      <CommentModal
        open={openComment}
        setOpen={setOpenComment}
        item={selectedItem}
        shallowUpdateUrl={snapshotUrl}
        type={""}
      />
      <RestoreSnapshotModal
        open={restoreModal}
        setOpen={setRestoreModal}
        snapshotId={snapshotToRestoreId}
        snapshotName={selectedProjectName}
      />

      <MUIDataTable
        title={"Snapshots"}
        data={data}
        columns={columns}
        options={options}
      />
    </>
  );
}

export default SnapTable;
