import React, { useState, useEffect } from "react";
import {
  Box,
  Button, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControl, FormControlLabel, FormGroup,
  FormLabel,
  Grid, LinearProgress, List, ListItem, ListItemText,
  Radio,
  RadioGroup,
  TextField, Typography
} from "@mui/material";
import {  Formik } from "formik";
import useProject from "../../../../../hooks/useProject";
import axios from "axios";
import * as Yup from "yup";
import { Add } from "@mui/icons-material";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { revenueAndCostState } from "../../../SharedState/RevenueAndCostState";
import { headcountsState } from "../../../SharedState/HeadcountsState";
import { currentAssetsState } from "../../../SharedState/CurrentAssetsState";
import { refreshVatPackage, vatPackageState } from "../../../SharedState/VatPackageState";
import { vatState } from "../../../SharedState/VatState";
import { overheadsState } from "../../../SharedState/OverheadsState";
import { getProjectIdNotAsync } from "../../../SharedComponents/ProjectServices";


function AddProduct() {
  const [open, setOpen] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState([]);

  const { project } = useProject();
  const [assumptionFormatValue, setAssumptionFormatValue] = useState("PRICE_X_QUANTITY");
  const [dpcAssumptionTypeValue, setDpcAssumptionTypeValue] = useState("UNIT_COST");

  const headcountsAtom = useRecoilValue(headcountsState);

  const [products, setProducts] = useState([]);
  const [headcounts, setHeadcounts] = useState([])

  const setCurrentAssets = useSetRecoilState(currentAssetsState);
  const [productsAtom, setProductsAtom] = useRecoilState(revenueAndCostState);

  const overheadsAtom = useRecoilValue(overheadsState);
  const currentAssetAtom = useRecoilValue(currentAssetsState);
  const setVatPackageAtom =  useSetRecoilState(vatPackageState);
  const vatRateAtom = useRecoilValue(vatState);

  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState({ id : 0 });
  const [productsForProject, setProductsForProject] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {

    if (open === true) {
      setProducts(structuredClone(productsAtom));
      setHeadcounts(structuredClone(headcountsAtom));

      loadProjects();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsAtom, headcountsAtom, open]);

  function loadProjects() {

    axios.get('projectsByOwner/' + getProjectIdNotAsync().owner).then(function(response) {
      setProjects(response.data);
    })

  }

  useEffect( () => {
    if (selectedProject !== undefined && selectedProject !==  undefined && assumptionFormatValue === "REVENUE_DIVISIONAL_CONSOLIDATION") {

      axios.get("revenuecos/" + selectedProject.id).then(function(response) {
        setProductsForProject(response.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject]);

  const handleChangeHeadcounts = (event) => {

    //find the department
    const extractedDtos = headcounts.reduce((acc, headcount) => acc.concat(headcount.headCountCategoryDtos), [])
      .filter(category => category.name === 'Quantity')
      .filter(category => category.departmentName === event.target.name)
      .find(category => category.id === Number(event.target.id));

    extractedDtos.checked = event.target.checked;

    //Clever way of getting the page to refresh
    setTriggerRefresh({
      ...triggerRefresh
    })

  };

  const handleSelectModel = (model) => {

    const currentIndex = selectedProducts.indexOf(model.productDto.id);
    const newChecked = [...selectedProducts];

    if (currentIndex === -1) {
      newChecked.push(model.productDto.id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedProducts(newChecked);
  };

  function findParentProduct(row) {
    const found = products.find(product =>   product.productDto.products.includes(row.productDto.id));
    return found ? found.productDto.name : '';
  }

  const handleChangeProduct = (event) => {

    const productCh = products
      .filter(product => product.productDto.name === event.target.name)[0];

    productCh.productDto.checked = event.target.checked;

    //Clever way of getting the page to refresh
    setTriggerRefresh({
      ...triggerRefresh
    })

  };

  function getCheckedDtoIds(headcounts) {
    let ids = headcounts.reduce((acc, headcount) => {
      const checkedIds = headcount.headCountCategoryDtos
        .filter(dto => dto.checked)
        .map(dto => dto.id);
      return acc.concat(checkedIds);
    }, []);

    return ids;
  }

  const validationSchema = Yup.object().shape({
    productName: Yup.string()
      .min(3, "Must be at least 3 characters")
      .max(150, "Must be less than 150 characters")
      .required("Required")
      .notOneOf(productsAtom.map(function(i) {
        return i?.productDto?.name;
      }), "Products must have a unique name"),
  });

  const initialValues = {
    productName: "",
    assumptionFormat: "",
    variableAValue: "Max Units",
    variableBValue: "Units Sold",
    variableCValue: "Percentage of quantity",
  };

  const handleSubmit = async (
    values,
    { setErrors, resetForm }
  ) => {
    setSubmittingForm(true)
    try {

      let productsToInclude = [];

      if (assumptionFormatValue === "REVENUE_DIVISIONAL_CONSOLIDATION" ) {
        productsToInclude = selectedProducts;
      } else {
        productsToInclude = products.filter(item => item.productDto.checked === true).map(item => item.productDto["id"]);
      }

      let assumptionVariant = dpcAssumptionTypeValue;
      if (assumptionFormatValue === "REVENUE_AS_A_PERCENTAGE_OF_VALUE") {
        assumptionVariant = "MARGIN";
      }

      axios
        .post("revenuecos/product", {
          name: values.productName,
          project: project.id,
          assumption: assumptionFormatValue,
          percentageComplete: 0,
          overrideComplete: false,
          variableA: values.variableAValue,
          variableB: values.variableBValue,
          variableC: values.variableCValue,
          dpcAssumptionType: assumptionVariant,
          products: productsToInclude,
          headcounts: getCheckedDtoIds(headcounts),
          linkedProjectId: selectedProject.id,
        })
        .then(async function(response) {

          //update the atoms - products & current assets (has list of products)
          await axios.get("revenuecos/" + project.id).then(function(response) {
            let products_ = response.data;
            setProductsAtom(structuredClone(response.data));

            //what about the vatPackage, shouldn't we recalculate that, since we won't have this new product in there?
            let vatPackage = refreshVatPackage(products_, structuredClone(overheadsAtom), structuredClone(vatRateAtom), currentAssetAtom);
            setVatPackageAtom(vatPackage); //basically this just triggers a refresh
          });

          await axios.get("currentassets/" + project.id).then(function(response) {
            setCurrentAssets(response.data);
          });

          setOpen(false);
          setSubmittingForm(false)
          resetForm();

          setSelectedProducts([]);
          setSelectedProject({ id : 0 });

        })
        .catch(function (error) {
          console.log(error);
          resetForm();
          setSubmittingForm(false)
        });


    } catch (error) {
      setErrors({ submit: error.message });

    }
  };

  const handleChangeAssumption = (event) => {
    setAssumptionFormatValue(event.target.value);
  };

  const handleChangeDpcAssumption = (event) => {
    setDpcAssumptionTypeValue(event.target.value);
  };

  return (
    <React.Fragment>
      <Grid container>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Grid item xs={12}>
              <Button
                mr={2}
                variant="contained"
                color="primary"
                sx={{ marginBottom: 2 }}
                onClick={() => setOpen(true)}
              >
                Add Product
                <Add />
              </Button>

              <Dialog
                open={open}
                onClose={(event, reason) => {
                  if (reason !== 'backdropClick') {
                    setOpen(false)
                  }
                }}
                fullWidth={["HEADS_TO_REVENUE", "REVENUE_DIVISIONAL_CONSOLIDATION"].includes(assumptionFormatValue)}
                maxWidth={"md"}
                aria-labelledby="form-dialog-title"
              >
                <form onSubmit={handleSubmit}>
                  <DialogTitle id="form-dialog-title">Add Product</DialogTitle>

                  {submittingForm &&  (

                    <DialogContent>

                      <DialogContentText>
                        Adding Product...
                        <LinearProgress my={2} />
                      </DialogContentText>

                    </DialogContent>
                  )}


                  {!submittingForm &&  (
                  <DialogContent sx={{ paddingBottom: 0 }}>

                    <Grid container spacing={3}>
                      <Grid item xs>


                    <DialogContentText>
                      Enter the name of the product you wish to add.
                    </DialogContentText>
                    <TextField
                      error={Boolean(touched.productName && errors.productName)}
                      name="productName"
                      autoFocus
                      placeholder={"Product Name"}
                      margin="dense"
                      id="productName"
                      label="Product Name"
                      value={values.productName}
                      onChange={handleChange}
                      type="text"
                      helperText={touched.productName && errors.productName}
                      fullWidth
                    />

                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Assumption Format
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="assumptionFormat"
                      value={assumptionFormatValue}
                      onChange={handleChangeAssumption}
                    >
                      <FormControlLabel
                        value="DIRECT_INPUT"
                        control={<Radio />}
                        label="Direct Input"
                      />
                      <FormControlLabel
                        value="PRICE_X_QUANTITY"
                        control={<Radio />}
                        label="Price x Quantity"
                      />
                      <FormControlLabel
                        value="PRICE_X_QUANTITY_COST_INFLATION"
                        control={<Radio />}
                        label="Price X Quantity (Cost Inflation)"
                      />
                      <FormControlLabel
                        value="SUBSCRIPTION_MODEL"
                        control={<Radio />}
                        label="Subscription modelling"
                      />
                      <FormControlLabel
                        value="ANNUAL_GROWTH_RATE"
                        control={<Radio />}
                        label="Annual Growth Rate"
                      />
                      <FormControlLabel
                        value="MONTHLY_GROWTH_RATE"
                        control={<Radio />}
                        label="Monthly Growth Rate"
                      />
                      <FormControlLabel
                        value="HEADS_TO_REVENUE"
                        control={<Radio />}
                        label="Sales Per Person"
                      />
                      <FormControlLabel
                        value="HEADCOUNT_UTILISATION"
                        control={<Radio />}
                        label="Headcount Utilisation"
                      />
                      <FormControlLabel
                        value="NON_HEADCOUNT_UTILISATION"
                        control={<Radio />}
                        label="Non-Headcount Utilisation"
                      />
                      <FormControlLabel
                        value="PRICE_X_QUANTITY_BUILDER"
                        control={<Radio />}
                        label="Price x Quantity (Builder)"
                      />
                      <FormControlLabel
                        value="REVENUE_AS_A_PERCENTAGE_OF_VALUE"
                        control={<Radio />}
                        label="Revenue as a percentage of a value (Builder)"
                      />
                      <FormControlLabel
                        value="SALES_PER_DAY_REVENUE"
                        control={<Radio />}
                        label="Sales Per Day"
                      />
                      <FormControlLabel
                        value="PERCENTAGE_OF_ANOTHER_PRODUCT"
                        control={<Radio />}
                        label="Percentage of Another Product"
                      />
                      <FormControlLabel
                        value="REVENUE_DIVISIONAL_CONSOLIDATION"
                        disabled={getProjectIdNotAsync().projectType !== "PNL_DIVISIONAL"}
                        control={<Radio />}
                        label="Revenue Consolidation"
                      />
                    </RadioGroup>

                      </Grid>


                      {assumptionFormatValue === "REVENUE_DIVISIONAL_CONSOLIDATION" &&
                        <Grid item xs>
                          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormLabel component="legend">Select a linked project to create your product consolidation group</FormLabel>
                            <FormGroup>

                              <Box sx={{minWidth: '50%'}}>
                                <br/>
                                <Typography variant="h6">Projects</Typography>
                                <Box sx={{ minHeight: '90%', maxHeight: '90%', overflow: 'auto', border: '1px solid #ccc', padding: '8px' }}>
                                  <List>

                                    {projects.filter(projectToPick => getProjectIdNotAsync().id !== projectToPick.id && getProjectIdNotAsync()?.projectMultiProjectList.filter(multiProject => multiProject.project === projectToPick.id).length > 0).map((row) => (
                                      <ListItem
                                        key={row.id}
                                        button
                                        selected={selectedProject.id === row.id}
                                        onClick={() => setSelectedProject(row)}
                                      >
                                        <ListItemText primary={row.name} />
                                      </ListItem>
                                    ))}
                                  </List>
                                </Box>
                              </Box>
                            </FormGroup>

                          </FormControl>
                        </Grid>
                      }

                      {assumptionFormatValue === "REVENUE_DIVISIONAL_CONSOLIDATION" &&
                        <Grid item xs>
                          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormLabel component="legend">Select products you want to include in this new consolidation, note that some may already be in an existing group.</FormLabel>
                            <FormGroup>

                              <Box sx={{minWidth: '50%'}}>
                                <Typography variant="h6">Products</Typography>
                                <Box sx={{ minHeight: '90%', maxHeight: '90%', overflow: 'auto', border: '1px solid #ccc', padding: '8px' }}>
                                  <List>

                                    {productsForProject.map((row) => (
                                      <ListItem
                                        key={row.productDto.id}
                                        button
                                        selected={selectedProject.id === row.id}
                                        secondaryAction={
                                          <Checkbox
                                            edge="end"
                                            onChange={() => handleSelectModel(row)}
                                            checked={selectedProducts.indexOf(row.productDto.id) !== -1}
                                          />
                                        }
                                      >

                                        <ListItemText primary={row.productDto.name} secondary={`Current Group: ${(findParentProduct(row))}`} />
                                      </ListItem>
                                    ))}
                                  </List>
                                </Box>
                              </Box>

                            </FormGroup>

                          </FormControl>
                        </Grid>
                      }

                      {assumptionFormatValue === "PRICE_X_QUANTITY_BUILDER" && <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormLabel component="legend">Price x Quantity (Builder)
                            calculation</FormLabel>
                          <FormGroup>

                            <DialogContentText>
                              <br/>
                              Variable A Name  (Optional):
                            </DialogContentText>
                            <TextField
                              error={Boolean(touched.variableAValue && errors.variableAValue)}
                              name="variableAValue"
                              autoFocus
                              margin="dense"
                              id="maxUnitName"
                              label="Max Unit"
                              value={values.variableAValue}
                              onChange={handleChange}
                              type="text"
                              helperText={touched.variableAValue && errors.variableAValue}
                              fullWidth
                            />

                            <DialogContentText>
                              <br/>
                              Variable B Name  (Optional):
                            </DialogContentText>
                            <TextField
                              error={Boolean(touched.variableBValue && errors.variableBValue)}
                              name="variableBValue"
                              autoFocus
                              margin="dense"
                              id="unitSold"
                              label="Units Sold"
                              value={values.variableBValue}
                              onChange={handleChange}
                              type="text"
                              helperText={touched.variableBValue && errors.variableBValue}
                              fullWidth
                            />



                          </FormGroup>

                        </FormControl>
                      </Grid>
                      }



                      {assumptionFormatValue === "REVENUE_AS_A_PERCENTAGE_OF_VALUE" && <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormLabel component="legend">Price x Quantity (Builder)
                            calculation</FormLabel>
                          <FormGroup>

                            <DialogContentText>
                              <br/>
                              Variable A Name  (Optional):
                            </DialogContentText>
                            <TextField
                              error={Boolean(touched.variableAValue && errors.variableAValue)}
                              name="variableAValue"
                              autoFocus
                              margin="dense"
                              id="maxUnitName"
                              label="Max Unit"
                              value={values.variableAValue}
                              onChange={handleChange}
                              type="text"
                              helperText={touched.variableAValue && errors.variableAValue}
                              fullWidth
                            />

                            <DialogContentText>
                              <br/>
                              Variable B Name  (Optional):
                            </DialogContentText>
                            <TextField
                              error={Boolean(touched.variableBValue && errors.variableBValue)}
                              name="variableBValue"
                              autoFocus
                              margin="dense"
                              id="unitSold"
                              label="Units Sold"
                              value={values.variableBValue}
                              onChange={handleChange}
                              type="text"
                              helperText={touched.variableBValue && errors.variableBValue}
                              fullWidth
                            />

                            <DialogContentText>
                              <br/>
                              Variable C Name  (Optional):
                            </DialogContentText>
                            <TextField
                              error={Boolean(touched.variableCValue && errors.variableCValue)}
                              name="variableCValue"
                              autoFocus
                              margin="dense"
                              id="percentageOfQuantity"
                              label="Percentage of quantity"
                              value={values.variableCValue}
                              onChange={handleChange}
                              type="text"
                              helperText={touched.variableCValue && errors.variableCValue}
                              fullWidth
                            />



                          </FormGroup>

                        </FormControl>
                      </Grid>
                      }


                      {assumptionFormatValue === "HEADS_TO_REVENUE" && <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormLabel component="legend">Headcount To include in headcount to revenue
                            calculation</FormLabel>
                          <FormGroup>

                            {headcounts &&
                              headcounts.filter(headcount => headcount.headCountDto.assumption === 'PRICE_X_QUANTITY').map((headcount) => (

                                headcount.headCountCategoryDtos.filter(category => category.name === 'Quantity' && category.departmentName !== '').map((category) => (
                                  <FormControlLabel key={Math.random() * 10000000}
                                                    control={
                                                      <Checkbox checked={category.checked} onChange={handleChangeHeadcounts}
                                                                name={category.departmentName} id={category.id + ""} />
                                                    }
                                                    label={category.departmentName}
                                  />
                                ))


                              ))}

                          </FormGroup>

                        </FormControl>
                      </Grid>
                      }

                      {["HEADS_TO_REVENUE","NON_HEADCOUNT_UTILISATION","PRICE_X_QUANTITY_BUILDER","SALES_PER_DAY_REVENUE"].includes(assumptionFormatValue) && <Grid item xs>
                          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">

                          <FormLabel component="legend">Please select the direct product cost assumption type:</FormLabel>
                          <FormGroup>

                              <RadioGroup
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="assumptionFormat"
                                value={dpcAssumptionTypeValue}
                                onChange={handleChangeDpcAssumption}
                              >
                                  <FormControlLabel
                                    value="MARGIN"
                                    control={<Radio />}
                                    label="Margin"
                                  />
                                  <FormControlLabel
                                    value="UNIT_COST"
                                    control={<Radio />}
                                    label="Unit Cost"
                                  />
                              </RadioGroup>
                            </FormGroup>
                          </FormControl>
                        </Grid>
                      }

                      {assumptionFormatValue === "HEADCOUNT_UTILISATION" && <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormLabel component="legend">Headcount To include in the headcount utilisation
                            calculation</FormLabel>
                          <FormGroup>

                            {headcounts &&
                              headcounts.filter(headcount => headcount.headCountDto.assumption === 'PRICE_X_QUANTITY').map((headcount) => (

                                headcount.headCountCategoryDtos.filter(category => category.name === 'Quantity' && category.departmentName !== '').map((category) => (
                                  <FormControlLabel key={Math.random() * 10000000}
                                                    control={
                                                      <Checkbox checked={category.checked} onChange={handleChangeHeadcounts}
                                                                name={category.departmentName} id={category.id + ""} />
                                                    }
                                                    label={category.departmentName}
                                  />
                                ))


                              ))}

                          </FormGroup>

                        </FormControl>
                      </Grid>
                      }


                      {assumptionFormatValue === "PERCENTAGE_OF_ANOTHER_PRODUCT" && <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormLabel component="legend">Product(s) to include in the percentage of another product
                            calculation</FormLabel>
                          <FormGroup>

                            {products &&
                              products.map((product) => (
                                <FormControlLabel key={Math.random() * 10000000}
                                                  control={
                                                    <Checkbox checked={product.productDto.checked} onChange={handleChangeProduct}
                                                              name={product.productDto.name} id={product.productDto.id + ""} />
                                                  }
                                                  label={product.productDto.name}
                                />

                              ))}

                          </FormGroup>

                        </FormControl>
                      </Grid>
                      }

                    </Grid>



                  </DialogContent>
                  )}

                  <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary" disabled={submittingForm}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      autoFocus
                      disabled={submittingForm}
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
            </Grid>
          )}
        </Formik>
      </Grid>
    </React.Fragment>
  );
}

export default AddProduct;
