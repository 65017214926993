import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";

import {
  Grid,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar
} from "@mui/material";

import { Menu as MenuIcon } from "@mui/icons-material";

import NavbarUserDropdown from "./NavbarUserDropdown";
import { usersViewingState } from "../../pages/powdr/SharedState/UsersViewingState";
import { useSetRecoilState } from "recoil";
import axios from "axios";
import getProjectId from "../../pages/powdr/SharedComponents/ProjectServices";
import _ from "lodash";
import UserAvatar from "../../pages/powdr/SharedComponents/UserAvatar";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

// Function to filter the entries
const filterEntries = (data) => {
  const twoHoursAgo = new Date(new Date().getTime() - (2 * 60 * 60 * 1000)); // 2 hours ago from now

  return _(data)
    .filter(entry => {
      // Check if 'startedViewing' is within the last 2 hours
      const viewingDate = new Date(entry.startedViewing);
      return viewingDate > twoHoursAgo;
    })
    .uniqBy('userId') // Filter out non-unique userIds
    .value(); // Get the final filtered and processed array
};


const Navbar = ({ onDrawerToggle }) => {

  const setData = useSetRecoilState(usersViewingState);
  const [userViewers, setUserViewers]  = useState([]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      try {

        let project = await getProjectId();
        const user = JSON.parse(localStorage.getItem("lastUser"));

        const response = await axios.put('projectSession/' + project.id + '/' + user.id )

        const result = response.data;
        if (isSubscribed) {
          setUserViewers(filterEntries(result))
          setData(result);
        }
      } catch (error) {
        console.error('Error updating data:', error);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 30000); // Poll every 30 seconds

    // Cleanup on unmount
    return () => {
      isSubscribed = false;
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item>
            </Grid>
            <Grid item xs >

            </Grid>

            <Grid item>
              <Grid container alignItems="center" item spacing={2} >

                {!!userViewers &&
                  userViewers.map((userViewer) => (

                    <Grid item key={Math.random() * 10000000}>
                        <UserAvatar id={userViewer.id} userDisplayName={userViewer.userDisplayName}></UserAvatar>
                    </Grid>

                ))}

                <Grid item>
                  <NavbarUserDropdown />
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
