import _ from "lodash";
import { getCurrentProject } from "../../SharedComponents/ProjectServices";
import axios from "axios";

export async function actualiseCorporationTax() {
  let firstForecast = getCurrentProject().firstForecast;

  let corpTaxs = [];

  let config = {
    method: "get",
    url: "vat/corporationtax/" + getCurrentProject().id
  };

  await axios(config)
    .then(function(response) {
      corpTaxs = response.data;
    })
    .catch(function(error) {
      console.log(error);
    });


  //actualise each vat
  _.forEach(corpTaxs, function(vat, i) {

    _.forEach(vat.vatCategoryDtos, function(category, x) {

      if (_.isEmpty(category.vatMonthDtos)) {
        return;
      }

      category.vatMonthDtos[firstForecast].cellType =
        category.vatMonthDtos[firstForecast - 1].cellType;

      category.vatMonthDtos[firstForecast].financialTypeEnum =
        category.vatMonthDtos[firstForecast - 1].financialTypeEnum;

    });

  });

  config = {
    method: "put",
    url: "vat/" + getCurrentProject().id,
    data: corpTaxs
  };

  await axios(config)
    .then(function (response) {})
    .catch(function (error) {
      console.log(error);
    });

}
