import React, { useEffect, useState } from "react";
import axios from "axios";
import useProject from "../../../../hooks/useProject";

import PageHeader from "../../SharedComponents/PageHeader";
import styled from "@emotion/styled";
import { Divider as MuiDivider } from "@mui/material";
import { spacing } from "@mui/system";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import SnapTable from "./components/SnapTable";
import Settings from "../../../../components/Settings";
import { useSetRecoilState } from "recoil";
import { drawerContentState, drawerState } from "../../SharedState/DrawerState";
import CreatingSnapshotModal from "./components/CreatingSnapshotModal";


const Divider = styled(MuiDivider)(spacing);

export default function Snapshot() {

  const { projectId } = useParams(); // Access projectId from the URL

  const [createModal, setCreateModal] = useState(false);

  const { project } = useProject();
  const [snapshotsData, setSnapshotsData] = useState(null);
  const setDrawerAtom = useSetRecoilState(drawerState);
  const setDrawerContentAtom = useSetRecoilState(drawerContentState);

  // Function to fetch snapshots
  const fetchSnapshots = () => {
    let idToUser = project.id;

    if (projectId != null) {
      idToUser = projectId;
    }

    axios.get('/snapshot/' + idToUser).then(function (response) {
      setSnapshotsData(response.data);
    });
  };

  // useEffect to load snapshots on component mount
  useEffect(() => {
    fetchSnapshots();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function createSnapshot() {
    if (project.projectType === "PNL_DIVISIONAL") {
      axios.put('/snapshot/consolidatedProject/' + project.id).then(function (response) {
        setCreateModal(true);
        setDrawerContentAtom("Creating CONSOLIDATED Snapshot")
        setDrawerAtom(true);
      });
    } else {
      axios.put('/snapshot/' + project.id).then(function (response) {
        setCreateModal(true);
        setDrawerContentAtom("Creating Snapshot")
        setDrawerAtom(true);
      });
    }
  }

  return (
    <React.Fragment>

      <PageHeader pageName={"Project Snapshots"} parentName={"Snapshot"}/>

      <Divider my={6} />

      <Button onClick={() => createSnapshot()}
              mr={2}
              variant="contained"
              color="primary"
              sx={{ marginBottom: 2 }}
      >
        Create Snapshot
      </Button>

      {!!snapshotsData &&
        <SnapTable data={snapshotsData}></SnapTable>
      }

      {/* Add the refresh button below the table */}
      <Button onClick={fetchSnapshots}
              variant="outlined"
              color="secondary"
              sx={{ marginTop: 2 }}
      >
        Refresh Snapshots
      </Button>

      <CreatingSnapshotModal
        open={createModal}
        setOpen={setCreateModal}
      />

      <Settings />

    </React.Fragment>
  );
}
